import {
  TransportDocumentQueryKey,
  useLoadedTransportDocuments,
} from "api/transportDocument.api";
import userStore, { getUserId } from "store/user.store";
import { PageLoader } from "components/PageLoader";
import {
  TransportDocument,
  TransportDocumentGroup,
  activeTransportDocuments,
  getVehicleFromGroups,
  getWeighedTransportDocuments,
  hasLoadedTransportDocument,
  hasWeighedTransportDocument,
} from "types/transportDocument.type";
import PrimaryButton from "components/buttons/PrimaryButton";
import instance from "api/axiosInstance";
import Modal from "components/Modal";
import { useForm } from "react-hook-form";
import { FC, useEffect, useState } from "react";
import { Step1Form } from "./Step1Form";
import { Step2Form } from "./Step2Form";
import { FormWaste } from "./helpers";
import { useSetFinalWeight } from "api/waste.api";
import { useQueryClient } from "react-query";
import { TransportDocumentStatus } from "types/enums";
import { DotMenu } from "components/DotMenu";
import {
  PencilSquareIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useWasteStationSignatureLogic } from "./useWasteStationSignatureLogic.hook";
import { SignatureModal } from "components/SignatureModal";
import { vehicleNameAndType } from "types/vehicle.type";
import { useSetActiveVehicles } from "api/user.api";
import { scrollToTop } from "helpers/scroll";
import { ArchiveBoxArrowDownIcon } from "@heroicons/react/24/outline";
import { GreenDot } from "components/GreenDot";
import { RedDot } from "components/RedDot";
import { TransportDocumentStatusTag } from "components/TransportDocumentStatusTag";
import { LightBulbIcon } from "@heroicons/react/24/solid";
import dismissedMessagesStore from "store/dismissedMessages.store";
import { EmptyStatePop } from "components/EmptyStatePop";
import { AssignmentInfoModal } from "../aktuella-uppdrag/AssignmentInfoModal";
import { PageTransition } from "components/animations/SnapUp";
import { isMobile } from "helpers/isMobile";

export type Step1Data = {
  actualTotalWeight: string;
  truckSelected: boolean;
  trailerSelected: boolean;
};

export const OpenTransportDocumentsPage = () => {
  const userId = userStore(getUserId);
  const {
    data: groupGroups,
    isLoading,
    isError,
  } = useLoadedTransportDocuments(userId ?? -1);

  const [transportDocumentIdLoading, setTransportDocumentIdLoading] = useState<
    number | undefined
  >();

  // Preview PDF
  const previewPDF = (id: number) => {
    const url = `/transport-document/${id}/preview-pdf`;
    setTransportDocumentIdLoading(id);
    if (isMobile()) {
      instance
        .get(url, {
          responseType: "blob",
        })
        .then((response) => {
          setTransportDocumentIdLoading(undefined);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.pdf");
          document.body.appendChild(link);
          link.click();
        });
    } else {
      instance
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          setTransportDocumentIdLoading(undefined);
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
    }
  };

  // Vägningsflöde
  const [selectedWasteStationId, setSelectedWasteStationId] = useState<
    number | undefined
  >();

  const [isStep1ModalOpen, setIsStep1ModalOpen] = useState<boolean>(false);
  const [isStep2ModalOpen, setIsStep2ModalOpen] = useState<boolean>(false);

  const openStep2Modal = () => {
    // setIsStep1ModalOpen(false);
    setIsStep2ModalOpen(true);
  };
  // step1
  const {
    register: registerStep1,
    handleSubmit: handleStep1Submit,
    reset: resetStep1,
    formState: { errors: step1Errors },
    watch: watchStep1,
  } = useForm<Step1Data>({
    defaultValues: {
      actualTotalWeight: "",
      truckSelected: true,
      trailerSelected: true,
    },
  });

  const onStep1Submit = handleStep1Submit(() => {
    // Vad ska man göra nu egentligen?
    // Man ska gå till step 2.
    openStep2Modal();
  });

  const onStep1ModalClose = () => {
    setIsStep1ModalOpen(false);
  };

  const afterStep1ModalClose = () => {
    setSelectedWasteStationId(undefined);
    resetStep1();
  };

  // Step 2

  const queryClient = useQueryClient();
  const { mutate: setWasteWeights, isSuccess: isSetFinalWeightSuccess } =
    useSetFinalWeight({
      onSuccess: () => {
        queryClient.invalidateQueries([TransportDocumentQueryKey.GetLoad]);
      },
    });

  const onStep2SaveClick = (items: FormWaste[]) => {
    // anropa servern med alla dessa waste och sätt dess faktiska vikt.
    if (!items || items.length === 0) {
      throw Error("No items");
    }
    setWasteWeights({
      wasteList: items.map((item) => ({
        id: item.id,
        weightFinal: item.weight,
      })),
    });
  };

  const onStep2ModalClose = () => {
    setIsStep2ModalOpen(false);
    onStep1ModalClose();
    // setSelectedWasteStationId(undefined);
  };

  const afterStep2ModalClose = () => setSelectedWasteStationId(undefined);

  useEffect(() => {
    if (isSetFinalWeightSuccess) {
      setIsStep1ModalOpen(false);
      setIsStep2ModalOpen(false);
    }
  }, [isSetFinalWeightSuccess]);

  // const afterSetFinalWeightSuccess = () => {
  //   resetStep1();
  //   setSelectedWasteStationId(undefined);
  // };

  // Waste station signature
  const {
    isSignatureModalVisible,
    hideSignatureModal,
    onWasteStationSignatureSubmit,
    isSavingSignature,
    setTransportIdsToSign,
  } = useWasteStationSignatureLogic();

  // Active vehicle
  const { mutate: setActiveVehicles, isSuccess: isSetActiveVehiclesSuccess } =
    useSetActiveVehicles(userId ?? -1);
  const onActivateVehicleClick = (vehicleId: number) => {
    if (!groupGroups) throw Error("groupGroups undefined");
    const currentActiveVehicles = groupGroups.activeVehicles;
    const vehicleToActivate = getVehicleFromGroups(vehicleId, [
      ...groupGroups.activeGroups,
      ...groupGroups.inactiveGroups,
    ]);

    const payload = [
      ...currentActiveVehicles.filter((v) => v.type !== vehicleToActivate.type),
      vehicleToActivate,
    ].map((v) => v.id);

    setActiveVehicles(payload);
  };

  const onDeactivateVehicleClick = (vehicleId: number) => {
    if (!groupGroups) throw Error("groupGroups undefined");
    const currentActiveVehicles = groupGroups.activeVehicles;

    const payload = [
      ...currentActiveVehicles.filter((v) => v.id !== vehicleId),
    ].map((v) => v.id);

    setActiveVehicles(payload);
  };

  useEffect(() => {
    if (isSetActiveVehiclesSuccess) {
      scrollToTop();
    }
  }, [isSetActiveVehiclesSuccess]);

  // Tips
  const { activeVehicleInfo, setActiveVehicleInfo } = dismissedMessagesStore();

  // View assignment
  const [assignmentIdToView, setAssignmentIdToView] = useState<number | null>(
    null
  );

  if (isLoading) return <PageLoader />;
  if (
    !groupGroups ||
    (groupGroups.activeGroups.length === 0 &&
      groupGroups.inactiveGroups.length === 0)
  )
    return (
      <div className="bg-white shadow-lg overflow-hidden lg:rounded-lg max-w-4xl mt-7 flex-1">
        <EmptyStatePop text="All last lossad och signerad" />
      </div>
    );
  if (isError) return <div>error</div>;

  const activeGroups = groupGroups.activeGroups;
  const inactiveGroups = groupGroups.inactiveGroups;

  const selectedGroup = activeGroups.find(
    (g) => g.wasteStation.id === selectedWasteStationId
  );

  const truckSelected = watchStep1("truckSelected");
  const trailerSelected = watchStep1("trailerSelected");
  const actualTotalWeight = watchStep1("actualTotalWeight");

  const tdsToWeigh = activeTransportDocuments(
    truckSelected,
    trailerSelected,
    selectedGroup
  );
  return (
    <PageTransition>
      <div className="bg-white shadow-lg overflow-hidden lg:rounded-lg max-w-4xl mt-7 flex-1">
        {activeGroups.length === 0 &&
          inactiveGroups.length > 0 &&
          !activeVehicleInfo && (
            <div className="rounded-md bg-cyan-50 p-4">
              <div className="flex mb-3 items-center">
                <LightBulbIcon
                  className="h-5 w-5 text-cyan-400 mr-2"
                  aria-hidden="true"
                />
                <span className="text-cyan-800 text-sm font-semibold">
                  Tips!
                </span>
              </div>
              <p className="text-sm text-cyan-700 mb-4">
                För att avlasta ett fordon, se till att det är inställt som
                "aktivt". Ett aktivt fordon indikeras av en grön lampa bredvid
                registreringsnumret. Om lampan är röd, betyder det att fordonet
                inte är aktivt.
              </p>
              <p className="text-sm text-cyan-700 mb-4">
                Aktivering av fordon: Klicka på menyknappen som finns till höger
                om registreringsnumret och välj "Aktivera".
              </p>
              <p className="text-sm text-cyan-700 mb-4">
                <span className="font-semibold">Viktigt:</span> Glöm inte att
                säkerställa att både bilen och släpvagnen är aktiverad före
                avfärd. Detta är avgörande för att man vid en olycka ska kunna
                identifiera vad som finns i lasten.
              </p>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setActiveVehicleInfo(true)}
                  className="ml-3 rounded-md bg-cyan-50 px-2 py-1.5 text-sm font-medium text-cyan-800 hover:bg-cyan-100 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2 focus:ring-offset-cyan-50"
                >
                  Visa inte igen
                </button>
              </div>
            </div>
          )}
        {activeGroups.map((group: TransportDocumentGroup) => (
          <Group
            key={group.wasteStation.id}
            group={group}
            setIsStep1ModalOpen={setIsStep1ModalOpen}
            setSelectedWasteStationId={setSelectedWasteStationId}
            setTransportIdsToSign={setTransportIdsToSign}
            onDeactivateVehicleClick={onDeactivateVehicleClick}
            previewPDF={previewPDF}
            setAssignmentIdToView={setAssignmentIdToView}
            isActive
            transportDocumentIdLoading={transportDocumentIdLoading}
          />
        ))}
        {/* {activeGroups.length > 0 && inactiveGroups.length > 0 && ( */}
        {/* <div className="h-4 bg-gray-100" /> */}
        {/* )} */}
        {inactiveGroups.map((group: TransportDocumentGroup) => (
          <Group
            key={group.wasteStation.id}
            group={group}
            setIsStep1ModalOpen={setIsStep1ModalOpen}
            setSelectedWasteStationId={setSelectedWasteStationId}
            setTransportIdsToSign={setTransportIdsToSign}
            onActivateVehicleClick={onActivateVehicleClick}
            previewPDF={previewPDF}
            setAssignmentIdToView={setAssignmentIdToView}
            transportDocumentIdLoading={transportDocumentIdLoading}
          />
        ))}
        <Modal
          isOpen={isStep1ModalOpen}
          onClose={onStep1ModalClose}
          title="Lossa"
          actionHandler={onStep1Submit}
          actionText="Fortsätt"
          afterClose={afterStep1ModalClose}
        >
          <Step1Form
            register={registerStep1}
            errors={step1Errors}
            group={selectedGroup}
            tdsToWeigh={tdsToWeigh}
            actualTotalWeight={actualTotalWeight}
          />
          <Modal
            isOpen={isStep2ModalOpen}
            onClose={onStep2ModalClose}
            title="Justera vikt"
            hideButtons
            afterClose={afterStep2ModalClose}
          >
            <Step2Form
              group={selectedGroup}
              tdsToWeigh={tdsToWeigh}
              actualTotalWeight={actualTotalWeight}
              onSaveClick={onStep2SaveClick}
              onClose={onStep2ModalClose}
            />
          </Modal>
        </Modal>

        <Modal
          isOpen={isSignatureModalVisible}
          title="Signatur mottagarstation"
          onClose={hideSignatureModal}
          hideButtons
        >
          <SignatureModal
            header="Signatur mottagarstation"
            sendImageToParent={(image: string, sendToWasteStation: boolean) =>
              onWasteStationSignatureSubmit(image, sendToWasteStation)
            }
            cancel={hideSignatureModal}
            isLoading={isSavingSignature}
            enableSendToWasteStation
          />
        </Modal>

        <AssignmentInfoModal
          assignmentId={assignmentIdToView}
          closeModal={() => setAssignmentIdToView(null)}
          isOpen={!!assignmentIdToView}
        />
      </div>
    </PageTransition>
  );
};

const Group: FC<{
  group: TransportDocumentGroup;
  setSelectedWasteStationId: (
    value: React.SetStateAction<number | undefined>
  ) => void;
  setTransportIdsToSign: (value: React.SetStateAction<number[]>) => void;
  setIsStep1ModalOpen: (value: React.SetStateAction<boolean>) => void;
  previewPDF: (id: number) => void;
  isActive?: boolean;
  onActivateVehicleClick?: (vehicleId: number) => void;
  onDeactivateVehicleClick?: (vehicleId: number) => void;
  setAssignmentIdToView: React.Dispatch<React.SetStateAction<number | null>>;
  transportDocumentIdLoading: number | undefined;
}> = ({
  group,
  setSelectedWasteStationId,
  setIsStep1ModalOpen,
  setTransportIdsToSign,
  previewPDF,
  isActive = false,
  onActivateVehicleClick,
  onDeactivateVehicleClick,
  setAssignmentIdToView,
  transportDocumentIdLoading,
}) => (
  <div
    key={group.wasteStation.id}
    className="flex flex-col p-4 py-8 border-b-8 border-gray-75"
  >
    <h2 className="flex items-center justify-between text-base font-semibold">
      <div className="flex items-center">
        <ArchiveBoxArrowDownIcon className="h-4 w-4 text-gray-600 mr-1" />
        {group.wasteStation.name}
      </div>
      {hasLoadedTransportDocument(group) && isActive && (
        <PrimaryButton
          size="medium"
          onClick={() => {
            setSelectedWasteStationId(group.wasteStation.id);
            setIsStep1ModalOpen(true);
          }}
        >
          Lossa
        </PrimaryButton>
      )}
      {hasWeighedTransportDocument(group) &&
        !hasLoadedTransportDocument(group) && (
          <>
            <PrimaryButton
              size="small"
              onClick={() => {
                setTransportIdsToSign(
                  getWeighedTransportDocuments(group).map((td) => td.id)
                );
              }}
            >
              <PencilSquareIcon className="h-4 w-4 mr-1" /> Signera lossade
            </PrimaryButton>
          </>
        )}
    </h2>
    <div className="space-y-10 mt-8">
      {group.vehicles.map(({ vehicle, transportDocuments }) => (
        <div
          key={vehicle.id}
          className="flex flex-col font-medium border-b border-gray-100"
        >
          <h3 className="flex items-center mb-4 justify-between">
            <div className="flex items-center">
              {isActive ? <GreenDot /> : <RedDot />}
              <span className="ml-1 mr-3">{vehicleNameAndType(vehicle)}</span>
              <DotMenu
                items={[
                  {
                    // label: isActive ? : "Deaktivera" : "Aktivera",
                    onClick: () => {
                      if (isActive) {
                        !!onDeactivateVehicleClick &&
                          onDeactivateVehicleClick(vehicle.id);
                      } else {
                        !!onActivateVehicleClick &&
                          onActivateVehicleClick(vehicle.id);
                      }
                    },
                    icon: isActive ? (
                      <div className="mr-1">
                        <RedDot />
                      </div>
                    ) : (
                      <div className="mr-1">
                        <GreenDot />
                      </div>
                    ),
                    label: isActive ? "Parkera" : "Aktivera",
                  },
                ]}
              />
            </div>
            {/* Om detta fordon är invägt och det finns andra fordon som ej är invägda ska vi ha knappen här */}
            {transportDocuments.some(
              (td) => td.status !== TransportDocumentStatus.Loaded
            ) &&
              group.vehicles.some(
                (v) =>
                  v.vehicle.id !== vehicle.id &&
                  v.transportDocuments.some(
                    (td) => td.status === TransportDocumentStatus.Loaded
                  )
              ) && (
                <PrimaryButton
                  size="small"
                  onClick={() => {
                    setTransportIdsToSign(
                      transportDocuments
                        .filter(
                          (td) => td.status === TransportDocumentStatus.Weighed
                        )
                        .map((td) => td.id)
                    );
                  }}
                >
                  <PencilSquareIcon className="h-4 w-4 mr-1" /> Signera lossade
                </PrimaryButton>
              )}
          </h3>
          <ul className="divide-y divide-gray-100 space-y-2">
            {transportDocuments.map((td) => (
              <TransportDocumentItem
                key={td.id}
                td={td}
                previewPdf={() => previewPDF(td.id)}
                onSignClick={() => setTransportIdsToSign([td.id])}
                onViewAssignmentClick={() =>
                  setAssignmentIdToView(td.tomning?.assignment.id ?? -1)
                }
                isLoadingPdf={transportDocumentIdLoading === td.id}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
);

const TransportDocumentItem: FC<{
  td: TransportDocument;
  previewPdf: () => void;
  onSignClick: () => void;
  onViewAssignmentClick: () => void;
  isLoadingPdf: boolean;
}> = ({ td, previewPdf, onSignClick, onViewAssignmentClick, isLoadingPdf }) => (
  <li
    key={td.id}
    className="flex items-center justify-between gap-x-6 py-4 bg-white border-t border-gray-100 relative"
  >
    <div className="min-w-0 flex-auto space-y-3 sm:space-y-4">
      {/* <p className="text-sm font-normal leading-6 text-gray-900 flex items-center">
        <FaceSmileIcon className="h-4 w-auto text-gray-600 mr-1" />
        {`${td.customer?.name}`}
      </p> */}
      {/* <p className="truncate text-xs leading-5 text-gray-900 flex items-center">
        <CircleStackIcon className="h-4 w-auto text-gray-500 mr-1" />
        {td.tomning?.separator?.identifier}
      </p> */}
      <table>
        <tbody>
          {td.wasteList.map((waste) => (
            <tr key={waste.id}>
              <td className="text-gray-900 text-base sm:text-sm font-medium pr-3 pb-1">
                {waste.wasteCode.codePretty}:
              </td>
              <td className="text-gray-900 text-base sm:text-sm font-normal pb-1">
                {td.status === TransportDocumentStatus.Loaded
                  ? `${waste.weightPreliminary} kg (prel)`
                  : `${waste.weightFinal} kg`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {td.status === TransportDocumentStatus.Weighed && (
      <div className="">
        <TransportDocumentStatusTag status={td.status} />
      </div>
    )}
    <div className="flex items-center">
      {/* <button
        onClick={previewPdf}
        className="mr-1.5 rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
      >
        Visa
        {isLoadingPdf && (
          <svg
            className={"w-3 h-3 ml-2 -mr-1 text-cyan-500 animate-spin"}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
      </button> */}
      <DotMenu
        items={[
          {
            label: "Uppdrag",
            onClick: onViewAssignmentClick,
            icon: (
              <DocumentMagnifyingGlassIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
          },
          {
            label: "Signera",
            onClick: onSignClick,
            icon: (
              <PencilSquareIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
            isHidden: td.status !== TransportDocumentStatus.Weighed,
          },
        ]}
      />
    </div>
  </li>
);
