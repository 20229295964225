import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { ForgotPasswordDTO, useForgotPassword } from "api/user.api";
import { TextInput } from "components/TextInput";
import { isEmail } from "helpers/string";
import { AuthFlowWrapper } from "components/AuthFlowWrapper";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

function ForgotPasswordPage() {
  const { mutate: resetPassword, isLoading, isSuccess } = useForgotPassword();

  useEffect(() => {
    document.title = "Glömt lösenord - Driftjournalen";
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordDTO>();

  const onSubmit = handleSubmit((data: ForgotPasswordDTO) => {
    resetPassword(data);
  });

  return (
    <AuthFlowWrapper
      headerText="Återställ ditt lösenord"
      onActionClick={onSubmit}
      isLoading={isLoading}
      buttonText="Skicka"
      buttonTextLoading="Skickar..."
      hideAction={isSuccess}
    >
      {isSuccess ? (
        <div>
          <CheckCircleIcon className="mx-auto h-10 w-auto text-blue-400 mb-2" />
          <p className="text-gray-700 text-center">
            Kontrollera din inkorg för att slutföra återställningen av
            lösenordet.
          </p>
        </div>
      ) : (
        <>
          <p className="text-gray-400 text-sm text-center">
            Vi skickar en länk till din e-postadress med uppgifter om hur du
            återställer ditt lösenord.
          </p>
          <TextInput
            register={register}
            name="email"
            label="E-postadress"
            validation={{
              validate: (value: string) =>
                !value || // Ok att lämna tomt
                isEmail(value) ||
                "Denna e-postadressen ser ut att vara felaktig",
            }}
            errorMessage={errors.email?.message}
            autoFocus
          />
        </>
      )}
    </AuthFlowWrapper>
  );
}

export default ForgotPasswordPage;
