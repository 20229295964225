import { FC } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { classNames } from "helpers/classNames";

export interface ComboBoxItem {
  id: number;
  name: string;
  description?: string;
}

interface Props {
  label?: string;
  items?: ComboBoxItem[];
  setQuery: (value: string) => void;
  selectedItem?: ComboBoxItem;
  setSelectedItem: (value: ComboBoxItem) => void;
}
export const ComboBox: FC<Props> = ({
  label,
  setQuery,
  items = [],
  selectedItem,
  setSelectedItem,
}) => {
  return (
    <Combobox as="div" value={selectedItem} onChange={setSelectedItem}>
      {!!label && (
        <Combobox.Label className="leading-6 flex text-sm font-semibold text-gray-800">
          {label}
        </Combobox.Label>
      )}

      <div className="relative mt-1">
        <Combobox.Input
          autoComplete="off"
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          // displayValue={(item: ComboBoxItem) => item.name}
          displayValue={(item: ComboBoxItem) =>
            `${item.name} ${item.description ?? ""}`
          }
          // placeholder="Sök avfallskod eller beskrivning"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {items.length > 0 && (
          <Combobox.Options className=" absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {items.map((item) => {
              return (
                <Combobox.Option
                  key={item.id}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9 now",
                      active ? "bg-cyan-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <div className="flex">
                        <span
                          className={classNames(
                            "shrink-0",
                            item.id === selectedItem?.id && "font-semibold"
                          )}
                        >
                          {item.name}
                        </span>
                        <span
                          className={classNames(
                            "text-gray-500 ml-3",
                            active ? "text-white" : "text-gray-600"
                          )}
                        >
                          {item.description}
                        </span>
                      </div>

                      {item.id === selectedItem?.id && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-cyan-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              );
            })}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
