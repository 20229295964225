import React, { useEffect } from "react";
import { LoginDTO, useLogin } from "../../api/auth.api";
import { useForm } from "react-hook-form";
import userStore from "../../store/user.store";
import { refreshTokenHasExpired } from "../../store/refreshToken.store";
import { Link } from "components/Link";
import { ErrorAlert } from "components/ErrorAlert";
import Logo from "assets/Logo.png";
import { PasswordInput } from "components/PasswordInput";
import { PageTransition } from "components/animations/SnapUp";

function SignInPage() {
  const { login, isError, isLoading } = useLogin();
  const { register, handleSubmit } = useForm<LoginDTO>();
  const { user, clearUser } = userStore((state) => state);
  const onSubmit = handleSubmit((data: LoginDTO) => login(data));

  useEffect(() => {
    document.title = "Logga in - Driftjournalen";
  }, []);

  useEffect(() => {
    if (user && refreshTokenHasExpired()) {
      clearUser();
    }
  }, [user, clearUser]);

  return (
    <PageTransition>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="logo" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Logga in på ditt konto
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    {...register("username")}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <PasswordInput
                  name="password"
                  register={register}
                  onSubmit={onSubmit}
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>

                <div className="text-sm">
                  <Link
                    to={"/forgot-password"}
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Glömt ditt lösenord?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  onClick={onSubmit}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {isLoading && (
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {isLoading ? "Loggar in" : "Logga in"}
                </button>
              </div>
              {isError && (
                <ErrorAlert text="Felaktiga inloggningsuppgifter, vänligen försök igen" />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}

export default SignInPage;
