import axios from "axios";
import { clearRefreshTokenExpirationDate } from "store/refreshToken.store";
import { ENDPOINT_LOGIN, ENDPOINT_REFRESH_TOKEN } from "./auth.api";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    if (isUnauthorized(err)) {
      if (isRefreshTokenRequest(err)) {
        // Refresh token expired, user needs to log in again
        clearRefreshTokenExpirationDate();
        window.location.href = window.location.origin + "/signin";
        return Promise.reject(err);
      } else if (isLoginRequest(err)) {
        return Promise.reject(err);
      }
      originalConfig._retry = true;
      try {
        await instance.get(ENDPOINT_REFRESH_TOKEN);
        return instance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    return Promise.reject(err);
  }
);
export default instance;

const isUnauthorized = (err: any) =>
  err.response && err.response.status === 401 && !err.config._retry;

const isRefreshTokenRequest = (err: any) =>
  err.config.url === ENDPOINT_REFRESH_TOKEN;

const isLoginRequest = (err: any) => err.config.url === ENDPOINT_LOGIN;

export enum ApiUrl {
  WasteStation = "/waste-station",
  Waste = "/waste",
  WasteStationNotification = "/waste-station-notification",
}

export const getErrorMessage = (err: any) => {
  if (err.response?.data?.message) {
    return err.response.data.message;
  }
  if (err.response?.data?.errors) {
    return err.response.data.errors;
  }
  return "Något gick fel";
};

export const getAxiosErrorMessage = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // Check if the error response contains data and a message
      if (
        error.response.data &&
        typeof error.response.data === "object" &&
        "message" in error.response.data
      ) {
        return (error.response.data as { message: string }).message;
      }
      return error.response.statusText;
    } else if (error.request) {
      return "No response received from the server";
    } else {
      return error.message;
    }
  } else if (error instanceof Error) {
    return error.message;
  } else {
    return "An unknown error occurred";
  }
};
