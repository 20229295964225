import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateAssignment, AssignmentQueryKey } from "api/assignment.api";
import { ComboBoxItem } from "components/ComboBox";
import { useNotification } from "hooks/useNotification";
import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import {
  useForm,
  useFieldArray,
  UseFormReturn,
  UseFieldArrayReturn,
} from "react-hook-form";
import { UseMutationResult, useQueryClient } from "react-query";
import {
  Assignment,
  AssignmentDTO,
  getEmptyAssignmentDTO,
} from "types/assignment.type";
import {
  WasteCodeFormValues,
  wasteCodeFormSchema,
  getWasteCodePreliminaryWeightErrorMessage,
} from "../Schedule.helpers";
import {
  AssignmentType,
  getAssignmentTypeDropdownItems,
} from "enums/AssignmentType.enum";
import { useWasteStationList } from "api/wasteStation.api";
import { WasteStation } from "types/wasteStation.type";
import {
  dateToYYYYMMDD,
  getDaysAsDropdownItems,
  getWeeksAsDropdownItems,
  isValidDate,
} from "helpers/date";
import { isAvskiljare, Separator } from "types/separator/separator.type";
import { useUsers } from "api/user.api";
import { customerToDropdownItem, userToDropdownItem } from "types/user.type";
import {
  CustomerQueryKey,
  useCustomer,
  useCustomerDropdown,
} from "api/customer.api";
import { useAddSeparatorLogic } from "pages/app/tomning-page/hooks/useAddSeparatorLogic.hook";
import { useWasteCodeDropDown } from "api/wasteCode.api";
import { DropdownItem } from "components/Dropdown";
import { Customer } from "types/customer.type";
import { SeparatorQueryKey } from "api/separator.api";

export const useAddAssignmentLogic = (
  predefinedSeparator?: Separator,
  predefinedCustomer?: Customer
): AddAssignmentLogicRetVal => {
  // Add assignment
  const [isModalOpen, setIsModalOpen] = useState(false);

  // waste codes
  const [selectedWasteCodes, setSelectedWasteCodes] = useState<ComboBoxItem[]>(
    []
  );

  const wasteCodeForm = useForm<WasteCodeFormValues>({
    resolver: zodResolver(wasteCodeFormSchema),
    defaultValues: {
      wasteCodes: [],
    },
    reValidateMode: "onChange",
  });

  const selectedWasteCodesList = wasteCodeForm.watch("wasteCodes");

  const wasteCodesFieldArray = useFieldArray({
    control: wasteCodeForm.control,
    name: "wasteCodes",
  });

  const mutation = useCreateAssignment();

  const assignmentForm = useForm<AssignmentDTO>({
    defaultValues: getEmptyAssignmentDTO(
      predefinedSeparator,
      predefinedCustomer
    ),
  });

  const assignmentType = assignmentForm.watch("type");

  const customerId = assignmentForm.watch("customerId");
  useEffect(() => {
    // Vi ska inte nollställa separator om det är predefined
    if (predefinedSeparator) {
      return;
    }
    assignmentForm.setValue("separatorId", 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, predefinedSeparator]);

  const isTomning = +assignmentType === AssignmentType.Tomning;

  const selectedWasteStationId = assignmentForm.watch("wasteStationId");
  const { data: wasteStationList } = useWasteStationList();

  const selectedWasteStation = useMemo(
    () =>
      (wasteStationList ?? []).find(
        (x) => !!selectedWasteStationId && x.id === +selectedWasteStationId
      ),
    [wasteStationList, selectedWasteStationId]
  );

  const wasteStationDropdownItems = useMemo(
    () =>
      (wasteStationList ?? []).map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [wasteStationList]
  );

  useEffect(() => {
    if (predefinedSeparator) {
      assignmentForm.setValue("customerId", predefinedSeparator.customerId);
      assignmentForm.setValue("separatorId", predefinedSeparator.id);
    } else if (predefinedCustomer) {
      assignmentForm.setValue("customerId", predefinedCustomer.id);
    }
  }, [predefinedSeparator, assignmentForm, predefinedCustomer]);

  const onAssignmentSubmit = assignmentForm.handleSubmit(
    (data: AssignmentDTO) => {
      if (!data.userId || data.userId < 1) {
        throw Error("Couldn't find user");
      }
      if (!data.customerId || data.customerId < 1) {
        throw Error("Couldn't find customer");
      }
      if (!data.separatorId || data.separatorId < 1) {
        throw Error("Couldn't find separator");
      }
      if (!data.type || data.type < 1) {
        throw Error("Couldn't find separator");
      }

      // if (
      //   selectedWasteCodesList.some(
      //     (x) => !!getWasteCodePreliminaryWeightErrorMessage(x)
      //   )
      // ) {
      //   // Man får inte submitta någon preliminär vikt som är fel
      //   return;
      // }

      selectedWasteCodesList.forEach((wasteCode, index) => {
        const errorMessage =
          getWasteCodePreliminaryWeightErrorMessage(wasteCode);

        if (errorMessage) {
          // Set the error for the specific field in the array
          wasteCodeForm.setError(`wasteCodes.${index}.preliminaryWeight`, {
            type: "manual",
            message: errorMessage,
          });
        } else {
          // Clear any previous error for this field
          wasteCodeForm.clearErrors(`wasteCodes.${index}.preliminaryWeight`);
        }
      });

      // After setting errors, check if there are any errors in the form
      const hasErrors = Object.keys(wasteCodeForm.formState.errors).length > 0;

      if (hasErrors) {
        // Prevent form submission if there are errors
        return;
      }

      if (
        isMissingDataForWasteStationNotification(
          data,
          assignmentForm,
          selectedWasteCodesList,
          wasteCodeForm,
          selectedWasteStation
        )
      ) {
        return;
      }

      data = {
        ...data,
        userId: +(data.userId as number),
        separatorId: +(data.separatorId as number),
        customerId: +(data.customerId as number),
        type: +data.type,
        wasteCodeList: selectedWasteCodesList.map(
          ({ id, preliminaryWeight }) => ({ id, preliminaryWeight })
        ),
        proxyCustomerId: !data.proxyCustomerId
          ? undefined
          : +data.proxyCustomerId,
        wasteStationId:
          !data.wasteStationId || +data.wasteStationId === 0
            ? null
            : +data.wasteStationId,
        weekday: !data.weekday ? undefined : +data.weekday,
        wasteStationNotificationDate: !data.wasteStationNotificationDate
          ? undefined
          : dateToYYYYMMDD(data.wasteStationNotificationDate),
      };

      if (data.type !== AssignmentType.Tomning && !data.includeTomning) {
        data = clearTomningFields(data);
      }

      mutation.mutate(data);
    }
  );

  const clearTomningFields = (data: AssignmentDTO) => ({
    ...data,
    wasteStationId: 0,
    wasteStationNotificationDate: undefined,
    wasteCodeList: [],
  });

  const onAddAssignmentModalClose = useCallback(() => {
    setIsModalOpen(false);
    setTimeout(() => {
      assignmentForm.reset(
        getEmptyAssignmentDTO(predefinedSeparator, predefinedCustomer)
      );
      setSelectedWasteCodes([]);
      wasteCodeForm.reset();
    }, 300);
  }, [
    setIsModalOpen,
    assignmentForm,
    wasteCodeForm,
    predefinedSeparator,
    predefinedCustomer,
  ]);

  const queryClient = useQueryClient();
  const notification = useNotification();
  useEffect(() => {
    if (mutation.isSuccess) {
      onAddAssignmentModalClose();
      queryClient.invalidateQueries([AssignmentQueryKey.GetAllForSchedulePage]);
      queryClient.invalidateQueries([
        SeparatorQueryKey.GetSeparatorAssignments,
      ]);
      queryClient.invalidateQueries([
        CustomerQueryKey.GetProxyAssignmentsForParent,
      ]);

      notification.info("Uppdraget har skapats");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess, onAddAssignmentModalClose, queryClient]);

  const { data: users } = useUsers();
  const userDropdownItems = useMemo(
    () =>
      (users ?? [])
        .map(userToDropdownItem)
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [users]
  );
  // Endpoint för att hämta alla kunder
  // Dropdown
  const { data: customers } = useCustomerDropdown();
  const customerDropdownItems = useMemo(
    () => (customers ?? []).map(customerToDropdownItem),
    [customers]
  );
  const selectedCustomerId = Number(assignmentForm.watch("customerId"));

  const { data: huvudkund } = useCustomer(selectedCustomerId, {
    enabled: selectedCustomerId > 0,
  });

  const { data: underkund } = useCustomer(
    Number(assignmentForm.watch("proxyCustomerId")),
    {
      enabled: Number(assignmentForm.watch("proxyCustomerId")) > 0,
    }
  );

  const verksamhetsutovare = underkund ?? huvudkund;

  // Kör denna kod varje gång man sätter en ny verksamhetsutövare (kund/underkund), dvs den som har objektet
  useEffect(() => {
    if (verksamhetsutovare) {
      assignmentForm.setValue("type", AssignmentType.Undefined);
      assignmentForm.setValue("includeTomning", false);
      if (verksamhetsutovare.separators?.length && !predefinedSeparator) {
        assignmentForm.setValue(
          "separatorId",
          verksamhetsutovare.separators[0].id
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verksamhetsutovare]);

  // useEffect som lyssnar på vald kund och hämtar varje gång man väljer en
  const separatorDropdownItems = useMemo(
    () =>
      (verksamhetsutovare?.separators ?? []).map(({ id, identifier }) => ({
        label: identifier ?? "Objekt saknar identifiering",
        value: id,
      })),
    [verksamhetsutovare]
  );

  // Skapa nytt objekt
  const addSeparatorLogic = useAddSeparatorLogic(verksamhetsutovare);

  // När man skapat ett objekt vill man sätta det som valt objekt
  useEffect(() => {
    if (
      addSeparatorLogic.createdSeparator &&
      separatorDropdownItems.find(
        (x) => x.value === addSeparatorLogic.createdSeparator?.id
      )
    ) {
      assignmentForm.setValue(
        "separatorId",
        addSeparatorLogic.createdSeparator.id
      );
    }
  }, [
    addSeparatorLogic.createdSeparator,
    separatorDropdownItems,
    assignmentForm,
  ]);

  // Datum
  const selectedDate = assignmentForm.watch("date");

  const weekDropdownItems = useMemo(() => getWeeksAsDropdownItems(), []);

  const daysDropdownItems = useMemo(
    () =>
      getDaysAsDropdownItems(
        !selectedDate ? undefined : new Date(selectedDate)
      ),
    [selectedDate]
  );

  // Defaultvalue till denna vecka
  // useEffect(() => {
  //   if (weekDropdownItems) {
  //     setValue("date", weekDropdownItems[0].value);
  //   }
  // }, [setValue, weekDropdownItems]);

  const assignmentTypeDropdownItems = useMemo(
    () => getAssignmentTypeDropdownItems(),
    []
  );

  const isInspection = [
    AssignmentType.FiveYearInspection,
    AssignmentType.SixMonthsInspection,
  ].includes(+assignmentType);

  // Detta hanterar det fall när man först valt slamtömning och lagt till koder
  // och sen bytt till annan typ av uppdrag
  useEffect(() => {
    if (!isTomning && selectedWasteCodes.length > 0) {
      setSelectedWasteCodes([]);
    }
  }, [isTomning, selectedWasteCodes, setSelectedWasteCodes]);

  // waste station

  // Waste
  const [wasteCodeQuery, setWasteCodeQuery] = useState<string>("");
  const { data: wasteCodes } = useWasteCodeDropDown(wasteCodeQuery);
  const [selectedWasteCode, setSelectedWasteCode] = useState<ComboBoxItem>({
    id: -1,
    name: "",
    description: "",
  });

  useEffect(() => {
    if (selectedWasteCode.id > 0) {
      setSelectedWasteCodes((prev) => [
        ...prev.filter((c) => c.id !== selectedWasteCode.id),
        { ...selectedWasteCode },
      ]);
      setSelectedWasteCode({
        id: -1,
        name: "",
        description: "",
      });
      setWasteCodeQuery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWasteCode]);

  const handleSetWasteCodeQuery = (query: string) =>
    setWasteCodeQuery(query.toLowerCase());

  const wasteCodeComboBoxItems: ComboBoxItem[] = (wasteCodes ?? []).map(
    (c) => ({
      id: c.id,
      name: c.codePretty,
      description: c.description,
    })
  );

  const [wasteCodeError, setWasteCodeError] = useState(false);

  useEffect(() => {
    if (wasteCodeError && selectedWasteCode.id > 0) {
      setWasteCodeError(false);
    }
  }, [wasteCodeError, selectedWasteCode]);

  const selectedSeparatorId = assignmentForm.watch("separatorId");
  const selectedSeparator = verksamhetsutovare?.separators?.find(
    // eslint-disable-next-line eqeqeq
    (sep) => sep.id == selectedSeparatorId
  );
  const hasSelectedAvskiljare = !selectedSeparator
    ? false
    : isAvskiljare(selectedSeparator);

  // const hasChildCustomers = (huvudkund?.childCustomers ?? []).length > 0;
  const isProxyAssignment = useMemo(
    () => !!huvudkund?.isParentCustomer,
    [huvudkund?.isParentCustomer]
  );

  useEffect(() => {
    if (!isProxyAssignment) {
      assignmentForm.setValue("proxyCustomerId", 0);
      if (!predefinedSeparator) {
        assignmentForm.setValue("separatorId", 0);
      }
      assignmentForm.setValue("isProxyAssignment", false);
    } else {
      assignmentForm.setValue("isProxyAssignment", true);
    }
  }, [isProxyAssignment, assignmentForm, predefinedSeparator]);

  const proxyCustomerDropdownItems = useMemo(
    () =>
      (huvudkund?.childCustomers ?? [])
        .map(customerToDropdownItem)
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [huvudkund]
  );

  const harHuvudkundMenInteUnderkund =
    !!huvudkund?.isParentCustomer && !underkund;

  const includeTomning = assignmentForm.watch("includeTomning");

  useEffect(() => {
    if (assignmentType !== AssignmentType.Tomning) {
      assignmentForm.setValue("wasteStationId", 0);
      assignmentForm.setValue("wasteStationNotificationDate", undefined);
      assignmentForm.setValue("includeTomning", false);
      wasteCodeForm.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentType]);

  return {
    isModalOpen,
    setIsModalOpen,
    onModalClose: onAddAssignmentModalClose,
    onAssignmentSubmit,
    mutation,
    assignmentForm,
    wasteCodeForm,
    wasteCodesFieldArray,
    selectedWasteCodes,
    setSelectedWasteCodes,
    selectedWasteCodesList,
    isTomning,
    selectedWasteStation,
    wasteStationDropdownItems,
    isCustomerPrefilled:
      !!predefinedSeparator?.customerId || !!predefinedCustomer,
    isSeparatorPrefilled: !!predefinedSeparator,
    customerDropdownItems,
    huvudkund,
    proxyCustomerDropdownItems,
    separatorDropdownItems,
    verksamhetsutovare,
    harHuvudkundMenInteUnderkund,
    userDropdownItems,
    weekDropdownItems,
    daysDropdownItems,
    assignmentTypeDropdownItems,
    hasSelectedAvskiljare,
    isInspection,
    wasteCodeComboBoxItems,
    handleSetWasteCodeQuery,
    selectedWasteCode,
    addSeparatorLogic,
    includeTomning: !!includeTomning,
  };
};

export type AddAssignmentLogicRetVal = {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  onModalClose: () => void;
  onAssignmentSubmit: () => void;
  mutation: UseMutationResult<Assignment, unknown, AssignmentDTO, unknown>;
  assignmentForm: UseFormReturn<AssignmentDTO, any>;
  wasteCodeForm: UseFormReturn<
    {
      wasteCodes: {
        id: number;
        name: string;
        description?: string | undefined;
        preliminaryWeight?: string | undefined;
      }[];
    },
    any
  >;
  wasteCodesFieldArray: UseFieldArrayReturn<
    {
      wasteCodes: WasteCodeItem[];
    },
    "wasteCodes",
    "id"
  >;
  selectedWasteCodes: ComboBoxItem[];
  setSelectedWasteCodes: Dispatch<SetStateAction<ComboBoxItem[]>>;
  selectedWasteCodesList: WasteCodeItem[];
  isTomning: boolean;
  selectedWasteStation?: WasteStation;
  wasteStationDropdownItems: { label: string; value: number }[];
  isCustomerPrefilled: boolean;
  isSeparatorPrefilled: boolean;
  customerDropdownItems: DropdownItem[];
  huvudkund?: Customer;
  proxyCustomerDropdownItems: DropdownItem[];
  separatorDropdownItems: DropdownItem[];
  verksamhetsutovare?: Customer;
  harHuvudkundMenInteUnderkund: boolean;
  userDropdownItems: DropdownItem[];
  weekDropdownItems: DropdownItem[];
  daysDropdownItems: DropdownItem[];
  assignmentTypeDropdownItems: DropdownItem[];
  hasSelectedAvskiljare: boolean;
  isInspection: boolean;
  wasteCodeComboBoxItems: ComboBoxItem[];
  handleSetWasteCodeQuery: (query: string) => void;
  selectedWasteCode: ComboBoxItem;
  addSeparatorLogic: ReturnType<typeof useAddSeparatorLogic>;
  includeTomning: boolean;
};

export type WasteCodeItem = {
  id: number;
  name: string;
  description?: string | undefined;
  preliminaryWeight?: string | undefined;
};

const isMissingDataForWasteStationNotification = (
  data: AssignmentDTO,
  form: UseFormReturn<AssignmentDTO, any>,
  selectedWasteCodesList: WasteCodeItem[],
  wasteCodeForm: UseFormReturn<WasteCodeFormValues, any>,
  selectedWasteStation?: WasteStation
) => {
  if (!selectedWasteStation?.wantsToBeNotified) return false;

  if (!isValidDate(data.wasteStationNotificationDate)) {
    form.setError("wasteStationNotificationDate", {
      message: "Obligatoriskt fält",
    });
    return true;
  }
  // if()
  let hasError = false;
  selectedWasteCodesList.forEach((item, index) => {
    const weight = item.preliminaryWeight;

    // Check if weight is not a positive number or cannot be parsed to a positive number
    if (!weight || isNaN(Number(weight)) || Number(weight) <= 0) {
      wasteCodeForm.setError(`wasteCodes.${index}.preliminaryWeight`, {
        type: "manual",
        message: "Ange vikt",
      });
      hasError = true;
    }
  });

  return hasError;
};
