import { FC, useState } from "react";
import { InspectionStatus } from "enums/InspectionStatus.enum";
import { InspectionStatusTag } from "../InspectionStatusTag";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ActionMenu } from "pages/app/dashboard/ActionMenu";
import {
  ArrowUturnLeftIcon,
  PencilSquareIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import {
  CubeIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import Modal from "components/Modal";
import { AssignmentInfoModal } from "pages/app/aktuella-uppdrag/AssignmentInfoModal";
import { Assignment } from "types/assignment.type";
import { ViewSeparatorModal } from "components/ViewSeparatorModal";
import { Link } from "components/Link";
import { getLinkToTomningPage } from "pages/app/tomning-page/TomningPage";
import { useAddTomningToAssignment } from "api/assignment.api";
import { useQueryClient } from "react-query";
import { FiveYearsInspectionQueryKey } from "api/fiveYearsInspection.api";
import { useNavigate } from "react-router-dom";
import { Tomning } from "types/tomning.type";
import PrimaryButton from "components/buttons/PrimaryButton";
import { AssignmentTypeButton } from "components/AssignmentTypeTag";
import { AssignmentType } from "enums/AssignmentType.enum";
import SecondaryButton from "components/buttons/SecondaryButton";

interface Props {
  status: InspectionStatus;
  isEditMode: boolean;
  setEditMode: () => void;
  setKlarmarkera?: (shouldBeKlarmarkerad: boolean) => void;
  isSettingKlarmarkera?: boolean;
  openEditSeparatorModal: () => void;
  assignment: Assignment;
}

export const InspectionHeader: FC<Props> = ({
  status,
  isEditMode,
  setEditMode,
  setKlarmarkera = (_: boolean) => null,
  assignment,
  openEditSeparatorModal,
}) => {
  const [isViewSeparatorModalOpen, setIsViewSeparatorModalOpen] =
    useState<boolean>(false);
  const [viewAssignmentInfo, setViewAssignmentInfo] = useState<boolean>(false);
  const isMissingSeparatorData =
    status === InspectionStatus.MissingSeparatorData;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: addTomning, isLoading: isAddingTomning } =
    useAddTomningToAssignment(assignment.id, {
      onSuccess: (result: Tomning) => {
        if (!!assignment.fiveYearsInspection) {
          queryClient.invalidateQueries([
            FiveYearsInspectionQueryKey.GetOne,
            assignment.fiveYearsInspection.id,
          ]);
        }
        if (!!assignment.sixMonthsInspection) {
          queryClient.invalidateQueries([
            FiveYearsInspectionQueryKey.GetOne,
            assignment.sixMonthsInspection.id,
          ]);
        }
        navigate(
          getLinkToTomningPage(
            assignment.customerId,
            assignment.separatorId,
            result.id
          )
        );
      },
    });

  return (
    <>
      {!isMissingSeparatorData && (
        <div className="px-4 py-5 sm:px-6 border-b border-b-gray-100">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-8">
            <div className="sm:col-span-1">
              <h3 className="text-lg leading-6 py-1 font-medium text-gray-900 flex justify-between items-center relative">
                <span className="flex items-center ">
                  <span className="hidden sm:block mr-4">Rapport</span>
                  <InspectionStatusTag status={status} />
                </span>
                <div className="flex items-center space-x-6">
                  {!isEditMode && status !== InspectionStatus.Klarmarkerad && (
                    <SecondaryButton size="small" onClick={setEditMode}>
                      Inmatningsläge
                    </SecondaryButton>
                  )}
                  {status === InspectionStatus.RequirementsFulfilled ? (
                    <PrimaryButton
                      size="small"
                      onClick={() => setKlarmarkera(true)}
                    >
                      Klarmarkera
                    </PrimaryButton>
                  ) : (
                    <div className="invisible">
                      <PrimaryButton size="small">Klarmarkera</PrimaryButton>
                    </div>
                  )}
                  {!!assignment.tomning && (
                    <Link
                      to={getLinkToTomningPage(
                        assignment.customerId,
                        assignment.separatorId,
                        assignment.tomning.id
                      )}
                      className="text-sm text-cyan-700 hover:text-cyan-800 font-medium select-none"
                    >
                      <AssignmentTypeButton type={AssignmentType.Tomning} />
                    </Link>
                  )}

                  <ActionMenu
                    items={[
                      {
                        label: "Klarmarkera",
                        onClick: () => setKlarmarkera(true),
                        icon: (
                          <CheckCircleIcon
                            className="mr-3 h-5 w-5 text-cyan-400 group-hover:text-cyan-500"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Inmatningsläge",
                        onClick: setEditMode,
                        icon: (
                          <PencilSquareIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Ta bort klarmarkering",
                        onClick: () => setKlarmarkera(false),
                        icon: (
                          <ArrowUturnLeftIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Uppdrag",
                        onClick: () => setViewAssignmentInfo(true),
                        icon: (
                          <DocumentMagnifyingGlassIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Objekt",
                        onClick: () => setIsViewSeparatorModalOpen(true),
                        icon: (
                          <CubeIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Lägg till tömning",
                        onClick: () => addTomning(),
                        icon: (
                          <PlusCircleIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        ),
                        isNew: true,
                        isLoading: isAddingTomning,
                      },
                    ]
                      .filter(
                        (item) =>
                          item.label !== "Inmatningsläge" ||
                          (!isEditMode &&
                            status !== InspectionStatus.Klarmarkerad)
                      )
                      .filter(
                        (item) =>
                          item.label !== "Klarmarkera" ||
                          status === InspectionStatus.RequirementsFulfilled
                      )
                      .filter(
                        (item) =>
                          item.label !== "Lägg till tömning" ||
                          !assignment.tomning
                      )
                      .filter(
                        (item) =>
                          item.label !== "Ta bort klarmarkering" ||
                          status === InspectionStatus.Klarmarkerad
                      )}
                  />
                </div>
              </h3>
            </div>
          </dl>
        </div>
      )}

      <AssignmentInfoModal
        assignmentId={assignment.id}
        closeModal={() => setViewAssignmentInfo(false)}
        isOpen={viewAssignmentInfo}
      />
      <Modal
        isOpen={isViewSeparatorModalOpen}
        title="Objekt"
        onClose={() => setIsViewSeparatorModalOpen(false)}
        isWide
        closeButtonText="Stäng"
        secondaryAction={() => {
          setIsViewSeparatorModalOpen(false);
          openEditSeparatorModal();
        }}
      >
        {!!assignment?.separator && (
          <ViewSeparatorModal separator={assignment.separator} />
        )}
      </Modal>
    </>
  );
};
