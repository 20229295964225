import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import Dropdown from "components/Dropdown";
import { TextArea } from "components/TextArea";
import { AssignmentType } from "enums/AssignmentType.enum";
import { ComboBox, ComboBoxItem } from "components/ComboBox";
import { TextInput } from "components/TextInput";
import { WasteCodePreview } from "components/WasteCodePreview";
import { Checkbox } from "components/Checkbox";
import SecondaryButton from "components/buttons/SecondaryButton";
import Modal from "components/Modal";
import { SeparatorMetadataForm } from "../../separator/SeparatorMetadataForm";
import { PriorityIcon } from "../PriorityIcon";
import { Tips } from "components/Tips";
import { Tooltip } from "react-tooltip";
import { AddAssignmentLogicRetVal } from "./useAddAssignmentLogic";
import { WasteStation } from "types/wasteStation.type";
import { DatePickerNew } from "./DatePickerNew";
import { Info } from "components/Info";
import Divider from "components/Divider";

interface Props {
  logic: AddAssignmentLogicRetVal;
}

export const AddAssignmentModal: FC<Props> = ({ logic }) => {
  const {
    wasteCodeForm,
    selectedWasteCodesList,
    isModalOpen,
    onModalClose,
    onAssignmentSubmit,
    mutation,
    wasteCodesFieldArray,
    assignmentForm,
    customerDropdownItems,
    huvudkund,
    proxyCustomerDropdownItems,
    separatorDropdownItems,
    verksamhetsutovare,
    harHuvudkundMenInteUnderkund,
    userDropdownItems,
    weekDropdownItems,
    daysDropdownItems,
    assignmentTypeDropdownItems,
    hasSelectedAvskiljare,
    isInspection,
    wasteCodeComboBoxItems,
    handleSetWasteCodeQuery,
    selectedWasteCode,
    addSeparatorLogic,
    includeTomning,
    wasteStationDropdownItems,
    isTomning,
  } = logic;
  // Users

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      title={
        !!huvudkund?.isParentCustomer
          ? "Nytt förmedlat uppdrag"
          : "Nytt uppdrag"
      }
      actionHandler={onAssignmentSubmit}
      isLoading={mutation.isLoading}
      actionText="Spara"
      isPrettyWide
    >
      <FormProvider {...wasteCodeForm}>
        <ModalContentWrapper className="space-y-6">
          {/* Kund, typ av uppgift, identifiering på objekt, datum, anteckning */}

          {/* Kund, underkund */}
          <div className="flex gap-x-4">
            <div className="w-1/2">
              <Dropdown
                items={customerDropdownItems}
                name="customerId"
                label="Kund"
                descriptionItemText=" "
                register={assignmentForm.register}
                validation={{
                  validate: (value: number) => {
                    return value > 0 || "Du måste välja en kund";
                  },
                }}
                errorMessage={
                  assignmentForm.formState.errors?.customerId?.message
                }
                disabled={logic.isCustomerPrefilled}
              />
            </div>
            <div className="w-1/2">
              {!!huvudkund?.isParentCustomer && (
                <Dropdown
                  items={proxyCustomerDropdownItems}
                  name="proxyCustomerId"
                  label="Verksamhetsutövare"
                  descriptionItemText=""
                  register={assignmentForm.register}
                  validation={{
                    validate: (value: number) => {
                      return value > 0 || "Du måste välja en underkund";
                    },
                  }}
                  errorMessage={
                    assignmentForm.formState.errors?.proxyCustomerId?.message
                  }
                />
              )}
            </div>
          </div>

          {/* Objekt */}
          <div className="flex gap-x-4 items-end">
            <div className="w-1/2">
              <Dropdown
                items={separatorDropdownItems}
                name="separatorId"
                label="Objekt"
                register={assignmentForm.register}
                disabled={
                  !verksamhetsutovare ||
                  separatorDropdownItems.length === 0 ||
                  logic.isSeparatorPrefilled
                }
                descriptionItemText={
                  !verksamhetsutovare
                    ? "Välj först kund"
                    : harHuvudkundMenInteUnderkund
                    ? "Välj först verksamhetsutövare"
                    : !!verksamhetsutovare &&
                      separatorDropdownItems.length === 0
                    ? "Kunden saknar objekt"
                    : " "
                }
                validation={{
                  validate: (value: number) => {
                    return value > 0 || "Du måste välja ett objekt";
                  },
                }}
                errorMessage={
                  assignmentForm.formState.errors?.separatorId?.message
                }
              />
            </div>
            <div className="w-1/2 flex justify-end pb-0.5">
              {!!verksamhetsutovare &&
                !harHuvudkundMenInteUnderkund &&
                !logic.isSeparatorPrefilled && (
                  <SecondaryButton
                    size="medium"
                    onClick={() =>
                      addSeparatorLogic.setIsNewSeparatorModalOpen(true)
                    }
                  >
                    + Nytt objekt
                  </SecondaryButton>
                )}
            </div>
          </div>

          {/* Operatör, prioriterat uppdrag */}
          <Tips
            id="f327ec2a-6d8c-430c-b15b-22b30fe32399"
            paragraphs={[
              "Välj 'Prioriterat uppdrag' om uppdraget är viktigt och ska utföras snarast. Uppdraget kommer hamna överst i operatörens uppdragslista och markeras som prioriterat.",
            ]}
          />
          {/* Användare, prioriterat uppdrag */}
          <div className="flex gap-x-4 items-end">
            <div className="w-1/2">
              <Dropdown
                items={userDropdownItems}
                name="userId"
                descriptionItemText=" "
                label="Operatör"
                register={assignmentForm.register}
                validation={{
                  validate: (value: number) =>
                    value > 0 || "Du måste välja en operatör",
                }}
                errorMessage={assignmentForm.formState.errors?.userId?.message}
              />
            </div>
            <div className="w-1/2 pb-2 px-0 flex justify-end items-center gap-x-2">
              <Checkbox
                label="Prioriterat uppdrag"
                name="isPrioritized"
                control={assignmentForm.control}
              />
              <div
                data-tooltip-id={`new-assignment-priority-icon-tooltip`}
                data-tooltip-content={
                  "Prioriterade uppdrag hamnar överst i operatörens lista och markeras med denna ikon."
                }
                data-tooltip-place="top"
              >
                <PriorityIcon />
              </div>
              <Tooltip id={`new-assignment-priority-icon-tooltip`} />
            </div>
          </div>

          {/* Vecka, exakt dag */}
          <div className="flex gap-x-4">
            <div className="w-1/2">
              <Dropdown
                items={weekDropdownItems}
                name="date"
                label="Vecka"
                register={assignmentForm.register}
                validation={{
                  validate: (value?: string) =>
                    !!value || "Du måste välja ett datum",
                }}
                errorMessage={assignmentForm.formState.errors?.date?.message}
              />
            </div>
            <div className="w-1/2">
              <Dropdown
                items={daysDropdownItems}
                name="weekday"
                label="Exakt dag (valfritt)"
                register={assignmentForm.register}
                disabledOptionValue={""}
                descriptionItemText=" "
              />
            </div>
          </div>

          {/* Uppdragstyp */}
          <div className="flex gap-x-4 items-end">
            <div className="w-1/2">
              <Dropdown
                items={assignmentTypeDropdownItems.filter(
                  (assignmentType) =>
                    (assignmentType.value !==
                      AssignmentType.SixMonthsInspection &&
                      assignmentType.value !==
                        AssignmentType.FiveYearInspection) ||
                    hasSelectedAvskiljare
                )}
                name="type"
                label="Uppgift"
                descriptionItemText=" "
                register={assignmentForm.register}
                validation={{
                  validate: (value: number) => {
                    return value > 0 || "Du måste välja en uppgift";
                  },
                }}
                errorMessage={assignmentForm.formState.errors?.type?.message}
              />
            </div>
            <div className="w-1/2 pb-2 px-0">
              {isInspection && (
                <Checkbox
                  label="Lägg till tömning"
                  name="includeTomning"
                  control={assignmentForm.control}
                />
              )}
            </div>
          </div>

          {/* specifika fält för tömning */}
          {(isTomning || includeTomning) && (
            <div className="space-y-8">
              <Dropdown
                items={wasteStationDropdownItems}
                name="wasteStationId"
                label="Mottagarstation"
                register={assignmentForm.register}
                disabled={wasteStationDropdownItems.length === 0}
                descriptionItemText={
                  wasteStationDropdownItems.length === 0
                    ? "Ni saknar mottagarstationer"
                    : ""
                }
              />
              {/* <Tips
                id="new-assignment-modal-wastestationnotification-info"
                paragraphs={[
                  "Mottagarstationen vill bli aviserad om avfall. Ange uppskattad vikt för varje avfall och välj Lämningsdatum. Mottagarstationen kommer få epost med informationen och kommer kunna acceptera eller neka. Denna feedback syns sen i uppdragslistan.",
                ]}
              /> */}
              {logic.selectedWasteStation?.wantsToBeNotified && (
                <Info
                  text={`${logic.selectedWasteStation.name} vill bli aviserad om avfall. Ange uppskattad vikt för varje avfall och välj Lämningsdatum. ${logic.selectedWasteStation.name} kommer få epost med informationen och kommer kunna acceptera eller neka. Denna feedback syns sen i uppdragslistan.`}
                />
              )}

              <div className="space-y-3">
                <ComboBox
                  label="Avfallskoder"
                  items={wasteCodeComboBoxItems}
                  setQuery={handleSetWasteCodeQuery}
                  selectedItem={selectedWasteCode}
                  setSelectedItem={(item: ComboBoxItem) => {
                    if (
                      selectedWasteCodesList.find((x) => x.name === item.name)
                    ) {
                      return; // undviker dubbletter
                    }
                    wasteCodesFieldArray.append({
                      ...item,
                      description: item.description,
                      preliminaryWeight: "",
                    });
                  }}
                />

                {wasteCodesFieldArray.fields.map((field, index) => (
                  <WasteCodePreview
                    key={field.id}
                    code={field}
                    register={wasteCodeForm.register}
                    index={index}
                    deleteCode={() => wasteCodesFieldArray.remove(index)}
                    errorMessage={
                      wasteCodeForm.formState.errors?.wasteCodes?.[index]
                        ?.preliminaryWeight?.message
                    }
                    includeDescription={false}
                  />
                ))}
              </div>

              <div className="flex justify-start">
                <WasteStationNotificationDate
                  wasteStation={logic.selectedWasteStation}
                  logic={logic}
                />
              </div>
            </div>
          )}

          {/* Om man valt slamtömning och mottagarstationen vill bli notifierad visar vi datepickern för det datumet. */}
          {/*  */}
          <Divider />
          <TextInput
            label="Referensnummer (visas för kund)"
            name="referenceNumber"
            register={assignmentForm.register}
          />
          <TextArea
            label="Intern kommentar"
            name="notes"
            register={assignmentForm.register}
          />

          <Modal
            isOpen={addSeparatorLogic.isNewSeparatorModalOpen}
            title={"Nytt objekt - " + verksamhetsutovare?.name}
            actionText="Spara"
            onClose={addSeparatorLogic.onNewSeparatorModalClose}
            actionHandler={addSeparatorLogic.onSeparatorSubmit}
            isLoading={addSeparatorLogic.isCreating}
            loadingText="Sparar..."
          >
            <SeparatorMetadataForm
              register={addSeparatorLogic.registerSeparator}
              errors={addSeparatorLogic.addSeparatorErrors}
              copyAddressFromCustomer={
                addSeparatorLogic.copyAddressFromCustomer
              }
              selectedSeparatorType={addSeparatorLogic.selectedSeparatorType}
              setValue={addSeparatorLogic.setValue}
              postnummer={addSeparatorLogic.watchSeparator("postalCode") ?? ""}
            />
          </Modal>
        </ModalContentWrapper>
      </FormProvider>
    </Modal>
  );
};

type WasteStationNotificationDateProps = {
  wasteStation?: WasteStation;
  logic: AddAssignmentLogicRetVal;
};
const WasteStationNotificationDate = ({
  wasteStation,
  logic,
}: WasteStationNotificationDateProps) => {
  if (!wasteStation?.wantsToBeNotified) {
    return null;
  }

  return (
    <FormProvider {...logic.assignmentForm}>
      <DatePickerNew
        name="wasteStationNotificationDate"
        label="Lämningsdatum"
        placeholder="Välj datum"
        minDate={new Date()}
      />
    </FormProvider>
  );
};
