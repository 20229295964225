import { FC } from "react";
import { useNavigate } from "react-router-dom";
import EmptyState from "components/EmptyState";
import { THead, TR, TBody, Table, TH, TD } from "components/table";
import { appendCharacterIfNotAlreadyThere } from "helpers/string";
import { Customer } from "types/customer.type";
import { Separator } from "types/separator/separator.type";
import TabContainer from "components/tab/TabContainer";
import { getSeparatorTypeLabel } from "types/separator/separator.helpers";
import { appRoutes } from "routes.type";
import { Warning } from "components/Warning";

interface Props {
  separators?: Separator[];
  customer: Customer;
  openNewSeparatorModal: () => void;
}

const Separators: FC<Props> = ({
  separators = [],
  customer,
  openNewSeparatorModal,
}) => {
  const navigate = useNavigate();

  const onSeparatorClick = (separatorId: number | string) =>
    navigate(`${appRoutes.customers.fullPath}/${customer.id}/${separatorId}`);

  if (separators.length === 0) {
    return (
      <div className="flex flex-col gap-y-6">
        {customer.childCustomers.length > 0 && (
          <Warning
            text="Obs! Objekt som läggs till här kommer hanteras som kundens egna objekt. Underkunders objekt 
måste skapas under via den specifika slutkundens kunduppgifter."
          />
        )}
        <EmptyState
          header="Inga objekt"
          subHeader={`Skapa ${appendCharacterIfNotAlreadyThere(
            customer.name,
            "s"
          )} första objekt`}
          onClick={openNewSeparatorModal}
          buttonText="Nytt objekt"
        />
      </div>
    );
  }

  return (
    <TabContainer>
      {customer.childCustomers.length > 0 && (
        <Warning
          text="Obs! Objekt som läggs till här kommer hanteras som kundens egna objekt. Underkunders objekt 
måste skapas under via den specifika slutkundens kunduppgifter."
        />
      )}
      <div className="px-4 sm:px-0">
        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>Typ</TH>
              <TH>Identifiering</TH>
              <TH hideOnMobile>Adress</TH>
            </TR>
          </THead>
          <TBody>
            {separators?.map((separator) => (
              <TR
                key={separator.id}
                onClick={() => onSeparatorClick(separator.id)}
              >
                <TD isFirstColumn>{getSeparatorTypeLabel(separator)}</TD>
                <TD>{separator.identifier}</TD>
                <TD hideOnMobile>{mergeAddress(separator)}</TD>
              </TR>
            ))}
          </TBody>
        </Table>
      </div>
    </TabContainer>
  );
};
export default Separators;

const mergeAddress = (separator: Separator) =>
  [separator.address, separator.postalCode, separator.city]
    .filter((item) => item)
    .join(", ");
