import { FC } from "react";
import { ComboBoxItem } from "./ComboBox";
import { TrashIcon } from "@heroicons/react/24/outline";
import { UseFormRegister } from "react-hook-form";
import { TextInput } from "./TextInput";
import { WasteCodeFormValues } from "pages/app/aktuella-uppdrag/Schedule.helpers";
import { classNames } from "helpers/classNames";

export const WasteCodePreview: FC<{
  code: ComboBoxItem;
  deleteCode: () => void;
  register: UseFormRegister<WasteCodeFormValues>;
  errorMessage?: string;
  index: number;
  includeDescription?: boolean;
}> = ({
  code,
  deleteCode,
  register,
  index,
  errorMessage,
  includeDescription = true,
}) => {
  return (
    <div
      className={classNames(
        "flex ",
        includeDescription && "p-4 border border-gray-100 rounded-lg shadow-sm"
      )}
    >
      <div className="flex items-center">
        <span
          className={classNames(
            "flex",
            includeDescription
              ? "flex-col space-y-2 cursor-pointer"
              : "space-x-4 items-center"
          )}
        >
          <span
            className={classNames(
              "text-sm font-semibold truncate",
              !includeDescription && "w-20"
            )}
          >
            {code.name}
          </span>
          {includeDescription && (
            <span
              className="text-sm text-gray-500"
              style={{ maxWidth: "40ch" }}
            >
              {code.description}
            </span>
          )}
          <TextInput
            label={includeDescription ? "Preliminär vikt" : undefined}
            name={`wasteCodes.${index}.preliminaryWeight`}
            register={register}
            errorMessage={errorMessage}
            // size="half"
            appendix="kg"
          />
        </span>
      </div>
      <div className="flex items-center ml-auto">
        <TrashIcon
          onClick={deleteCode}
          className="text-sm text-gray-500 h-4 cursor-pointer hover:text-red-400 hover:transform hover:scale-110"
        />
      </div>
    </div>
  );
};
