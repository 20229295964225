import { usePatchWasteStationNotification } from "api/wasteStationNotification.api";
import PrimaryButton from "components/buttons/PrimaryButton";
import { Item } from "components/desriptionList/Item";
import { Label } from "components/desriptionList/Label";
import { Value } from "components/desriptionList/Value";
import { DividerWithLabel } from "components/DividerWithLabel";
import Modal from "components/Modal";
import { dateToYYYYMMDD, formatDate } from "helpers/date";
import { useEffect, useState } from "react";
import { Assignment } from "types/assignment.type";
import { DatePickerNew } from "../AddAssignmentModal/DatePickerNew";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import SecondaryButton from "components/buttons/SecondaryButton";
import Divider from "components/Divider";

type Props = {
  assignment?: Assignment;
  isOpen: boolean;
  onSuccess: () => void;
  closeModal: () => void;
};

const NewDateSchema = z.object({
  newDate: z.date().min(new Date(), "Välj ett datum"),
});

export type TNewDateSchema = z.infer<typeof NewDateSchema>;

export const RejectedWasteStationNotificationModal = ({
  assignment,
  isOpen,
  onSuccess,
  closeModal,
}: Props) => {
  const form = useForm<TNewDateSchema>();

  const [isLoadingButton1, setIsLoadingButton1] = useState(false);
  const [isLoadingButton2, setIsLoadingButton2] = useState(false);

  const mutation = usePatchWasteStationNotification(
    assignment?.wasteStationNotification?.id || 0
  );

  useEffect(() => {
    if (mutation.isSuccess) {
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess]);

  if (
    !assignment?.wasteStationNotification ||
    !assignment?.wasteStation ||
    !assignment?.user ||
    !assignment
  ) {
    // Detta kommer aldrig hända, lägger in det för att berätta för typescript att det finns
    return null;
  }
  const { wasteStationNotification, wasteStation } = assignment;

  const handleAcceptRequestedDateChange = () => {
    if (!wasteStationNotification.requestedDateChange) {
      throw Error("Saknar requestedDateChange");
    }
    setIsLoadingButton1(true);
    mutation.mutate({
      newDate: dateToYYYYMMDD(wasteStationNotification.requestedDateChange),
    });
  };

  const onSubmit = form.handleSubmit(({ newDate }) => {
    if (!newDate) {
      form.setError("newDate", { message: "Välj ett datum" });
      return;
    }
    setIsLoadingButton2(true);
    mutation.mutate({ newDate: dateToYYYYMMDD(newDate) });
  });
  const hasRequestedDateChange = !!wasteStationNotification.requestedDateChange;
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Önskat lämningsdatum nekat"
      isPrettyWide
      errorTriangle
      hideButtons
    >
      <div className="mt-8 space-y-4">
        <Item>
          <Label>Mottagarstation</Label>
          <Value>{wasteStation.name}</Value>
        </Item>
        {/* <Item>
          <Label>Operatör</Label>
          <Value>{user.name}</Value>
        </Item> */}

        <Item>
          <Label>Avfall</Label>
          <Value>
            <div className="space-y-1">
              {assignment.wasteStationNotification?.preliminaryWeights.map(
                (pw) => (
                  <p
                    key={pw.code}
                  >{`${pw.code} - ${pw.preliminaryWeightInKilo} kg`}</p>
                )
              )}
            </div>
          </Value>
        </Item>
        {!!wasteStationNotification.messageFromWasteStation && (
          <Item>
            <Label>{`Meddelande`}</Label>
            <Value>{wasteStationNotification.messageFromWasteStation}</Value>
          </Item>
        )}
        <Item>
          <Label>Lämningsdatum önskat av oss</Label>
          <Value>{formatDate(wasteStationNotification.requestedDate)}</Value>
        </Item>
        {hasRequestedDateChange && (
          <>
            <Divider />
            <Item>
              <Label>{`Föreslaget nytt datum av ${assignment.wasteStation?.name}`}</Label>
              <Value>
                <div className="flex items-center justify-between space-x-4">
                  <span>
                    {formatDate(wasteStationNotification.requestedDateChange)}
                  </span>
                  <PrimaryButton
                    onClick={handleAcceptRequestedDateChange}
                    size="small"
                    isLoading={isLoadingButton1}
                  >
                    Acceptera föreslaget datum
                  </PrimaryButton>
                </div>
              </Value>
            </Item>
          </>
        )}
        {hasRequestedDateChange && <DividerWithLabel label="eller" />}
        <Item>
          <Label>Föreslå nytt datum</Label>
          <span>
            <FormProvider {...form}>
              <DatePickerNew name="newDate" minDate={new Date()} />
            </FormProvider>
          </span>
          <div className="flex justify-end">
            {hasRequestedDateChange ? (
              <SecondaryButton
                onClick={onSubmit}
                size="small"
                isLoading={isLoadingButton2}
              >
                Föreslå nytt datum
              </SecondaryButton>
            ) : (
              <PrimaryButton
                onClick={onSubmit}
                size="small"
                isLoading={isLoadingButton2}
              >
                Föreslå nytt datum
              </PrimaryButton>
            )}
          </div>
          {/* </div> */}
        </Item>
      </div>
    </Modal>
  );
};
