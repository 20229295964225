import { FC, useMemo } from "react";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { Assignment } from "types/assignment.type";
import { useUsers } from "api/user.api";
import { TextArea } from "components/TextArea";
import {
  dateToDropdownItem,
  getDaysAsDropdownItems,
  getWeeksAsDropdownItems,
  isBeforeThisWeek,
} from "helpers/date";
import { userToDropdownItem } from "types/user.type";
import Dropdown from "components/Dropdown";
import { DateDropdownItem } from "components/DateDropdownStupid";
import Modal from "components/Modal";
import { EditAssignmentLogic } from "./useEditAssignment.logic";
import { AssignmentTypeTag } from "components/AssignmentTypeTag";
import { TextInput } from "components/TextInput";

interface Props {
  assignment: Assignment;
  logic: EditAssignmentLogic;
}

export const UpdateAssignmentModal: FC<Props> = ({ assignment, logic }) => {
  // Operatör
  const { closeModal, isModalOpen, saveChanges, form, mutation } = logic;
  const { data: users } = useUsers();
  const userDropdownItems = useMemo(
    () =>
      (users ?? [])
        .map(userToDropdownItem)
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [users]
  );

  // Vecka + dag
  const selectedDate = logic.form.watch("date");
  const weekDropdownItems = useMemo(() => getWeeksAsDropdownItems(), []);
  const daysDropdownItems = useMemo(
    () =>
      getDaysAsDropdownItems(
        !selectedDate ? undefined : new Date(selectedDate)
      ),
    [selectedDate]
  );

  const getPassedDownDropdownItem = () => {
    if (!isBeforeThisWeek(new Date(assignment.date))) return undefined;

    return dateToDropdownItem(new Date(assignment.date));
  };

  let dateDropdownItems: DateDropdownItem[] = [];
  const passedDateDropdownItem = getPassedDownDropdownItem();

  if (!!passedDateDropdownItem) {
    dateDropdownItems = [passedDateDropdownItem];
  }
  dateDropdownItems = [...dateDropdownItems, ...weekDropdownItems];

  if (!assignment) {
    return null;
  }

  return (
    <Modal
      isOpen={isModalOpen}
      actionHandler={saveChanges}
      onClose={closeModal}
      title="Ändra uppdrag"
      isLoading={mutation.isLoading}
      actionText="Spara"
    >
      <ModalContentWrapper>
        <div className="flex items-center gap-x-2">
          {assignment.customer.name}
          <AssignmentTypeTag type={assignment.type} />
        </div>
        {/* Operatör */}
        <Dropdown
          items={userDropdownItems}
          name="userId"
          descriptionItemText=" "
          label="Operatör"
          register={form.register}
          validation={{
            validate: (value: number) =>
              value > 0 || "Du måste välja en operatör",
          }}
          errorMessage={form.formState.errors?.userId?.message}
        />

        {/* Vecka, exakt dag */}
        <div className="flex gap-x-4">
          <div className="w-1/2">
            <Dropdown
              items={dateDropdownItems}
              name="date"
              label="Vecka"
              register={form.register}
              validation={{
                validate: (value?: string) =>
                  !!value || "Du måste välja ett datum",
              }}
              errorMessage={logic.form.formState.errors?.date?.message}
            />
          </div>
          <div className="w-1/2">
            <Dropdown
              items={daysDropdownItems}
              name="weekday"
              label="Exakt dag (valfritt)"
              register={form.register}
              disabledOptionValue={""}
              descriptionItemText=" "
            />
          </div>
        </div>
        <TextInput
          label="Referensnummer"
          name="referenceNumber"
          register={form.register}
        />
        <TextArea
          label="Intern kommentar"
          name="notes"
          register={form.register}
        />
      </ModalContentWrapper>
    </Modal>
  );
};
