import { useNotification } from "hooks/useNotification";
import { useMutation, useQueryClient } from "react-query";
import instance, { ApiUrl } from "./axiosInstance";
import { UpdateWasteDTO, Waste } from "types/waste.type";
import { TomningQueryKey } from "./tomning.api";

export const useUpdateWaste = (settings = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: UpdateWasteDTO) =>
      (await instance.put(ApiUrl.Waste, payload)).data,
    {
      onSuccess: (data: Waste) => {
        notification.info(`Ändringarna sparade`);

        // Här ska vi invalidera query för fristående transportdokument också
        if (data.transportDocument.tomning) {
          queryClient.invalidateQueries([
            TomningQueryKey.GetOne,
            data.transportDocument.tomning.id,
          ]);
        }
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      ...settings,
    }
  );
};

export const useSetFinalWeight = (settings = {}) => {
  const notification = useNotification();

  return useMutation(
    async (payload: { wasteList: { id: number; weightFinal: string }[] }) =>
      (await instance.put(`${ApiUrl.Waste}/final-weight`, payload)).data,
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      ...settings,
    }
  );
};

export const useDeleteWaste = (settings = {}) =>
  useMutation(
    async (id: number) => await instance.delete(`${ApiUrl.Waste}/${id}`),
    {
      ...settings,
    }
  );
