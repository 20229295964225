import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CreateCustomerDTO, Customer } from "types/customer.type";
import instance from "./axiosInstance";
import { SearchPayload } from "./assignment.api";
import { Assignment } from "types/assignment.type";
import { toQueryString } from "helpers/url";

export const ENDPOINT = "/customer";

export enum CustomerQueryKey {
  GetAll = "get-customers",
  GetOne = "get-customer",
  GetProxyAssignmentsForParent = "GET_CUSTOMER_PROXY_ASSIGNMENTS_AS_PARENT_KEY",
  GetDropdown = "get-customer-dropdown",
}

export const useCustomers = (params: Record<string, any>) =>
  useQuery(
    [CustomerQueryKey.GetAll, params],
    async (): Promise<CustomersQueryResult> =>
      (await instance.get(`${ENDPOINT}?${toQueryString(params)}`)).data
  );

export const useSearchCustomers = () => {
  return useMutation(
    async (payload: SearchPayload): Promise<CustomersQueryResult> =>
      (await instance.post(ENDPOINT + "/search", payload)).data
  );
};

export const useCustomerDropdown = (query = "") => {
  return useQuery(
    [CustomerQueryKey.GetDropdown, query],
    async (): Promise<Customer[]> =>
      (await instance.get(`${ENDPOINT}/dropdown?q=${query}`)).data
  );
};

export type CustomerDropdownItem = {
  id: number;
  name: string;
};

export type CustomersQueryResult = {
  customers: Customer[];
  count: number;
};

export const useCustomer = (id: number, options = {}) =>
  useQuery(
    [CustomerQueryKey.GetOne, id],
    async (): Promise<Customer> =>
      (await instance.get(`${ENDPOINT}/${id}`)).data,
    { ...options }
  );

export const useProxyAssignmentsAsParent = (id: number) => {
  const queryClient = useQueryClient();
  return useQuery(
    [CustomerQueryKey.GetProxyAssignmentsForParent, id],
    async (): Promise<Assignment[]> =>
      (await instance.get(`${ENDPOINT}/${id}/proxy-assignments-as-parent`))
        .data,
    {
      onSuccess: () =>
        queryClient.invalidateQueries([CustomerQueryKey.GetOne, id]),
    }
  );
};

export const useCreateCustomer = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: CreateCustomerDTO): Promise<Customer> =>
      (await instance.post(ENDPOINT, payload)).data,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([CustomerQueryKey.GetAll]);
        notification.info(`${data.name} skapad`);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      ...settings,
    }
  );
};

export const useUpdateCustomer = (id: number) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: CreateCustomerDTO): Promise<Customer> => {
      const { emails, separators, ...rest } = payload;
      return (await instance.put(`${ENDPOINT}/${id}`, rest)).data;
    },
    {
      onSuccess: (data: Customer) => {
        notification.info(`Ändringarna sparades`);
        queryClient.invalidateQueries([CustomerQueryKey.GetOne, data.id]);
        queryClient.invalidateQueries([CustomerQueryKey.GetAll]);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export type ProxyCustomerDto = {
  customerId: number;
  proxyCustomerId: number;
};
export const useAddProxyCustomer = () => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async ({
      customerId,
      proxyCustomerId,
    }: ProxyCustomerDto): Promise<Customer> =>
      (
        await instance.put(`${ENDPOINT}/${customerId}/proxy-customer`, {
          proxyCustomerId,
        })
      ).data,
    {
      onSuccess: (data: Customer) => {
        notification.info(`Underkund tillagd`);
        queryClient.invalidateQueries([CustomerQueryKey.GetOne, data.id]);
        queryClient.invalidateQueries([CustomerQueryKey.GetAll]);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};

export const usePatchCustomer = (id: number, settings = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation(
    async (payload: Partial<CreateCustomerDTO>): Promise<Customer> =>
      (await instance.patch(`${ENDPOINT}/${id}`, payload)).data,
    {
      onSuccess: (data: Customer) => {
        notification.info(`Ändringarna sparade`);
        queryClient.invalidateQueries([CustomerQueryKey.GetOne, data.id]);
        queryClient.invalidateQueries([CustomerQueryKey.GetAll]);
      },
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
      ...settings,
    }
  );
};

export const useDeleteCustomer = (id: number, settings = {}) => {
  return useMutation(async () => await instance.delete(`${ENDPOINT}/${id}`), {
    ...settings,
  });
};
