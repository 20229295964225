type Props = {
  label: string;
};

export const DividerWithLabel = ({ label }: Props) => (
  <div className="relative">
    <div aria-hidden="true" className="absolute inset-0 flex items-center">
      <div className="w-full border-t border-gray-100" />
    </div>
    <div className="relative flex justify-center">
      <span className="bg-white px-2 text-sm text-gray-500">{label}</span>
    </div>
  </div>
);
