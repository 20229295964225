import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInputStupid } from "components/TextInputStupid";
import { H2 } from "components/text/H2";
import {
  AssignmentInfoCustomer,
  AssignmentInfoSeparator,
  AssignmentInfoTomningInfo,
} from "pages/app/aktuella-uppdrag/AssignmentInfoModal";
import { FC } from "react";
import { Assignment, TimeReportDTO, hasTomning } from "types/assignment.type";

interface Props {
  assignments: Assignment[];
  timeReportItems: TimeReportDTO[];
  updateTimeReportItemHour: (assignmentId: number, value: string) => void;
  assignmentsWithFormErrors: number[];
}

export const TimeReportForm: FC<Props> = ({
  assignments = [],
  timeReportItems = [],
  updateTimeReportItemHour,
  assignmentsWithFormErrors,
}) => {
  const assignmentsWithTomning = assignments.filter((ass) => hasTomning(ass));

  return (
    <ModalContentWrapper>
      {/* Loopa igenom varje transportdokument precis som på Last-sidan */}
      {assignmentsWithTomning.map((assignment, index) => {
        const td = assignment.tomning?.transportDocument;
        const item = timeReportItems.find(
          (d) => d.assignmentId === assignment.id
        );
        if (!td || !item) {
          return <div>Error</div>;
        }
        return (
          <div
            key={assignment.id}
            className="min-w-0 flex-auto space-y-2 sm:space-y-4 border-b border-gray-200 pb-8"
          >
            <H2>{`Uppdrag #${index + 1}`}</H2>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 p-4 sm:p-0 lg:grid-cols-2">
              <AssignmentInfoCustomer assignment={assignment} />
              <AssignmentInfoSeparator assignment={assignment} />
              <AssignmentInfoTomningInfo
                assignment={assignment}
                includeReportedHours={false}
              />
            </dl>
            <TextInputStupid
              label="Arbetad tid"
              appendix="timmar"
              onChange={(hours) =>
                updateTimeReportItemHour(assignment.id, hours)
              }
              value={item.reportedHours}
              size="half"
              errorMessage={
                assignmentsWithFormErrors.includes(assignment.id)
                  ? "Obligatoriskt fält"
                  : undefined
              }
            />
          </div>
        );
      })}
    </ModalContentWrapper>
  );
};
