export const toQueryString = (params: Record<string, any>): string =>
  Object.keys(params)
    .map((key) => {
      const value = params[key];
      // Handle undefined or null values
      if (value === undefined || value === null) {
        return "";
      }
      // Encode key and value to be URL-safe
      return encodeURIComponent(key) + "=" + encodeURIComponent(value);
    })
    .filter((pair) => pair !== "") // Remove any empty pairs
    .join("&"); // Join all pairs with & symbol
