import { FC, useEffect, useMemo } from "react";
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import {
  Assignment,
  AssignmentDTO,
  hasInspection,
} from "types/assignment.type";
import { useUsers } from "api/user.api";
import { TextArea } from "components/TextArea";
import { useCustomer, useCustomerDropdown } from "api/customer.api";
import { getDaysAsDropdownItems, getWeeksAsDropdownItems } from "helpers/date";
import {
  AssignmentType,
  getAssignmentTypeDropdownItems,
} from "enums/AssignmentType.enum";
import { customerToDropdownItem, userToDropdownItem } from "types/user.type";
import { TextInput } from "components/TextInput";
import Dropdown from "components/Dropdown";
import { AnimateOpacity } from "components/animations/AnimateOpacity";
import { useAddSeparatorLogic } from "../tomning-page/hooks/useAddSeparatorLogic.hook";
import Modal from "components/Modal";
import { SeparatorMetadataForm } from "../separator/SeparatorMetadataForm";
import { Checkbox } from "components/Checkbox";
import { PriorityIcon } from "./PriorityIcon";
import { Tooltip } from "react-tooltip";
import { isAvskiljare } from "types/separator/separator.type";
import { DateDropdownItem } from "components/DateDropdownStupid";
import { Info } from "components/Info";

interface Props {
  register: UseFormRegister<AssignmentDTO>;
  formData: UseFormWatch<AssignmentDTO>;
  formErrors?: any;
  passedDateDropdownItem?: DateDropdownItem;

  setValue: UseFormSetValue<AssignmentDTO>;
  assignment?: Assignment | undefined;
  control: Control<any>;
}

export const UpdateAssignment: FC<Props> = ({
  register,
  formData,
  formErrors,
  setValue,
  passedDateDropdownItem,
  control,
  assignment,
}) => {
  // Users
  const { data: users } = useUsers();
  const userDropdownItems = useMemo(
    () =>
      (users ?? [])
        .map(userToDropdownItem)
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [users]
  );
  // Endpoint för att hämta alla kunder
  // Dropdown
  const { data: customers } = useCustomerDropdown();
  const customerDropdownItems = useMemo(
    () => (customers ?? []).map(customerToDropdownItem),
    [customers]
  );
  const selectedCustomerId = Number(formData("customerId"));

  // Endpoint för objekt för den kunden
  const { data: huvudkund } = useCustomer(selectedCustomerId, {
    enabled: selectedCustomerId > 0,
  });

  const { data: underkund } = useCustomer(Number(formData("proxyCustomerId")), {
    enabled: Number(formData("proxyCustomerId")) > 0,
  });

  const verksamhetsutovare = underkund ?? huvudkund;

  // Resetta separatorId om man byter verksamhetsutövare
  const separatorId = formData("separatorId") ?? undefined;
  useEffect(() => {
    if (
      verksamhetsutovare &&
      (verksamhetsutovare.separators ?? []).every((s) => s.id !== separatorId)
    ) {
      setValue("separatorId", 0);
    }
  }, [verksamhetsutovare, separatorId, setValue]);

  // useEffect som lyssnar på vald kund och hämtar varje gång man väljer en
  const separatorDropdownItems = useMemo(
    () =>
      (verksamhetsutovare?.separators ?? []).map(({ id, identifier }) => ({
        label: identifier ?? "Objekt saknar identifiering",
        value: id,
      })),
    [verksamhetsutovare]
  );

  // Skapa nytt objekt
  const addSeparatorLogic = useAddSeparatorLogic(verksamhetsutovare);

  // När man skapat ett objekt vill man sätta det som valt objekt
  useEffect(() => {
    if (
      addSeparatorLogic.createdSeparator &&
      separatorDropdownItems.find(
        (x) => x.value === addSeparatorLogic.createdSeparator?.id
      )
    ) {
      setValue("separatorId", addSeparatorLogic.createdSeparator.id);
    }
  }, [addSeparatorLogic.createdSeparator, separatorDropdownItems, setValue]);

  // Datum

  const selectedDate = formData("date");

  const weekDropdownItems = useMemo(() => getWeeksAsDropdownItems(), []);

  const daysDropdownItems = useMemo(
    () =>
      getDaysAsDropdownItems(
        !selectedDate ? undefined : new Date(selectedDate)
      ),
    [selectedDate]
  );

  // Assignment type
  const assignmentTypeDropdownItems = useMemo(
    () => getAssignmentTypeDropdownItems(),
    []
  );

  // useEffect(() => {
  //   // Om man valt en ny kund och tidigare
  //   if (customerId !== initialCustomerId) {
  //     console.log("Körs denna?");
  //     setValue("separatorId", 0);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [customerId, initialCustomerId]);

  const isProxyAssignment = useMemo(
    () => !!huvudkund?.isParentCustomer,
    [huvudkund?.isParentCustomer]
  );

  useEffect(() => {
    if (!isProxyAssignment) {
      setValue("isProxyAssignment", false);
    } else {
      setValue("isProxyAssignment", true);
    }
  }, [isProxyAssignment, setValue]);

  const proxyCustomerDropdownItems = useMemo(
    () =>
      (huvudkund?.childCustomers ?? [])
        .map(customerToDropdownItem)
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [huvudkund]
  );

  const harHuvudkundMenInteUnderkund =
    !!huvudkund?.isParentCustomer && !underkund;

  let dateDropdownItems: DateDropdownItem[] = [];
  if (!!passedDateDropdownItem) {
    dateDropdownItems = [passedDateDropdownItem];
  }
  dateDropdownItems = [...dateDropdownItems, ...weekDropdownItems];

  if (!assignment) {
    return null;
  }

  const separatorPlaceholder = !verksamhetsutovare
    ? "Välj först kund"
    : harHuvudkundMenInteUnderkund
    ? "Välj först verksamhetsutövare"
    : !!verksamhetsutovare && separatorDropdownItems.length === 0
    ? "Kunden saknar objekt"
    : " ";

  const selectedSeparatorId = formData("separatorId");

  const selectedSeparator = verksamhetsutovare?.separators?.find(
    // eslint-disable-next-line eqeqeq
    (sep) => sep.id == selectedSeparatorId
  );

  const hasSelectedAvskiljare = !selectedSeparator
    ? false
    : isAvskiljare(selectedSeparator);

  const isInspection = [
    AssignmentType.FiveYearInspection,
    AssignmentType.SixMonthsInspection,
  ].includes(+formData("type"));

  // const separatorId = formData("separatorId") ?? undefined;
  // console.log({ separatorId });

  const hasStarted = hasInspection(assignment);

  return (
    <ModalContentWrapper>
      <Info text="Vill du ändra kund eller objekt behöver du skapa en ny uppgift och ta bort den befintliga." />
      {/* Kund, underkund */}
      <div className="flex gap-x-4">
        <div className="w-1/2">
          <Dropdown
            items={customerDropdownItems}
            name="customerId"
            label="Kund"
            descriptionItemText=" "
            register={register}
            validation={{
              validate: (value: number) => {
                return value > 0 || "Du måste välja en kund";
              },
            }}
            errorMessage={formErrors?.customerId?.message}
            disabled
          />
        </div>
        <div className="w-1/2">
          <AnimateOpacity isVisible={!!huvudkund?.isParentCustomer}>
            <Dropdown
              items={proxyCustomerDropdownItems}
              name="proxyCustomerId"
              label="Verksamhetsutövare"
              descriptionItemText=""
              register={register}
              validation={{
                validate: (value: number) => {
                  return value > 0 || "Du måste välja en underkund";
                },
              }}
              errorMessage={formErrors?.proxyCustomerId?.message}
              disabled
            />
          </AnimateOpacity>
        </div>
      </div>

      {/* Objekt */}
      <div className="flex gap-x-4 items-end">
        <div className="w-1/2">
          <Dropdown
            items={separatorDropdownItems}
            name="separatorId"
            label="Objekt"
            register={register}
            disabled
            descriptionItemText={separatorPlaceholder}
            validation={{
              validate: (value: number) => {
                return value > 0 || "Du måste välja ett objekt";
              },
            }}
            errorMessage={formErrors?.separatorId?.message}
          />
        </div>
        <div className="w-1/2 flex justify-end pb-0.5"></div>
      </div>

      {/* Operatör, prioriterat uppdrag */}
      <div className="flex gap-x-4 items-end">
        <div className="w-1/2">
          <Dropdown
            items={userDropdownItems}
            name="userId"
            descriptionItemText=" "
            label="Operatör"
            register={register}
            validation={{
              validate: (value: number) =>
                value > 0 || "Du måste välja en operatör",
            }}
            errorMessage={formErrors?.userId?.message}
          />
        </div>
        <div className="w-1/2 pb-2 px-0 flex justify-end items-center gap-x-2">
          <Checkbox
            label="Prioriterat uppdrag"
            name="isPrioritized"
            control={control}
          />
          <div
            data-tooltip-id={`new-assignment-priority-icon-tooltip`}
            data-tooltip-content={
              "Prioriterade uppdrag hamnar överst i operatörens lista och markeras med denna ikon."
            }
            data-tooltip-place="top"
          >
            <PriorityIcon />
          </div>
          <Tooltip id={`new-assignment-priority-icon-tooltip`} />
        </div>
      </div>

      {/* Vecka, exakt dag */}
      <div className="flex gap-x-4">
        <div className="w-1/2">
          <Dropdown
            items={dateDropdownItems}
            name="date"
            label="Vecka"
            register={register}
            validation={{
              validate: (value?: string) =>
                !!value || "Du måste välja ett datum",
            }}
            errorMessage={formErrors?.date?.message}
          />
        </div>
        <div className="w-1/2">
          <Dropdown
            items={daysDropdownItems}
            name="weekday"
            label="Exakt dag (valfritt)"
            register={register}
            disabledOptionValue={""}
            descriptionItemText=" "
          />
        </div>
      </div>

      {/* Uppdragstyp */}
      <div className="flex gap-x-4 items-end">
        <div className="w-1/2">
          <Dropdown
            items={assignmentTypeDropdownItems.filter(
              (assignmentType) =>
                (assignmentType.value !== AssignmentType.SixMonthsInspection &&
                  assignmentType.value !== AssignmentType.FiveYearInspection) ||
                hasSelectedAvskiljare
            )}
            name="type"
            label="Uppgift"
            descriptionItemText=" "
            register={register}
            validation={{
              validate: (value: number) => {
                return value > 0 || "Du måste välja en uppgift";
              },
            }}
            errorMessage={formErrors?.type?.message}
            disabled={hasStarted}
          />
        </div>
        <div className="w-1/2 pb-2 px-0">
          <AnimateOpacity isVisible={isInspection}>
            <Checkbox
              label="Lägg till tömning"
              name="includeTomning"
              control={control}
            />
          </AnimateOpacity>
        </div>
      </div>

      <TextInput
        label="Referensnummer"
        name="referenceNumber"
        register={register}
      />
      <TextArea label="Intern kommentar" name="notes" register={register} />
      <Modal
        isOpen={addSeparatorLogic.isNewSeparatorModalOpen}
        title={"Nytt objekt - " + verksamhetsutovare?.name}
        actionText="Spara"
        onClose={addSeparatorLogic.onNewSeparatorModalClose}
        actionHandler={addSeparatorLogic.onSeparatorSubmit}
        isLoading={addSeparatorLogic.isCreating}
        loadingText="Sparar..."
      >
        <SeparatorMetadataForm
          register={addSeparatorLogic.registerSeparator}
          errors={addSeparatorLogic.addSeparatorErrors}
          copyAddressFromCustomer={addSeparatorLogic.copyAddressFromCustomer}
          selectedSeparatorType={addSeparatorLogic.selectedSeparatorType}
          setValue={addSeparatorLogic.setValue}
          postnummer={addSeparatorLogic.watchSeparator("postalCode") ?? ""}
        />
      </Modal>
    </ModalContentWrapper>
  );
};
