import { getWeek, getYear, isThisWeek } from "date-fns";
import { getWeekSpan, isBeforeThisWeek, isCurrentYear } from "helpers/date";
import { Assignment } from "types/assignment.type";
import swedish from "date-fns/locale/sv";
import { AssignmentStatus } from "enums/AssignmentStatus.enum";
import { z } from "zod";
import { isNumber } from "helpers/number";

export const groupAssignmentsByWeek = (
  assignments: Assignment[]
): WeekWithAssignments[] => {
  const groups = assignments.reduce(
    (result: WeekWithAssignments[], assignment) => {
      // create a composed key: 'year-week'
      const assignmentDate = new Date(assignment.date);
      const week = getWeek(assignmentDate, {
        locale: swedish,
      });
      const year = getYear(assignmentDate);
      const yearWeek = `${year}-${week}`;

      // add this key as a property to the result object
      if (!result.find((item) => item.yearWeek === yearWeek)) {
        result.push({
          yearWeek,
          date: assignment.date,
          assignments: [{ ...assignment }],
          label: isThisWeek(assignmentDate)
            ? `Denna vecka (${getWeekSpan(assignmentDate)})`
            : `Vecka ${week} (${getWeekSpan(assignmentDate)}) ${
                isCurrentYear(new Date(assignment.date)) ? "" : ` - ${year}`
              }`,
          weekHasPassed: isBeforeThisWeek(new Date(assignment.date)),
          isCurrentWeek: isThisWeek(assignmentDate),
        });
      } else {
        result = result.map((item) =>
          item.yearWeek === yearWeek
            ? { ...item, assignments: [...item.assignments, { ...assignment }] }
            : item
        );
      }

      return result;
    },
    []
  );
  return groups
    .sort((a, b) => (a.date < b.date ? -1 : 1))
    .map((group) => ({
      ...group,
      assignments: group.assignments.sort((a, b) =>
        (a.user.name ?? "") < (b.user.name ?? "")
          ? -1
          : (a.user.name ?? "") > (b.user.name ?? "")
          ? 1
          : (a.customer?.name ?? "") < (b.customer?.name ?? "")
          ? -1
          : 1
      ),
    }));
};

export type WeekWithAssignments = {
  label: string;
  yearWeek: string;
  date: string; // yyyy-MM-dd
  assignments: Assignment[];
  weekHasPassed: boolean;
  isCurrentWeek: boolean;
};

export const shouldDisplayLateWarning = (
  assignment: Assignment,
  isLate: boolean
) => {
  if (!isLate) {
    return false;
  }
  if (!assignment.separator) {
    return true; // Vi kan aldrig se att status är tex klarmarkerad om den inte har en separator
  }
  if (!assignment.assignmentStatus) {
    throw Error("shouldDisplayLateWarning missing assignmentStatus");
  }
  return assignment.assignmentStatus < AssignmentStatus.Klarmarkerad;
};

export const wasteCodeSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional(),
  preliminaryWeight: z
    .string()
    .optional()
    .refine((value) => !value || !isNaN(Number(value)), {
      message: "Ange vikten i siffror",
    }),
});

export type WasteCodeSchemaType = z.infer<typeof wasteCodeSchema>;

export const wasteCodeFormSchema = z.object({
  wasteCodes: z.array(wasteCodeSchema),
});

export type WasteCodeFormValues = z.infer<typeof wasteCodeFormSchema>;

export const getWasteCodePreliminaryWeightErrorMessage = (
  item?: WasteCodeSchemaType
) => {
  if (!item) {
    return undefined;
  }
  if (
    item.preliminaryWeight === undefined ||
    item.preliminaryWeight === "" ||
    item.preliminaryWeight === null
  ) {
    return undefined;
  }
  if (isNumber(item.preliminaryWeight)) {
    if (Number(item.preliminaryWeight) < 0) {
      return "Vikten får inte vara negativ";
    } else {
      return undefined;
    }
  }
  return "Ange vikten med siffror";
};
