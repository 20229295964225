import { Navigate, Outlet } from "react-router-dom";
import AppLayout from "./components/AppLayout";
import SeparatorPage from "./pages/app/separator/SeparatorPage";
import { CustomerListPage } from "./pages/app/customer-list/CustomerListPage";
import SignInPage from "./pages/sign-in/SignInPage";
import SixMonthsInspectionPage from "./pages/app/six-months-inspection-page/SixMonthsInspectionPage";
import ImagePage from "./pages/app/image-page/ImagePage";
import FiveYearsInspectionPage from "./pages/app/five-years-inspection-page/FiveYearsInspectionPage";
import PdfPage from "pages/app/pdf-page/PdfPage";
import { MyCompanyPage } from "pages/app/organization-page/OrganizationPage";
import SignUpPage from "pages/sign-up/SignUpPage";
import { AccountPage } from "pages/app/account/AccountPage";
import ResetPasswordPage from "pages/reset-password/ResetPasswordPage";
import ForgotPasswordPage from "pages/forgot-password/ForgotPasswordPage";
import AssignmentListPage from "pages/app/assignment-history-page/AssignmentHistoryPage";
import DashboardPage from "pages/app/dashboard/DashboardPage";
import { appRoutes, RouteUrl } from "routes.type";
import AktuellaUppdragPage from "pages/app/aktuella-uppdrag/AktuellaUppdragPage";
import UserSchedulePage from "pages/app/user-schedule/UserSchedulePage";
import { Role } from "types/enums";
import ChangelogPage from "pages/app/changelog/ChangelogPage";
import CustomerPage from "./pages/app/customer/CustomerPage";
import { WasteStationListPage } from "pages/app/waste-station-list/WasteStationListPage";
import { WasteStationPage } from "pages/app/waste-station/WasteStationPage";
import TomningPage from "pages/app/tomning-page/TomningPage";
import { OpenTransportDocumentsPage } from "pages/app/open-transport-documents-page/OpenTransportDocumentsPage";
import { VehicleContentQrPage } from "pages/app/vehicle-content-qr/VehicleContentQrPage";
import { VehicleContentPage } from "pages/vehicle-content/VehicleContent";
import FeedbackPage from "pages/app/feedback-page/FeedbackPage";
import { NewTransportPage } from "pages/app/naturvardsverket/transport/NewTransportPage";
import { NvRapporteringListPage } from "pages/app/naturvardsverket/NvRapporteringListPage/NvRapporteringListPage";
import { NewTransportPlaneringPage } from "pages/app/naturvardsverket/transportplanering/NewTransportPlaneringPage";
import { CompanyListPage } from "pages/app/company-list/CompanyListPage";
import { MagicLinkLoginPage } from "pages/magic-link-login/MagicLinkLoginPage";
import { WasteStationNotificationPage } from "pages/waste-station-notification/WasteStationNotificationPage";

const routes = (isLoggedIn: boolean, role: Role) => [
  {
    path: RouteUrl.WasteStationNotification,
    element: <WasteStationNotificationPage />,
  },
  {
    path: RouteUrl.App,
    element: isLoggedIn ? (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ) : (
      <Navigate to={RouteUrl.Login} />
    ),
    children: [
      {
        path: RouteUrl.Customers,
        element: <Outlet />,
        children: [
          {
            path: ":customerId",
            element: <Outlet />,
            children: [
              {
                path: ":separatorId",
                element: <Outlet />,
                children: [
                  {
                    path: RouteUrl.FiveYearsInspection,
                    element: <Outlet />,
                    children: [
                      {
                        path: ":inspectionId",
                        element: <FiveYearsInspectionPage />,
                      },
                    ],
                  },
                  {
                    path: RouteUrl.SixMonthsInspection,
                    element: <Outlet />,
                    children: [
                      {
                        path: ":inspectionId",
                        element: <SixMonthsInspectionPage />,
                      },
                    ],
                  },
                  {
                    path: RouteUrl.Tomning,
                    element: <Outlet />,
                    children: [
                      {
                        path: ":id",
                        element: <TomningPage />,
                      },
                    ],
                  },
                  { path: "", element: <SeparatorPage /> },
                ],
              },
              { path: "", element: <CustomerPage /> },
            ],
          },
          { path: "", element: <CustomerListPage /> },
        ],
      },
      {
        path: RouteUrl.Companies,
        element: <Outlet />,
        children: [
          { path: ":conpanyId", element: <h1>Företagssida</h1> },
          { path: "", element: <CompanyListPage /> },
        ],
      },
      { path: RouteUrl.Schedule, element: <AktuellaUppdragPage /> },
      { path: RouteUrl.UserSchedule, element: <UserSchedulePage /> },
      {
        path: RouteUrl.Avfallsregistret,
        element: <Outlet />,
        children: [
          {
            path: RouteUrl.RapporteraTransport,
            element: <NewTransportPage />,
          },
          {
            path: RouteUrl.RapporteraTransportPlanering,
            element: <NewTransportPlaneringPage />,
          },
          {
            path: ":id",
            element: <h1>en specifik rapportering</h1>,
          },
          { path: "", element: <NvRapporteringListPage /> },
        ],
      },
      {
        path: RouteUrl.WasteStations,
        element: <Outlet />,
        children: [
          {
            path: ":id",
            element: <WasteStationPage />,
          },
          { path: "", element: <WasteStationListPage /> },
        ],
      },
      { path: RouteUrl.Inspections, element: <AssignmentListPage /> },
      { path: RouteUrl.Dashboard, element: <DashboardPage /> },
      { path: RouteUrl.Organization, element: <MyCompanyPage /> },
      { path: RouteUrl.Account, element: <AccountPage /> },
      { path: RouteUrl.Changelog, element: <ChangelogPage /> },
      { path: RouteUrl.Feedback, element: <FeedbackPage /> },
      {
        path: RouteUrl.OpenTransportDocuments,
        element: <OpenTransportDocumentsPage />,
      },
      {
        path: "",
        element: (
          <Navigate
            to={
              (role ?? Role.Guest) > Role.User
                ? appRoutes.dashboard.fullPath
                : appRoutes.userSchedule.fullPath
            }
          />
        ),
      },
    ],
  },
  {
    path: RouteUrl.Login,
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <SignInPage />,
  },
  {
    path: RouteUrl.VehicleContentQr,
    children: [
      {
        path: ":id",
        element: <VehicleContentQrPage />,
      },
      { path: "", element: <VehicleContentQrPage /> },
    ],
  },
  {
    path: RouteUrl.VehicleContent,
    element: <VehicleContentPage />,
  },
  // {
  //   path: "/cv",
  //   element: <CV />,
  // },
  {
    path: RouteUrl.Signup,
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <SignUpPage />,
  },
  {
    path: RouteUrl.ForgotPassword,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <ForgotPasswordPage />
    ),
  },
  {
    path: RouteUrl.ResetPassword,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <ResetPasswordPage />
    ),
  },
  {
    path: RouteUrl.MagicLinkLogin,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <MagicLinkLoginPage />
    ),
  },
  {
    path: "image",
    element: <Outlet />,
    children: [
      {
        path: ":img",
        element: <ImagePage />,
      },
      { path: "", element: <ImagePage /> },
    ],
  },
  {
    path: "pdf",
    element: <Outlet />,
    children: [
      {
        path: ":pdf",
        element: <PdfPage />,
      },
      { path: "", element: <PdfPage /> },
    ],
  },
  {
    path: "/",
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <Navigate to={RouteUrl.Login} />
    ),
  },
];

// Ska ha en entity för planerat jobb
// Assignment
// Ska göra det rätt den här gången
// En assignment måste alltid vara planerad på en person.
// Den KAN ha en vecka, den kan ha en dag.
// Den har en kund<
// den har en typ
// den kan ha en separator

// Man ska kunna

// Finns det någon poäng öht att skapa ett assignment utan en person?
// Naj.
// En operatör ska kunna

// börja med att bara tilldela dem till en vecka.
// Ska man välja den från en dropdown då eller vad?

export default routes;
