import {
  consolidateAssignments,
  useAssignmentsForUser,
} from "api/assignment.api";
import { PageLoader } from "components/PageLoader";
import { Panel } from "components/Panel";
import { Assignment } from "types/assignment.type";
import { FC, useEffect, useState } from "react";
import { Transition } from "components/animations/Transition";
import userStore, { getUserId } from "store/user.store";
import { AssignmentType } from "enums/AssignmentType.enum";
import { useCreateSixMonthsInspection } from "api/sixMonthsInspection.api";
import { useCreateFiveYearsInspection } from "api/fiveYearsInspection.api";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { getLinkToFiveYearsInspectionPage } from "../five-years-inspection-page/FiveYearsInspectionPage";
import { getLinkToSixMonthsInspectionPage } from "../six-months-inspection-page/SixMonthsInspectionPage";
import {
  CircleStackIcon,
  ClockIcon,
  DocumentMagnifyingGlassIcon,
  ClipboardDocumentListIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import { AssignmentStatusTag } from "components/AssignmentStatusTag";
import { isEmptyArray } from "helpers/array";
import { config } from "react-spring";
import { PageTransition } from "components/animations/SnapUp";
import { useCreateTomning } from "api/tomning.api";
import { getLinkToTomningPage } from "../tomning-page/TomningPage";
import { AssignmentInfoModal } from "../aktuella-uppdrag/AssignmentInfoModal";
import { AssignmentTypeTag } from "components/AssignmentTypeTag";
import { Tips } from "components/Tips";
import { getIdentifierWithType } from "types/separator/separator.helpers";
import { PriorityIcon } from "../aktuella-uppdrag/PriorityIcon";
import { formatDateWithMonth, getDayNameFromWeekDay } from "helpers/date";
import { useEditAssignmentLogic } from "./useEditAssignment.logic";
import { UpdateAssignmentModal } from "./UpdateAssignmentModal";
import { ActionMenu } from "../dashboard/ActionMenu";
import { WasteStationIcon } from "components/WasteStationIcon";

export default function UserSchedulePage() {
  const userId = userStore(getUserId);
  // Assignments
  const { data: schedulePageResult, isLoading: isLoadingSchedulePageResult } =
    useAssignmentsForUser(userId ?? -1);

  const [assignments, setAssignments] = useState<Assignment[]>([]);

  useEffect(() => {
    if (schedulePageResult) {
      setAssignments(consolidateAssignments(schedulePageResult));
    } else {
      setAssignments([]);
    }
  }, [schedulePageResult]);

  // Create inspection and navigate
  const navigate = useNavigate();
  const {
    data: createdSixMonthsInspection,
    mutate: createSixMonthsInspection,
    isLoading: isCreatingSixMonthsInspection,
  } = useCreateSixMonthsInspection();

  useEffect(() => {
    if (createdSixMonthsInspection) {
      navigate(
        getLinkToSixMonthsInspectionPage(
          createdSixMonthsInspection.separator.customer.id,
          createdSixMonthsInspection.separator.id,
          createdSixMonthsInspection.id
        )
      );
    }
  }, [createdSixMonthsInspection, navigate]);

  const {
    data: createdFiveYearsInspection,
    mutate: createFiveYearsInspection,
    isLoading: isCreatingFiveYearsInspection,
  } = useCreateFiveYearsInspection();

  useEffect(() => {
    if (createdFiveYearsInspection) {
      navigate(
        getLinkToFiveYearsInspectionPage(
          createdFiveYearsInspection.separator.customer.id,
          createdFiveYearsInspection.separator.id,
          createdFiveYearsInspection.id
        )
      );
    }
  }, [createdFiveYearsInspection, navigate]);

  const {
    mutate: createTomning,
    data: createdTomning,
    isLoading: isCreatingTomning,
  } = useCreateTomning();

  useEffect(() => {
    if (createdTomning) {
      navigate(
        getLinkToTomningPage(
          createdTomning.transportDocument.customerId,
          createdTomning.separator.id,
          createdTomning.id
        )
      );
    }
  }, [createdTomning, navigate]);

  const toInspectionClick = (assignment: Assignment) => {
    switch (assignment.type) {
      case AssignmentType.FiveYearInspection: {
        if (assignment.fiveYearsInspection) {
          navigate(
            getLinkToFiveYearsInspectionPage(
              assignment.customer.id,
              assignment.separator.id,
              assignment.fiveYearsInspection.id
            )
          );
          break;
        }

        createFiveYearsInspection({
          separatorId: assignment.separator.id,
          inspectionDate: new Date(format(new Date(), "yyyy-MM-dd")),
          assignmentId: assignment.id,
        });
        break;
      }
      case AssignmentType.SixMonthsInspection: {
        if (assignment.sixMonthsInspection) {
          navigate(
            getLinkToSixMonthsInspectionPage(
              assignment.customer.id,
              assignment.separator.id,
              assignment.sixMonthsInspection.id
            )
          );
          break;
        }
        createSixMonthsInspection({
          separatorId: assignment.separator.id,
          inspectionDate: new Date(format(new Date(), "yyyy-MM-dd")),
          assignmentId: assignment.id,
        });
        break;
      }
      case AssignmentType.Tomning: {
        if (assignment.tomning) {
          navigate(
            getLinkToTomningPage(
              assignment.customer.id,
              assignment.separator.id,
              assignment.tomning.id
            )
          );
          break;
        }
        createTomning({
          separatorId: assignment.separator.id,
          inspectionDate: new Date(format(new Date(), "yyyy-MM-dd")),
          assignmentId: assignment.id,
        });
        break;
      }
      default:
        throw Error(
          "toInspectionClick not implemented for type " + assignment.type
        );
    }
  };

  const [assignmentIdToView, setAssignmentIdToView] = useState<number | null>(
    null
  );

  if (
    isLoadingSchedulePageResult ||
    isCreatingFiveYearsInspection ||
    isCreatingSixMonthsInspection ||
    isCreatingTomning
  ) {
    return <PageLoader />;
  }

  if (isEmptyArray(assignments)) {
    return (
      <PageTransition>
        <Panel>
          <div className="flex flex-col items-center mx-auto my-12">
            <Transition
              settings={{
                from: {
                  transform: "scale(0)",
                },
                enter: {
                  transform: "scale(1)",
                },
                delay: 153,
                config: config.wobbly,
              }}
              isVisible
            >
              <CheckCircleIcon className="text-cyan-400 h-12 w-12" />
            </Transition>
            <span className="text-base text-gray-800 mt-2">
              Alla uppdrag är genomförda
            </span>
          </div>
        </Panel>
      </PageTransition>
    );
  }

  return (
    <>
      {/* Data att visa upp: User, Customer, Uppgift, vecka, avskiljare */}
      <Transition
        settings={{
          from: { opacity: 0, transform: "translate3d(0px, 30px, 0px)" },
          enter: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
          config: { precision: 0.02 },
        }}
      >
        <Panel>
          <div className="space-y-8">
            <Tips
              paragraphs={[
                "Klicka på ett uppdrag för att öppna det. För att ändra ett uppdrag eller se mer information, använd menyknappen till höger.",
              ]}
              id="9a141osdfjosdifj869b3f4"
            />
            {(schedulePageResult?.prioritizedAssignments ?? []).length > 0 && (
              <div className="pt-4">
                <h3 className="text-sm font-semibold leading-6 text-gray-900 flex items-center gap-x-2">
                  <PriorityIcon /> Prioriterade uppdrag
                </h3>
                <ol className="mt-4 text-sm leading-6 lg:col-span-7 xl:col-span-8 divide-y divide-gray-100 border-y border-y-gray-100">
                  {schedulePageResult?.prioritizedAssignments.map((ass) => (
                    <AssignmentItem
                      key={ass.id}
                      assignment={ass}
                      toInspectionClick={() => toInspectionClick(ass)}
                      openInfoModal={() => setAssignmentIdToView(ass.id)}
                    />
                  ))}
                </ol>
              </div>
            )}

            {schedulePageResult?.weeks.map((week) => (
              <div key={week.weekNumber} className="pt-0">
                <h3 className="text-base font-semibold leading-6 text-gray-900 flex flex-col">
                  {`Vecka ${week.weekNumber}`}
                  <span className="ml-0 text-gray-600 font-normal">
                    {week.weekSpan}
                  </span>
                </h3>

                <ol className="mt-4 text-sm leading-6 lg:col-span-7 xl:col-span-8 divide-y divide-gray-100 border-y border-y-gray-100">
                  {week.assignments?.map((assignment: Assignment) => (
                    <AssignmentItem
                      key={assignment.id}
                      assignment={assignment}
                      toInspectionClick={() => toInspectionClick(assignment)}
                      openInfoModal={() => setAssignmentIdToView(assignment.id)}
                    />
                  ))}
                </ol>
              </div>
            ))}
          </div>
        </Panel>

        <AssignmentInfoModal
          isOpen={!!assignmentIdToView}
          assignmentId={assignmentIdToView}
          closeModal={() => setAssignmentIdToView(null)}
        />
      </Transition>
    </>
  );
}

const AssignmentItem: FC<{
  assignment: Assignment;
  toInspectionClick: () => void;
  openInfoModal: () => void;
}> = ({ assignment, toInspectionClick, openInfoModal }) => {
  const logic = useEditAssignmentLogic(assignment);

  return (
    <>
      <li className="flex py-7 sm:py-5 items-center px-1 sm:px-0">
        <div
          className="flex-grow mr-4 cursor-pointer"
          onClick={toInspectionClick}
        >
          {!!assignment.weekday && (
            <span className="font-medium text-sm my-1">
              {getDayNameFromWeekDay(assignment.weekday)}
            </span>
          )}
          <p className="flex flex-col items-start text-base font-medium text-gray-900 my-1">
            <span className="flex items-start space-x-2">
              {assignment.isPrioritized && <PriorityIcon />}
              <AssignmentTypeTag type={assignment.type} />
              {assignment.includeTomning && (
                <AssignmentTypeTag type={AssignmentType.Tomning} />
              )}
            </span>
          </p>
          <span className="flex items-center text-base sm:text-sm font-medium text-gray-900 my-2">
            <span className="truncate w-48 sm:w-56 text-gray-950">
              {assignment.customer?.name}
            </span>
          </span>
          <span className="flex items-center text-sm text-gray-800 mb-2">
            <CircleStackIcon
              className="mr-1 h-4 flex-shrink-0 text-gray-500"
              aria-hidden="true"
            />
            <span className="truncate w-52 sm:w-56">
              {getIdentifierWithType(assignment.separator)}
            </span>
          </span>
          <span className="block sm:hidden mt-2">
            <AssignmentStatusTag status={assignment.assignmentStatus} />
          </span>

          {assignment.wasteStation && (
            <span className="mt-3 text-gray-900 flex items-center">
              <WasteStationIcon className="mr-1" />
              {assignment.wasteStation.name}
            </span>
          )}
          {assignment.wasteStationNotification && (
            <span className="mt-0 text-gray-700 flex items-center">
              {`Lämnas ${formatDateWithMonth(
                assignment.wasteStationNotification.requestedDate
              )}`}
            </span>
          )}

          {!!assignment.notes && (
            <div className="flex items-center max-w-sm">
              <ChatBubbleBottomCenterTextIcon
                className="mr-1 h-5 flex-shrink-0"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-900">{assignment.notes}</span>
            </div>
          )}
          {!!assignment.reportedHours && (
            <div className="flex items-center max-w-sm">
              <ClockIcon
                className="mr-1 h-4 flex-shrink-0 text-gray-500"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-900">{`${assignment.reportedHours} timmar`}</span>
            </div>
          )}
        </div>
        <div className="hidden sm:flex sm:w-40 justify-start">
          <AssignmentStatusTag status={assignment.assignmentStatus} />
        </div>

        <ActionMenu
          items={[
            {
              label: "Öppna",
              onClick: toInspectionClick,
              icon: (
                <ClipboardDocumentListIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              ),
            },
            {
              label: "Detaljer",
              onClick: openInfoModal,
              icon: (
                <DocumentMagnifyingGlassIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              ),
            },
            {
              label: "Ändra",
              onClick: logic.openModal,
              icon: (
                <PencilSquareIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              ),
            },
          ]}
        />
      </li>
      <UpdateAssignmentModal assignment={assignment} logic={logic} />
    </>
  );
};
