/* This example requires Tailwind CSS v2.0+ */
import { FC, Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "helpers/classNames";
import SecondaryButton from "./buttons/SecondaryButton";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ProxyAssignmentTag } from "./ProxyAssignmentTag";
import { PriorityIcon } from "pages/app/aktuella-uppdrag/PriorityIcon";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  actionText?: string;
  actionHandler?: () => void;
  title: string;
  actionDisabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  isWide?: boolean;
  isPrettyWide?: boolean;
  closeButtonText?: string;
  secondaryAction?: () => void;
  hideButtons?: boolean;
  isProxyAssignment?: boolean;
  isPriorityAssignment?: boolean;
  afterClose?: () => void;
  errorTriangle?: boolean;
}
const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
  actionText,
  actionHandler,
  title,
  actionDisabled = false,
  isLoading = false,
  loadingText = "Sparar...",
  isWide = false,
  isPrettyWide = false,
  closeButtonText = "Avbryt",
  secondaryAction,
  hideButtons = false,
  isProxyAssignment,
  isPriorityAssignment = false,
  afterClose,
  errorTriangle = false,
}) => {
  // const cancelButtonRef = useRef(null);

  const modalRef = useRef<HTMLDivElement>(null);
  const stopDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  useEffect(() => {
    modalRef?.current?.addEventListener("dragover", stopDrop);
    modalRef?.current?.addEventListener("drop", stopDrop);
    return () => {
      modalRef?.current?.removeEventListener("dragover", stopDrop);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      modalRef?.current?.removeEventListener("drop", stopDrop);
    };
  }, []);

  return (
    <Transition.Root show={isOpen} as={Fragment} afterLeave={afterClose}>
      <Dialog
        as="div"
        className="relative z-10"
        // initialFocus={cancelButtonRef}
        onClose={() => null}
        ref={modalRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "relative bg-white rounded-lg pt-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full p-2 pb-4 sm:pb-2 sm:max-w-lg",
                  isWide && "md:max-w-5xl",
                  isPrettyWide && "md:max-w-3xl"
                )}
              >
                <ModalCloseIcon onClick={onClose} />
                <div>
                  <div>
                    <Dialog.Title
                      as="h3"
                      className={classNames(
                        "text-lg leading-6 font-medium text-gray-900 p-2 sm:p-4",
                        !!secondaryAction && "flex justify-between",
                        (!!isProxyAssignment || errorTriangle) &&
                          "flex items-center"
                      )}
                    >
                      {title}
                      {!!isProxyAssignment && (
                        <div className="ml-4">
                          <ProxyAssignmentTag />
                        </div>
                      )}
                      {!!isPriorityAssignment && (
                        <div className="ml-4 text-sm flex items-center gap-x-1">
                          <PriorityIcon />
                          Prioriterat
                        </div>
                      )}
                      {!!errorTriangle && (
                        <div className="ml-2">
                          <ExclamationTriangleIcon className="h-5 w-5 text-red-500" />
                        </div>
                      )}
                      {!!secondaryAction && (
                        <SecondaryButton onClick={secondaryAction} disableFocus>
                          Ändra
                        </SecondaryButton>
                      )}
                    </Dialog.Title>

                    <div className="w-full border-t border-gray-200" />

                    <div className="px-2 sm:px-4 sm:pb-8">{children}</div>
                  </div>
                </div>
                {!hideButtons && (
                  <>
                    <div className="w-full border-t border-gray-200 mt-4 sm:mt-0" />
                    <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense sm:p-4 bg-white mt-8 sm:mt-0 px-2 sm:px-0">
                      {!!actionHandler && (
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-700 disabled:bg-gray-400 text-base font-medium text-white hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300 sm:col-start-2 sm:text-sm"
                          onClick={actionHandler}
                          disabled={actionDisabled || isLoading}
                        >
                          {isLoading && (
                            <svg
                              className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )}

                          {isLoading
                            ? !!loadingText
                              ? loadingText
                              : actionText || title
                            : actionText || title}
                        </button>
                      )}
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={onClose}
                        // ref={cancelButtonRef}
                      >
                        {closeButtonText}
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;

type ModalCloseIconProps = {
  onClick: () => void;
};
export const ModalCloseIcon: FC<ModalCloseIconProps> = ({ onClick }) => (
  <div className="absolute top-0 right-0 block pt-6 pr-4 sm:block">
    <button
      type="button"
      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-flowerpink-500 focus:ring-offset-2"
      onClick={onClick}
      tabIndex={-1}
    >
      <span className="sr-only">Close</span>
      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  </div>
);
