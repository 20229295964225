import React, { FC } from "react";
import { Control, useController } from "react-hook-form";
import { twMerge } from "tailwind-merge";

interface Props {
  label: string;
  name: string;
  control: Control<any>;
  disabled?: boolean;
  labelClassName?: string;
}

export const Checkbox: FC<Props> = ({
  label,
  name,
  control,
  disabled = false,
  labelClassName,
}) => {
  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    defaultValue: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <fieldset>
      <label
        className={twMerge(
          "flex items-center cursor-pointer",
          disabled && "cursor-auto"
        )}
      >
        <input
          id={name}
          type="checkbox"
          onChange={handleChange}
          checked={!!value}
          ref={ref}
          className="focus:ring-cyan-400 h-4 w-4 text-cyan-600 border-gray-300 rounded disabled:text-gray-300"
          disabled={disabled}
        />
        <span
          className={twMerge(
            "ml-2 font-normal text-gray-900 disabled:text-gray-400 select-none text-sm",
            labelClassName
          )}
        >
          {label}
        </span>
      </label>
    </fieldset>
  );
};
