import { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { WasteStationNotificationStatus } from "types/wasteStationNotification.type";

export const WasteStationNotificationStatusIcon = ({
  status,
}: {
  status: WasteStationNotificationStatus;
}) => {
  const colorClass = useMemo(() => {
    switch (status) {
      case WasteStationNotificationStatus.Approved:
        return "text-green-400";
      case WasteStationNotificationStatus.Pending:
        return "text-yellow-400";
      case WasteStationNotificationStatus.Rejected:
        return "text-rose-400";
      default:
        return "text-gray-400";
    }
  }, [status]);

  return (
    <div className={twMerge("flex-none rounded-full p-1", colorClass)}>
      <div className="h-2 w-2 rounded-full bg-current" />
    </div>
  );
};
