import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCustomers } from "api/customer.api";
import PrimaryButton from "components/buttons/PrimaryButton";
import EmptyState from "components/EmptyState";
import Modal from "components/Modal";
import { PageLoader } from "components/PageLoader";
import { Table, THead, TR, TH, TBody, TD } from "components/table";
import {
  CreateCustomerDTO,
  Customer,
  getEmptyCreateCustomerDTO,
} from "types/customer.type";
import AddCustomer from "./AddCustomer";
import { isNumber } from "helpers/number";
import { SearchField } from "components/SearchField";
import { Pagination } from "components/Pagination";
import { appRoutes } from "routes.type";
import { PageTransition } from "components/animations/SnapUp";
import { Panel } from "components/Panel";
import { ProxyAssignmentIcon } from "components/ProxyAssignmentIcon";
import { useAddCustomerLogic } from "hooks/useAddCustomerLogic";
import { CheckboxStupid } from "components/CheckboxStupid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import userStore, { getUserCompanyId } from "store/user.store";

const PAGE_SIZE = 20;

export const CustomerListPage = () => {
  const navigate = useNavigate();

  const companyId = userStore(getUserCompanyId);

  const [searchParams, setSearchParams] = useSearchParams();

  const setQuery = (query: string) =>
    setSearchParams({ query: query.toLowerCase() });

  const queryParam = searchParams.get("query") ?? "";
  const showDeletedParamName = "showdeleted";
  const showDeleted = searchParams.get(showDeletedParamName) === "true";
  const pageParam = searchParams.get("page") ?? "1";
  const pageNumber = isNumber(pageParam) ? +pageParam : 1;

  const setShowDeleted = (show: boolean) =>
    setSearchParams({ showdeleted: show ? "true" : "false" });

  const { data: searchResult, isLoading } = useCustomers({
    query: queryParam,
    offset: (pageNumber - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
    includeDeleted: showDeleted,
    companyId,
  });

  const addCustomer = useAddCustomerLogic();

  const onSubmit = addCustomer.form.handleSubmit((data: CreateCustomerDTO) =>
    addCustomer.mutation.mutate(data)
  );

  const closeModal = () => {
    addCustomer.form.reset(getEmptyCreateCustomerDTO());
    addCustomer.closeModal();
  };

  useEffect(() => {
    if (addCustomer.mutation.isSuccess && addCustomer.mutation.data) {
      navigate(
        `${appRoutes.customers.fullPath}/${addCustomer.mutation.data.id}`
      );
    }
  }, [addCustomer.mutation, navigate]);

  const getCustomerLink = useCallback(
    (customerId: number) => `${appRoutes.customers.fullPath}/${customerId}`,
    []
  );

  if (isLoading) {
    return <PageLoader />;
  }

  if (!searchResult || (!queryParam && searchResult.customers.length === 0)) {
    return (
      <PageTransition>
        <Panel>
          <EmptyState
            header="Inga kunder"
            subHeader="Kom igång genom att skapa din första kund"
            buttonText="Ny kund"
            onClick={addCustomer.openModal}
          />
        </Panel>
        <Modal
          isOpen={addCustomer.isModalOpen}
          onClose={closeModal}
          title="Lägg till kund"
          actionHandler={onSubmit}
          isLoading={addCustomer.mutation.isLoading}
          loadingText="Sparar..."
        >
          <AddCustomer
            register={addCustomer.form.register}
            errors={addCustomer.form.formState.errors}
            control={addCustomer.form.control}
          />
        </Modal>
      </PageTransition>
    );
  }

  return (
    <PageTransition>
      <div className="px-4 sm:px-0">
        <div className="sm:flex sm:items-center mb-4">
          <div className="sm:flex-auto space-y-2">
            <SearchField
              placeholder="Namn, kundnummer, email, telefon"
              onSearch={(query: string) => {
                if (query) {
                  setQuery(query);
                } else {
                  searchParams.delete("query");
                  setSearchParams(searchParams);
                }
              }}
              initialValue={queryParam}
            />
            <CheckboxStupid
              label="Visa borttagna"
              name="showDeleted"
              checked={showDeleted}
              setChecked={(checked) => setShowDeleted(checked)}
              small
            />
          </div>
          {/* {isAdmin && ( */}
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex items-end justify-end">
            <PrimaryButton onClick={addCustomer.openModal}>
              + Ny kund
            </PrimaryButton>
          </div>
          {/* )} */}
        </div>

        <Table>
          <THead>
            <TR>
              {/* <TH className="w-0 px-0">
                <></>
              </TH> */}
              <TH isFirstColumn>Företag</TH>
              <TH>Kontaktperson</TH>
              <TH hideOnMobile>Rapporteringsombud</TH>
              <TH hideOnTablet>Stad</TH>
            </TR>
          </THead>
          <TBody>
            {searchResult.customers.map(
              ({
                id,
                name,
                contactName,
                contactPhone,
                city,
                isParentCustomer,
                nvFullmakt,
              }: Customer) => (
                <TR key={id} link={getCustomerLink(id)}>
                  <TD isFirstColumn className="flex items-center gap-x-2">
                    {name}
                    {isParentCustomer && <ProxyAssignmentIcon />}
                  </TD>
                  <TD>{contactName}</TD>
                  <TD hideOnMobile>
                    {nvFullmakt ? (
                      <CheckCircleIcon
                        className="flex-shrink-0 h-5 w-5 text-cyan-400"
                        aria-hidden="true"
                      />
                    ) : (
                      ""
                    )}
                  </TD>
                  <TD hideOnTablet>{city}</TD>
                </TR>
              )
            )}
          </TBody>
        </Table>
      </div>

      <Pagination
        currentPage={pageNumber}
        totalCount={searchResult?.count ?? 0}
        pageSize={PAGE_SIZE}
        setPage={(page: number) => setSearchParams({ page: page.toString() })}
      />
      <Modal
        isOpen={addCustomer.isModalOpen}
        onClose={closeModal}
        title="Lägg till kund"
        actionHandler={onSubmit}
        isLoading={addCustomer.mutation.isLoading}
        loadingText="Sparar..."
      >
        <AddCustomer
          register={addCustomer.form.register}
          errors={addCustomer.form.formState.errors}
          control={addCustomer.form.control}
        />
      </Modal>
    </PageTransition>
  );
};
