import { classNames } from "helpers/classNames";
import React, { FC } from "react";
import { twMerge } from "tailwind-merge";
interface Props {
  children: React.ReactNode;
  light?: boolean;
}
export const ModalLabel: FC<
  Props &
    React.DetailedHTMLProps<
      React.LabelHTMLAttributes<HTMLLabelElement>,
      HTMLLabelElement
    >
> = ({ children, light = false, className, ...rest }) => (
  <label
    className={twMerge(
      classNames(
        "flex text-sm font-semibold",
        light ? "text-gray-600" : "text-gray-800"
      ),
      className
    )}
    {...rest}
  >
    {children}
  </label>
);
