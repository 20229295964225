import { Badge } from "components/Badge";
import React, { FC } from "react";
import { Role } from "types/enums";
import { classNames } from "../../helpers/classNames";
// import { SmoothUpNew } from "components/animations/SnapUp";

export interface Tab {
  label: string;
  content: React.ReactNode;
  isSelected: boolean;
  labelName?: string;
  button?: React.ReactNode;
  showIfDeleted?: boolean;
  minimumRole?: Role;
  badge?: string | undefined;
  isHidden?: boolean;
}
interface Props {
  tabs: Tab[];
  setTab: (label: string) => void;
  isDashboard?: boolean; // Kör annat tema där
}
export const TabPageWrapper: FC<Props> = ({
  tabs,
  setTab,
  isDashboard = false,
}) => {
  const filteredTabs = tabs.filter((tab) => !tab.isHidden);
  return (
    <div>
      <div className="md:mt-7 shadow sm:rounded-lg bg-white sm:bg-white max-w-5xl py-4 sm:py-0">
        <div className="sm:hidden px-4">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md focus:ring-cyan-500 focus:border-cyan-500"
            defaultValue={
              filteredTabs.find((tab: Tab) => tab?.isSelected)?.label
            }
            onChange={(e) => setTab(e.target.value)}
          >
            {filteredTabs.map((tab) => (
              <option key={tab.label} value={tab.label}>
                {tab.labelName ?? tab.label}
              </option>
            ))}
          </select>
          {filteredTabs.find((tab) => tab?.isSelected)?.button && (
            <div className="mt-4 flex justify-end">
              {filteredTabs.find((tab) => tab?.isSelected)?.button}
            </div>
          )}
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200 flex items-center justify-between pr-8">
            <nav className="-mb-px flex space-x-8 pl-8" aria-label="Tabs">
              {filteredTabs.map(({ label, isSelected, labelName, badge }) => (
                <div
                  key={label}
                  onClick={() => setTab(label)}
                  className={classNames(
                    isSelected
                      ? "border-cyan-500 text-cyan-600 font-semibold"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 font-semibold",
                    "whitespace-nowrap py-4 px-1 border-b-2 text-sm cursor-pointer tracking-wide flex"
                  )}
                  aria-current={isSelected ? "page" : undefined}
                >
                  {labelName ?? label}
                  <div className="pl-1">
                    {!!badge && (
                      <Badge
                        text={badge}
                        isSelected
                        selectedColors={
                          isDashboard
                            ? "bg-pink-50 text-pink-600"
                            : "bg-cyan-100 text-cyan-700"
                        }
                      />
                    )}
                  </div>
                </div>
              ))}
            </nav>
            {filteredTabs.find((tab) => tab?.isSelected)?.button &&
              filteredTabs.find((tab) => tab?.isSelected)?.button}
          </div>
        </div>
        {/* <div className="overflow-hidden"> */}
        <div className="px-4 sm:px-8 pt-4 sm:py-8">
          {filteredTabs.find((tab) => tab?.isSelected)?.content}
        </div>
      </div>
    </div>
  );
};
