import { FC } from "react";
import {
  AssignmentType,
  assignmentTypeNameShort,
  getAssignmentTypeName,
} from "enums/AssignmentType.enum";
import { twMerge } from "tailwind-merge";

interface Props {
  type: AssignmentType;
  className?: string;
}

export const AssignmentTypeTag: FC<Props> = ({ type, className }) => {
  switch (type) {
    case AssignmentType.SixMonthsInspection:
      return (
        <span
          className={twMerge(
            "inline-flex items-center gap-x-0.5 rounded-md bg-sky-50 pl-1.5 pr-2 py-0.5 text-xs font-medium text-sky-950 ring-1 ring-inset ring-sky-600/10",
            className
          )}
        >
          {getAssignmentTypeName(type)}
        </span>
      );
    case AssignmentType.FiveYearInspection:
      return (
        <span
          className={twMerge(
            "inline-flex items-center gap-x-0.5 rounded-md bg-brand-50 pl-1.5 pr-2 py-0.5 text-xs font-medium text-brand-950 ring-1 ring-inset ring-brand-600/10",
            className
          )}
        >
          {getAssignmentTypeName(type)}
        </span>
      );
    case AssignmentType.Tomning:
      return (
        <span
          className={twMerge(
            "inline-flex items-center gap-x-0.5 rounded-md bg-stone-50 pl-1.5 pr-2 py-0.5 text-xs font-medium text-stone-950 ring-1 ring-inset ring-stone-600/10",
            className
          )}
        >
          {getAssignmentTypeName(type)}
        </span>
      );

    default:
      throw Error(`AssignmentTypeTag is missing implementation for ${type}`);
  }
};

export const AssignmentTypeTagSmall: FC<Props> = ({ type, className }) => {
  switch (type) {
    case AssignmentType.SixMonthsInspection:
      return (
        <span
          className={twMerge(
            "inline-flex items-center gap-x-0.5 rounded-md bg-sky-50 pl-1.5 pr-2 py-1 text-xs font-medium text-sky-950 ring-1 ring-inset ring-sky-600/10",
            className
          )}
        >
          {assignmentTypeNameShort(type)}
        </span>
      );
    case AssignmentType.FiveYearInspection:
      return (
        <span
          className={twMerge(
            "inline-flex items-center gap-x-0.5 rounded-md bg-brand-50 pl-1.5 pr-2 py-1 text-xs font-medium text-brand-950 ring-1 ring-inset ring-brand-600/10",
            className
          )}
        >
          {assignmentTypeNameShort(type)}
        </span>
      );
    case AssignmentType.Tomning:
      return (
        <span
          className={twMerge(
            "inline-flex items-center gap-x-0.5 rounded-md bg-stone-50 pl-1.5 pr-2 py-1 text-xs font-medium text-stone-950 ring-1 ring-inset ring-stone-600/10",
            className
          )}
        >
          {assignmentTypeNameShort(type)}
        </span>
      );

    default:
      throw Error(`AssignmentTypeTag is missing implementation for ${type}`);
  }
};

export const AssignmentTypeButton: FC<Props> = ({ type }) => {
  switch (type) {
    case AssignmentType.SixMonthsInspection:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-sky-50 pl-1.5 pr-2 py-1 text-xs font-medium text-sky-950 ring-1 ring-inset ring-sky-600/10">
          {`Öppna ${getAssignmentTypeName(type)}`}
        </span>
      );
    case AssignmentType.FiveYearInspection:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-brand-50 pl-1.5 pr-2 py-1 text-xs font-medium text-brand-950 ring-1 ring-inset ring-brand-600/10">
          {`Öppna ${getAssignmentTypeName(type)}`}
        </span>
      );
    case AssignmentType.Tomning:
      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-stone-50 pl-1.5 pr-2 py-1 text-xs font-medium text-stone-950 ring-1 ring-inset ring-stone-600/10">
          {`Öppna ${getAssignmentTypeName(type)}`}
        </span>
      );

    default:
      throw Error(`AssignmentTypeTag is missing implementation for ${type}`);
  }
};
