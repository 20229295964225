import { ArchiveBoxArrowDownIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
};
export const WasteStationIcon = ({ className }: Props) => (
  <ArchiveBoxArrowDownIcon
    className={twMerge("text-gray-700 h-4 w-4", className)}
  />
);
