import { useRapporteraTransportPlanering } from "api/avfallsregistret.api";
import {
  TransportDocumentQueryKey,
  usePatchTransportDocument,
  useTransportDocument,
} from "api/transportDocument.api";
import { Panel } from "components/Panel";
import PrimaryButton from "components/buttons/PrimaryButton";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TransportSätt } from "types/tomning.type";
import { FormErrorList } from "components/FormErrorList";
import { useGuessKommun } from "api/postnummer.api";
import { Uppkomstplats } from "./Uppkomstplats";
import { Mottagare } from "./Mottagare";
import { zodResolver } from "@hookform/resolvers/zod";

import { RouteUrl } from "routes.type";
import { Avfall } from "./Avfall";
import { DateTimePicker } from "components/DateTimePicker";
import { Overview } from "./Overview";
import { TextInput } from "components/TextInput";
import { useNotification } from "hooks/useNotification";
import { NvRapporteringStatus } from "enums/NvRapporteringStatus.enum";
import { NvReportedTag } from "components/NvReportedTag";
import { useQueryClient } from "react-query";
import { ActionMenu } from "pages/app/dashboard/ActionMenu";
import { EyeSlashIcon } from "@heroicons/react/24/solid";
import {
  DashboardTabs,
  getLinkToDashboardPage,
} from "pages/app/dashboard/DashboardPage";
import { PageLoader } from "components/PageLoader";
import {
  TransportPlaneringDTO,
  TransportPlaneringDtoSchema,
} from "types/transportPlanering.type";
import {
  resetForm,
  tranformDataBeforeSubmit,
  validateLocation,
} from "./NewTransportPlaneringPage.helpers";
import { Verksamhetsutovare } from "./Verksamhetsutovare";
import { Transportör } from "./Transportör";
import { Tips } from "components/Tips";

export const NewTransportPlaneringPage: React.FC = () => {
  // const navigate = useNavigate();
  // tabs
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const transportDocumentId = searchParams.get("tdid");

  const { data: transportDocument, isLoading: isLoadingTransportDocument } =
    useTransportDocument(!transportDocumentId ? -1 : +transportDocumentId);

  const navigateToPåminnelser = useCallback(
    () => navigate(getLinkToDashboardPage(DashboardTabs.Avfallsregistret)),
    [navigate]
  );

  const notification = useNotification();

  // Man ska kunna skapa en helt ny eller utgå från ett existerande transportdokument.
  const form = useForm<TransportPlaneringDTO>({
    defaultValues: {
      transportsatt: TransportSätt.Vägtransport,
      verksamhetsutovare: "",
      verksamhetensNamn: "",
    },
    resolver: zodResolver(TransportPlaneringDtoSchema),
    mode: "onSubmit",
  });

  // Gissa postnummer
  const gissaMottagareKommun = useGuessKommun();

  useEffect(() => {
    if (gissaMottagareKommun.data) {
      form.setValue("mottagningsplats.kommunkod", gissaMottagareKommun.data);
    }
  }, [gissaMottagareKommun.data, form]);

  const avfallFieldArray = useFieldArray({
    name: "avfall",
    control: form.control,
  });

  useEffect(() => {
    if (transportDocument) {
      resetForm(form, transportDocument, avfallFieldArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportDocument]);

  const mutation = useRapporteraTransportPlanering();

  useEffect(() => {
    if (mutation.isSuccess) {
      notification.success("Ombudsrapportering lyckades");
      queryClient.invalidateQueries([
        TransportDocumentQueryKey.GetOne,
        +(transportDocumentId ?? "-1"),
      ]);
      queryClient.invalidateQueries([
        TransportDocumentQueryKey.GetNotReportedToNv,
      ]);
      navigateToPåminnelser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess]);

  const onSubmit = form.handleSubmit((data: TransportPlaneringDTO) => {
    const hasLocationError = validateLocation(form, data);
    if (hasLocationError) {
      return;
    }
    mutation.mutate(tranformDataBeforeSubmit(data));
  });

  const error = mutation.error;
  let errorList: string[] = useMemo(() => {
    if (error) {
      const axiosError = error as any;
      if (axiosError.response?.data?.message) {
        if (Array.isArray(axiosError.response.data.message)) {
          return axiosError.response.data.message;
        } else {
          return [axiosError.response.data.message as string];
        }
      }
    }
    return [];
  }, [error]);

  useEffect(() => {
    if (errorList) {
      if (errorList.includes("Felaktigt värde för Verksamhetsutovare")) {
        form.setError("verksamhetsutovare", {
          message: "Felaktigt organisationsnummer",
        });
      }
      if (
        errorList
          .map((x) => x.toLowerCase())
          .includes("verksamhetensKontaktpersonNamn".toLowerCase())
      ) {
        form.setError("verksamhetensKontaktpersonNamn", {
          message: "Ange kontaktpersonens namn utan specialtecken",
        });
      }
      if (errorList.includes("Felaktigt värde för NyInnehavare")) {
        form.setError("nyInnehavare", {
          message: "Felaktigt organisationsnummer",
        });
      }
      if (
        errorList.includes(
          "Verksamhetsutövare eller ombud måste matcha rapportörens organisationsnummer"
        ) ||
        errorList.includes("Felaktigt värde för Ombud") ||
        errorList.includes("Felaktigt värde för Transportor")
      ) {
        form.setError("ombud", {
          message: "Ange ert organisationsnummer",
        });
      }
      if (errorList.includes("Felaktigt värde för CfarNr")) {
        form.setError("cfarNR", {
          message: "Felaktigt cfarNR",
        });
      }
    }
  }, [errorList, form]);

  const patchTd = usePatchTransportDocument(+(transportDocumentId ?? "-1"));

  useEffect(() => {
    if (patchTd.isSuccess) {
      // Gå tillbaka till fittsidan
      notification.info("Påminnelse borttagen");
      queryClient.invalidateQueries([
        TransportDocumentQueryKey.GetNotReportedToNv,
      ]);
      navigateToPåminnelser();
    }
  }, [patchTd.isSuccess, navigateToPåminnelser, queryClient, notification]);

  const isCompleted =
    transportDocument?.transportPlaneringRapporteringStatus ===
    NvRapporteringStatus.Completed;

  if (isLoadingTransportDocument) {
    return <PageLoader />;
  }

  return (
    // <PageTransition>
    <FormProvider {...form}>
      <Panel innerClassname="py-4">
        <div className="max-w-[640px]">
          <Tips
            id=",ajdhfkjashdkjashdkjashdkjashdkjahsdkjhaskd"
            paragraphs={[
              "Informationen ni lägger till eller uppdaterar – inklusive detaljer om kund, objekt och mottagarstation – sparas automatiskt på respektive plats. Detta innebär att ni inte behöver fylla i samma information på nytt vid framtida rapporteringar. Vi gör detta för att förenkla processen och spara tid vid varje rapporteringstillfälle.",
            ]}
          />
        </div>
        <div className="w-full h-0 relative">
          <div className="absolute top-[-16px] left-[-24px]">
            {isCompleted && <NvReportedTag />}
          </div>
          <div className="absolute top-[-4px] right-[-12px] ">
            <ActionMenu
              items={[
                {
                  label: "Ta bort påminnelse",
                  onClick: () =>
                    patchTd.mutate({
                      transportPlaneringRapporteringStatus:
                        NvRapporteringStatus.Ignored,
                    }),
                  icon: (
                    <EyeSlashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                  ),
                },
              ]}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 divide-x mt-4 p-4 rounded-lg border border-gray-200 rounded-b-none">
          <Overview td={transportDocument} />
          <Avfall
            avfallFieldArray={avfallFieldArray}
            register={form.register}
            formState={form.formState}
            disabled={isCompleted}
          />
          <div className="flex flex-col gap-y-4 px-6">
            <h2 className="text-gray-900 text-base font-medium">Transport</h2>
            <DateTimePicker
              control={form.control}
              name="borttransportDatum"
              label="Tidpunkt för avfärd"
              disabled={isCompleted}
            />
            <TextInput
              register={form.register}
              label="Referens"
              name="referens"
              placeholder="Valfritt"
              disabled={isCompleted}
            />

            {/* <Dropdown
              items={TransportSättDropdownItems}
              name="transportsatt"
              label={"Transportmedel"}
              register={form.register}
              disabled={isCompleted}
            /> */}
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 divide-x mt-0 p-4 rounded-lg border border-gray-200 rounded-t-none border-t-0">
          {/* Avsändare */}
          <Verksamhetsutovare
            register={form.register}
            formState={form.formState}
            disabled={isCompleted}
          />
          <Uppkomstplats
            register={form.register}
            formState={form.formState}
            watch={form.watch}
            setValue={form.setValue}
            disabled={isCompleted}
            errorList={errorList}
          />
          <Mottagare
            register={form.register}
            formState={form.formState}
            watch={form.watch}
            setValue={form.setValue}
            disabled={isCompleted}
            errorList={errorList}
          />
          <Transportör
            register={form.register}
            formState={form.formState}
            disabled={isCompleted}
          />
        </div>

        <FormErrorList errors={errorList} />
        <div className="flex items-end mt-4 justify-between">
          {/* <SecondaryButton size="large">Ignorera påminnelse</SecondaryButton> */}
          <div>
            {!isCompleted && (
              <PrimaryButton
                // className="mt-8"
                size="large"
                onClick={onSubmit}
                isLoading={mutation.isLoading}
              >
                Rapportera till Avfallsregistret
              </PrimaryButton>
            )}
          </div>
        </div>
      </Panel>
    </FormProvider>
    // </PageTransition>
  );
};

export const getLinkToRapporteraTransportPlaneringPage = (tdId?: number) =>
  `${RouteUrl.App}/${RouteUrl.Avfallsregistret}/${
    RouteUrl.RapporteraTransportPlanering
  }${tdId ? `?tdid=${tdId}` : ""}`;
