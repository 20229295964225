import { DateDropdownItem } from "components/DateDropdownStupid";
import { DropdownItem } from "components/Dropdown";
import {
  addDays,
  addWeeks,
  differenceInCalendarWeeks,
  endOfWeek,
  format,
  getWeek,
  getYear,
  isBefore,
  isSameYear,
  isThisWeek,
  isToday,
  startOfWeek,
} from "date-fns";
import swedish from "date-fns/locale/sv";
import { TomningReminderMonths } from "types/separator/separator.type";

export const dateToYearMonthDay = (date?: Date | null) =>
  format(date ?? new Date(), "yyyy-MM-dd");

export const formatDate = (date?: string | Date): string => {
  if (!date) return "-";
  if (isToday(new Date(date))) {
    return "Idag";
  }
  return format(new Date(date), "dd MMMM yyyy", {
    locale: swedish,
  });
};

export const nowAsISOString = () => {
  const date = new Date();
  const timezoneOffset = date.getTimezoneOffset() * 60000;
  // Adjust the date by the timezone offset to get the correct local time
  return new Date(date.getTime() - timezoneOffset).toISOString();
};

// OBS Lägger på 2 timmar för att kompensera för tidsdiff, detta är såklart en temp lösning
export const formatDateWithTime = (date?: string | Date): string => {
  if (!date) return "-";
  if (isToday(new Date(date))) {
    return `${format(new Date(date), "HH:mm", {
      locale: swedish,
    })}`;
  }
  return format(new Date(date), "dd MMMM yyyy", {
    locale: swedish,
  });
};

// capitalize first letter
export const getMonthName = (date?: string | Date): string => {
  if (!date) return "-";
  const month = format(new Date(date), "MMMM", {
    locale: swedish,
  });
  return month.charAt(0).toUpperCase() + month.slice(1);
};

export const getMonthNameWithYearIfNotThisYear = (
  date?: string | Date
): string => {
  if (!date) return "-";
  const month = format(new Date(date), "MMMM", {
    locale: swedish,
  });

  return `${month.charAt(0).toUpperCase()}${month.slice(1)}${
    !isSameYear(new Date(), new Date(date)) ? ` ${getYear(new Date(date))}` : ""
  }`;
};

export const getMonthNameWithYear = (date?: string | Date): string => {
  if (!date) return "-";
  const month = format(new Date(date), "MMMM", {
    locale: swedish,
  });

  return `${month.charAt(0).toUpperCase()}${month.slice(1)}${` ${getYear(
    new Date(date)
  )}`}`;
};

export const formatDateWithMonth = (date: Date) =>
  format(new Date(date), "dd MMMM", {
    locale: swedish,
  });

export const dateHasPassed = (date?: Date) =>
  !!date && isBefore(date, new Date());

// Om det har passerat så var datum
// nu vecka 47
// om passerat, dateWeek = 49
export const formatDateRelative = (date?: Date) => {
  if (!date) return "";
  const hasPassed = dateHasPassed(date);

  const diffInWeeks = differenceInCalendarWeeks(new Date(), date);
  if (hasPassed) {
    if (diffInWeeks > 2) {
      return formatDate(date);
    }
    if (diffInWeeks === 2) {
      return "för två veckor sedan";
    } else if (diffInWeeks === 1) {
      return "förra veckan";
    } else {
      return "denna veckan";
    }
  } else {
    if (diffInWeeks < -2) {
      return formatDate(date);
    } else if (diffInWeeks === -2) {
      return "om två veckor";
    } else if (diffInWeeks === -1) {
      return "nästa vecka";
    } else {
      return "denna veckan";
    }
  }
};

// Denna borde sätta alla datum till typ onsdag, eller köra veckonummer. men just nu funkar detta
export const getWeeksAsDropdownItems = (
  numberOfWeeks: number = 16,
  startDate: Date = new Date()
): DateDropdownItem[] =>
  weeksToDropdownItems(generateWeeks(numberOfWeeks, startDate));

const generateWeeks = (
  numberOfWeeks: number,
  startDate: Date
): { date: Date; week: number; year: number }[] => {
  let result = [];
  // console.log(endOfWeek(addWeeks(new Date(), 1), { locale: swedish }));

  for (let i = 0; i < numberOfWeeks; i++) {
    const date = addWeeks(startDate, i);
    result.push({
      date,
      week: getWeek(date, { locale: swedish }),
      year: getYear(date),
    });
  }
  return result;
};

export const getDaysAsDropdownItems = (week?: Date | null) => {
  if (!week) {
    return [];
  }

  const start = startOfWeek(week, { weekStartsOn: 1 }); // Adjust to make week start on Monday
  const days: DropdownItem[] = [];

  for (let i = 0; i < 5; i++) {
    // Loop from Monday to Friday
    const currentDate = addDays(start, i);
    days.push({
      value: i + 1,
      label: firstLetterUppercase(
        `${format(currentDate, "EEEE", { locale: swedish })} ${format(
          currentDate,
          "d MMMM",
          { locale: swedish }
        )}`
      ), // Get the full name of the day in Swedish
    });
  }

  return days;
};

export const getDayName = (date: string) =>
  firstLetterUppercase(
    `${format(new Date(date), "EEEE", { locale: swedish })}`
  );

export const getDayNameFromWeekDay = (day?: number) => {
  if (!day) {
    return "";
  }

  switch (+day) {
    case 1:
      return "Måndag";
    case 2:
      return "Tisdag";
    case 3:
      return "Onsdag";
    case 4:
      return "Torsdag";
    case 5:
      return "Fredag";
    case 6:
      return "Lördag";
    case 7:
      return "Söndag";
    default:
      return "";
  }
};

const firstLetterUppercase = (str: string): string =>
  !str ? "" : str.charAt(0).toUpperCase() + str.slice(1);

// Varje value ska vara onsdagen för varje vecka
const weeksToDropdownItems = (
  weeks: { date: Date; week: number; year: number }[]
) =>
  weeks.map((week) => ({
    label: isThisWeek(week.date)
      ? `Denna vecka (${formatWeekSpan(week.date)})`
      : `Vecka ${week.week}${
          isCurrentYear(week.date) ? "" : ` - ${week.year}`
        } (${formatWeekSpan(week.date)})`,
    value: format(getWednesdayOfWeek(week.date), "yyyy-MM-dd"),
  }));

export const dateToDropdownItem = (date: Date): DateDropdownItem => ({
  label: `Vecka ${getWeek(date, { locale: swedish })}${
    isCurrentYear(date) ? "" : ` - ${getYear(date)}`
  } (${formatWeekSpan(date)})`,
  value: "",
});

export const getWeekSpan = (date: Date): string =>
  `${formatDateWithMonth(firstDateOfWeek(date))} - ${formatDateWithMonth(
    addDays(lastDateOfWeek(date), -2)
  )}`;

export const isCurrentYear = (date: Date) =>
  getYear(date) === getYear(new Date());

export const firstDateOfWeek = (date: Date) =>
  startOfWeek(date, { locale: swedish });

export const lastDateOfWeek = (date: Date) =>
  endOfWeek(date, { locale: swedish });

export const getWednesdayOfWeek = (date: Date) =>
  addDays(firstDateOfWeek(date), 2);

// Så jävla buggigt
export const isBeforeThisWeek = (date: Date) =>
  isBefore(date, firstDateOfWeek(new Date()));

export const getWeekWithLabel = (date: Date) =>
  `Vecka ${getWeek(date, {
    locale: swedish,
  })}`;

export function getMonthsString(
  reminderMonths?: TomningReminderMonths
): string {
  if (!reminderMonths) return "";
  const monthsEnglish: (keyof TomningReminderMonths)[] = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const monthsSwedish: string[] = [];

  monthsEnglish.forEach((month, index) => {
    if (reminderMonths[month]) {
      // Add 1 to index as Date takes month as 0 based index
      const date = new Date(2023, index, 1);
      const monthSwedish = format(date, "MMMM", { locale: swedish });
      const monthSwedishCapitalized =
        monthSwedish.charAt(0).toUpperCase() + monthSwedish.slice(1);
      monthsSwedish.push(monthSwedishCapitalized);
    }
  });

  return monthsSwedish.join(", ");
}

export const svenskMånad = (date: Date): string => {
  if (!date) return "-";
  return format(date, "MMMM", { locale: swedish })
    .replace("January", "januari")
    .replace("February", "februari")
    .replace("March", "mars")
    .replace("April", "april")
    .replace("May", "maj")
    .replace("June", "juni")
    .replace("July", "juli")
    .replace("August", "augusti")
    .replace("September", "september")
    .replace("October", "oktober")
    .replace("November", "november")
    .replace("December", "december");
};

export const svenskMånadKort = (date: Date): string => {
  if (!date) return "-";
  return format(date, "MMMM", { locale: swedish })
    .replace("January", "jan")
    .replace("February", "feb")
    .replace("March", "mar")
    .replace("April", "apr")
    .replace("May", "maj")
    .replace("June", "jun")
    .replace("July", "jul")
    .replace("August", "aug")
    .replace("September", "sep")
    .replace("October", "okt")
    .replace("November", "nov")
    .replace("December", "dec");
};

export const formatWeekSpan = (startMonday: Date | string): string => {
  // Ensure the input is a Date object
  const startDate: Date =
    typeof startMonday === "string" ? new Date(startMonday) : startMonday;
  const start = startOfWeek(startDate, { weekStartsOn: 1 });

  // Calculate the end date (Sunday)
  const endDate: Date = addDays(start, 6);

  // Check if the start and end dates are in the same month
  if (start.getMonth() === endDate.getMonth()) {
    // Same month: "4-8 February"
    return `${format(start, "d")}-${format(endDate, "d")} ${svenskMånad(
      start
    )}`;
  } else {
    // Different months: "27 Feb - 3 Mar"
    return `${format(start, "d")} ${svenskMånadKort(start)} - ${format(
      endDate,
      "d"
    )} ${svenskMånadKort(endDate)}`;
  }
};

export const UTC_DIFF_IN_HOURS = 1;

export const isValidDate = (value?: string | Date | null): boolean => {
  if (!value) return false;
  if (value instanceof Date) {
    return !isNaN(value.getTime());
  }

  if (typeof value === "string") {
    const date = new Date(value);
    return !isNaN(date.getTime());
  }

  return false;
};

export const dateToYYYYMMDD = (date?: Date | string | null) => {
  if (!date) return "";
  const adjustedDate = new Date(date);
  adjustedDate.setMinutes(
    adjustedDate.getMinutes() - adjustedDate.getTimezoneOffset()
  );
  return adjustedDate.toISOString().split("T")[0];
};
