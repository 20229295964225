import { Transition } from "components/animations/Transition";
import { FC, useEffect, useState } from "react";
import { config } from "react-spring";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { InspectionStatus } from "enums/InspectionStatus.enum";
import { FilePreview, InspectionPDFPreview } from "components/FilePreview";
import {
  FiveYearsInspection,
  InspectionFile,
} from "types/fiveYearsInspection/fiveYearsInspection.type";
import { FileUploadButton } from "components/buttons/FileUploadButton";
import PrimaryButton from "components/buttons/PrimaryButton";
import { isEmptyArray } from "helpers/array";
import Alert from "components/Alert";
import { classNames } from "helpers/classNames";
import userStore, { getUserRole } from "store/user.store";
import { Role, TransportDocumentStatus } from "types/enums";
import { SixMonthsInspection } from "types/sixMonthsInspection/sixMonthsInspection.type";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import Modal from "components/Modal";
import { DocumentationEmailForm } from "components/DocumentationEmailForm";
import { EmailStatus } from "types/email.type";
import { ErrorAlert } from "components/ErrorAlert";
import SecondaryButton from "components/buttons/SecondaryButton";
import { Checkbox } from "components/Checkbox";
import { Tomning } from "types/tomning.type";
import { isMobile } from "helpers/isMobile";
import instance from "api/axiosInstance";
import { TomningQueryKey } from "api/tomning.api";
import { Info } from "components/Info";
import { isEmail } from "helpers/string";
import { useCustomerDocumentationEmail } from "./useCustomerDocumentationEmail.logic";
import { Customer } from "types/customer.type";

type Props = {
  disableAnimation: boolean;
  hasSentEmail: boolean;
  inspectionStatus?: InspectionStatus;
  afterUploadedFile?: (url: string) => void; // Kör denna när man laddat upp en ny fil
  existingFiles?: InspectionFile[];
  isLoadingFiles: boolean;
  deleteFile: (id: number) => void;
  inspectionId?: number;
  fileName?: string;
  previewPDF: () => void;
  onFileAdd: (blob: Blob | null, filename: string) => Promise<void>;
  isUploadingImages: boolean;
  sendPDF: (includeTransportDocument: boolean) => void;
  isLoading: boolean;
  isSending: boolean;
  isLoadingPreview: boolean;
  inspection: FiveYearsInspection | SixMonthsInspection;
  tomning?: Tomning;
  documentationEmail?: string;
};

export const Attachments: FC<Props> = ({
  previewPDF,
  existingFiles = [],
  isLoadingFiles,
  deleteFile,
  hasSentEmail = false,
  inspectionStatus,
  fileName = "",
  onFileAdd,
  isUploadingImages = false,
  sendPDF,
  isLoading,
  isSending,
  isLoadingPreview,
  inspection,
  tomning,
  documentationEmail,
}) => {
  const userRole = userStore(getUserRole);
  const hasValidDocumentationEmail = isEmail(documentationEmail);

  const assignment = inspection.assignment;

  const { watch, control } = useForm<{
    includeTransportDocument: boolean;
  }>({
    defaultValues: {
      includeTransportDocument:
        !!tomning &&
        tomning.transportDocument.status === TransportDocumentStatus.Signed,
    },
  });

  const includeTransportDocument = watch("includeTransportDocument");

  const [enableInspectionPdfAnimation, setEnableInspectionPdfAnimation] =
    useState(false);

  useEffect(() => {
    setTimeout(() => setEnableInspectionPdfAnimation(true), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update documentationEmail

  const {
    isDocumentationModalOpen,
    setIsDocumentationModalOpen,
    closeModal,
    onSubmit,
    register,
    errors,
    isPatchingCustomer,
  } = useCustomerDocumentationEmail(
    (inspection.assignment.isProxyAssignment
      ? inspection.assignment.parentCustomer
      : inspection.separator.customer) as Customer,
    inspection.id
  );

  // Kolla helt enkelt om det senaste mailet är rött
  const showEmailError =
    !!inspection.emails &&
    inspection.emails.length > 0 &&
    inspection.emails[0].status === EmailStatus.FailedToSend;

  const queryClient = useQueryClient();

  const [
    isLoadingTransportDocumentPreview,
    setIsLoadingTransportDocumentPreview,
  ] = useState<boolean>(false);
  // Transport document
  const previewTransportDocument = () => {
    const url = `/transport-document/${tomning?.transportDocument.id}/preview-pdf`;
    setIsLoadingTransportDocumentPreview(true);
    if (isMobile()) {
      // Ladda ner pdfen om det är på mobil
      instance
        .get(url, {
          responseType: "blob",
        })
        .then((response) => {
          setIsLoadingTransportDocumentPreview(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.pdf");
          document.body.appendChild(link);
          link.click();
        });
    } else {
      // Annars öppna i ny flik
      instance
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          setIsLoadingTransportDocumentPreview(false);
          queryClient.invalidateQueries([TomningQueryKey.GetOne, tomning?.id]);
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
    }
  };

  return (
    <div
      className={classNames(
        "bg-white shadow-lg overflow-hidden sm:rounded-lg mt-7 lg:mt-0 lg:ml-7 mb-4 lg:mb-0",
        hasValidDocumentationEmail && "pb-6"
      )}
    >
      <div className="px-4 py-5 sm:px-6 flex pb-5 flex-col">
        <h3 className="text-lg font-medium leading-6 text-gray-900 py-1 mt-px flex items-center justify-between">
          Dokument
        </h3>
      </div>
      <div className="border-t border-gray-100 px-4 sm:px-6 py-6">
        {showEmailError && (
          <ErrorAlert text="Det senaste utskicket gick inte fram till kunden. Kontrollera att epostadressen stämmer." />
        )}

        <h4 className="text-md font-normal leading-6 text-gray-900 py-1 mb-2">
          Kontrollrapport
        </h4>
        <div className="space-y-6">
          {inspectionStatus === InspectionStatus.Klarmarkerad ? (
            <Transition
              settings={{
                from: {
                  transform: "scale(0.8)",
                  opacity: 0.2,
                },
                enter: {
                  transform: "scale(1)",
                  opacity: 1,
                },
                config: { ...config.wobbly, tension: 300 },
                immediate: !enableInspectionPdfAnimation,
              }}
              isVisible={true}
            >
              <InspectionPDFPreview
                onClick={previewPDF}
                inspectionName={fileName}
                isLoading={isLoadingPreview}
              />
            </Transition>
          ) : (
            <span className="text-gray-800">
              {`${
                inspectionStatus === InspectionStatus.RequirementsFulfilled
                  ? "Klarmarkera"
                  : "Slutför"
              } besiktningen för att generera kontrollrapporten`}
            </span>
          )}
        </div>
      </div>
      <div className="px-4 sm:px-6">
        <h4 className="text-md font-normal leading-6 text-gray-900 py-1 mb-2">
          Övriga bilagor
        </h4>
        <div className="space-y-6">
          {existingFiles.map(({ id, url }) => (
            <FilePreview key={id} url={url} onDelete={() => deleteFile(id)} />
          ))}
          <FileUploadButton
            onFileAdd={onFileAdd}
            isLoading={isUploadingImages || isLoadingFiles}
          />
        </div>
      </div>
      {assignment.isProxyAssignment && (
        <Info
          className="m-6 mb-0"
          text={`Dokumenten skickas till ${assignment.parentCustomer?.name} (uppdragsförmedlaren).`}
        />
      )}
      {/* Ta bort om det inte finns någon tömning på uppdraget */}
      {!!tomning && (
        <div className="px-4 sm:px-6 pt-6">
          <h4 className="text-md font-normal leading-6 text-gray-900 py-1 mb-2">
            Transportdokument
          </h4>
          <div className="space-y-6">
            {tomning.transportDocument.status ===
            TransportDocumentStatus.Signed ? (
              <>
                <InspectionPDFPreview
                  onClick={previewTransportDocument}
                  inspectionName={"transportdokument.pdf"}
                  isLoading={isLoadingTransportDocumentPreview}
                />
                <Checkbox
                  label="Bifoga transportdokument"
                  control={control}
                  name="includeTransportDocument"
                  disabled={
                    tomning.transportDocument.status !==
                    TransportDocumentStatus.Signed
                  }
                />
              </>
            ) : (
              <span className="text-gray-800 leading-3 ">
                Det finns en tömning kopplad till uppdraget. Slutför tömningen
                för att kunna bifoga transportdokumentet.
              </span>
            )}
          </div>
        </div>
      )}

      {!hasValidDocumentationEmail && (
        <div className="mt-6">
          <Alert
            text="Kunden saknar Epostadress för rapporter."
            buttonText="Lägg till"
            onButtonClick={() => setIsDocumentationModalOpen(true)}
          />
          {/* <div className="flex justify-center items-center">
            <button
              onClick={() => setIsDocumentationModalOpen(true)}
              className="text-sm text-cyan-700 font-semibold underline"
            >
              Lägg till
            </button>
          </div> */}
        </div>
      )}
      {inspectionStatus === InspectionStatus.Klarmarkerad &&
        hasValidDocumentationEmail &&
        userRole >= Role.Admin && (
          <div className="px-4 sm:px-6 pt-6 flex flex-col mt-4">
            <div className="mb-5">
              {hasSentEmail ? (
                <SecondaryButton
                  onClick={() => sendPDF(includeTransportDocument)}
                  isLoading={isLoading || isSending}
                  loadingText="Skickar..."
                  fullWidth
                >
                  <EnvelopeIcon className="h-5 w-5 mr-2" />
                  Skicka igen
                </SecondaryButton>
              ) : (
                <PrimaryButton
                  onClick={() => sendPDF(includeTransportDocument)}
                  isLoading={isLoading || isSending}
                  loadingText="Skickar..."
                  fullWidth
                >
                  <EnvelopeIcon className="h-5 w-5 mr-2" />
                  {getSendButtonText(existingFiles, includeTransportDocument)}
                </PrimaryButton>
              )}
            </div>
            <div className="flex justify-center items-center mb-1">
              <span className="text-sm text-gray-800 mr-1">Skickas till</span>
              <span className="text-sm text-gray-900 font-semibold">
                {documentationEmail}
              </span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-sm text-gray-800 mr-1">Fel adress?</span>
              <button
                onClick={() => setIsDocumentationModalOpen(true)}
                className="text-sm text-cyan-700 font-semibold underline"
              >
                Ändra Epostadress för rapporter
              </button>
            </div>
          </div>
        )}
      <Modal
        isOpen={isDocumentationModalOpen}
        onClose={closeModal}
        title="Ändra kund"
        actionText="Spara"
        actionHandler={onSubmit}
        isLoading={isPatchingCustomer}
        loadingText="Sparar..."
      >
        <DocumentationEmailForm
          register={register}
          errors={errors}
          customerName={
            assignment.isProxyAssignment
              ? assignment.parentCustomer?.name
              : inspection.separator.customer.name
          }
        />
      </Modal>
    </div>
  );
};

const getSendButtonText = (
  existingFiles: InspectionFile[],
  includeTransportDocument: boolean
) =>
  isEmptyArray(existingFiles) && !includeTransportDocument
    ? "Skicka kontrollrapport"
    : "Skicka kontrollrapport + bilagor";
