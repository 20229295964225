import { EyeSlashIcon } from "@heroicons/react/24/solid";
import TabContainer from "components/tab/TabContainer";
import { Table, TR, TH, TBody, TD, THead } from "components/table";
import { FC } from "react";
import { getAssignmentInspectionLinkForEmails } from "../assignment-history-page/AssignmentHistoryPage.helpers";
import { ActionMenu } from "./ActionMenu";
import { EmptyStatePop } from "components/EmptyStatePop";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { AssignmentTypeTag } from "components/AssignmentTypeTag";
import {
  Email,
  EmailStatus,
  getLinkToEmailAssignmentPage,
} from "types/email.type";
import { emailTypeToAssignmentType } from "enums/EmailType.enum";
import { EmailStatusTag } from "components/EmailStatusTag";
import { AssignmentType } from "enums/AssignmentType.enum";
import { EmailNotSentAssignment } from "./DashboardPage";

interface Props {
  assignments: EmailNotSentAssignment[];
  handleIgnore: (id: number, type: AssignmentType) => void;
  failedToSendEmails?: Email[];
  ignoreFailedEmail: (id: number) => void;
}
export const EmailNotSent: FC<Props> = ({
  assignments,
  handleIgnore,
  failedToSendEmails = [],
  ignoreFailedEmail,
}) => {
  const navigate = useNavigate();

  return (
    <TabContainer>
      {assignments.length === 0 && failedToSendEmails.length === 0 ? (
        <EmptyStatePop text="Alla rapporter skickade" />
      ) : (
        <>
          <Table>
            <THead className="bg-gray-50">
              <TR>
                <TH isFirstColumn>Kund/Mottagarstation</TH>
                <TH>Typ</TH>
                <TH>
                  <></>
                </TH>
                <TH>
                  <></>
                </TH>
              </TR>
            </THead>
            <TBody>
              {failedToSendEmails.map((email) => (
                <TR key={email.id}>
                  <TD isFirstColumn>
                    {email.customer?.name ?? email.wasteStation.name}
                  </TD>
                  <TD>
                    <AssignmentTypeTag
                      type={emailTypeToAssignmentType(email.type)}
                    />
                  </TD>
                  <TD>
                    <EmailStatusTag status={EmailStatus.FailedToSend} />
                  </TD>
                  <TD isLastColumn>
                    <ActionMenu
                      items={[
                        {
                          label: "Öppna",
                          onClick: () =>
                            navigate(getLinkToEmailAssignmentPage(email)),
                          icon: (
                            <DocumentMagnifyingGlassIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          ),
                        },
                        {
                          label: "Ta bort påminnelse",
                          onClick: () => ignoreFailedEmail(email.id),
                          icon: (
                            <EyeSlashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                          ),
                        },
                      ]}
                    />
                  </TD>
                </TR>
              ))}
              {assignments.map((item) => (
                <TR key={item.key}>
                  <TD isFirstColumn>{item.customerName}</TD>
                  <TD>
                    <div className="flex items-center space-x-2">
                      <AssignmentTypeTag type={item.type} />
                    </div>
                  </TD>
                  <TD>
                    <></>
                  </TD>
                  <TD isLastColumn>
                    <ActionMenu
                      items={[
                        {
                          label: "Öppna",
                          onClick: () =>
                            navigate(
                              getAssignmentInspectionLinkForEmails(item)
                            ),
                          icon: (
                            <DocumentMagnifyingGlassIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          ),
                        },
                        {
                          label: "Ta bort påminnelse",
                          onClick: () => handleIgnore(item.id, item.type),
                          icon: (
                            <EyeSlashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                          ),
                        },
                      ]}
                    />
                  </TD>
                </TR>
              ))}
            </TBody>
          </Table>
        </>
      )}
    </TabContainer>
  );
};
