import { ModalContentWrapper } from "components/ModalContentWrapper";
import { getWeek } from "date-fns";
import { AssignmentType } from "enums/AssignmentType.enum";
import { FC, useEffect, useState } from "react";
import {
  Assignment,
  hasFiveYearsInspection,
  hasSixMonthsInspection,
  hasTomning,
} from "types/assignment.type";
import swedish from "date-fns/locale/sv";
import { appRoutes } from "routes.type";
import { Link } from "components/Link";
import { getLinkToSixMonthsInspectionPage } from "../six-months-inspection-page/SixMonthsInspectionPage";
import { getLinkToFiveYearsInspectionPage } from "../five-years-inspection-page/FiveYearsInspectionPage";
import { formatDate, getDayNameFromWeekDay } from "helpers/date";
import { AssignmentStatusTag } from "components/AssignmentStatusTag";
import { getLinkToTomningPage } from "../tomning-page/TomningPage";
import {
  getIdentifierWithType,
  getSeparatorLabel,
} from "types/separator/separator.helpers";
import { AssignmentTypeTag } from "components/AssignmentTypeTag";
import { classNames } from "helpers/classNames";
import { Waste } from "types/waste.type";
import { useAssignment } from "api/assignment.api";
import Modal from "components/Modal";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
type Props = {
  assignmentId?: number | null;
  closeModal: () => void;
  isOpen: boolean;
};
export const AssignmentInfoModal: FC<Props> = ({
  assignmentId,
  closeModal,
  isOpen,
}) => {
  const { data: serverAssignment } = useAssignment(assignmentId);

  const [assignment, setAssignment] = useState<Assignment | null>(null);

  useEffect(() => {
    if (serverAssignment) {
      setAssignment(serverAssignment);
    }
  }, [serverAssignment]);

  return (
    <Modal
      isOpen={isOpen && !!assignment && assignment.id === assignmentId}
      onClose={closeModal}
      title="Detaljer"
      closeButtonText="Stäng"
      isProxyAssignment={assignment?.isProxyAssignment}
      isPriorityAssignment={assignment?.isPrioritized}
      isWide
    >
      {!!assignment && (
        <ModalContentWrapper>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-10 sm:grid-cols-2 p-4 sm:p-0 lg:grid-cols-3">
            <AssignmentInfoType assignment={assignment} />
            <AssignmentInfoOperatorInfo assignment={assignment} />
            {assignment.isProxyAssignment && (
              <>
                <AssignmentInfoParentCustomer assignment={assignment} />
                <AssignmentInfoChildCustomer assignment={assignment} />
              </>
            )}
            {!assignment.isProxyAssignment && (
              <AssignmentInfoCustomer assignment={assignment} />
            )}

            <AssignmentInfoSeparator assignment={assignment} />
            <AssignmentInfoSeparatorAddress assignment={assignment} />
            {!!assignment.separator.coordinates && (
              <SeparatorCoordinates assignment={assignment} />
            )}
            <AssignmentInfoStatus assignment={assignment} />
            {hasSixMonthsInspection(assignment) && (
              <SixMonthsInspectionInfo assignment={assignment} />
            )}
            {hasFiveYearsInspection(assignment) && (
              <FiveYearsInspectionInfo assignment={assignment} />
            )}
            {hasTomning(assignment) && (
              <AssignmentInfoTomningInfo assignment={assignment} />
            )}
            <AssignmentInfoScheduledDate assignment={assignment} />
            <AssignmentInfoReferenceNumber assignment={assignment} />
            <AssignmentInfoNotes assignment={assignment} />
          </dl>
        </ModalContentWrapper>
      )}
    </Modal>
  );
};

export const AssignmentInfoCustomer = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Kund</Label>
    <Value>
      <Link
        to={`${appRoutes.customers.fullPath}/${assignment.customer?.id}`}
        className="text-cyan-600 hover:text-cyan-700 font-medium"
      >
        {assignment.customer?.name}
      </Link>
    </Value>
  </Item>
);

export const AssignmentInfoParentCustomer = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Uppdragsförmedlare</Label>
    <Value>
      <Link
        to={`${appRoutes.customers.fullPath}/${assignment.parentCustomer?.id}`}
        className="text-cyan-600 hover:text-cyan-700 font-medium"
      >
        {assignment.parentCustomer?.name}
      </Link>
    </Value>
  </Item>
);

export const AssignmentInfoChildCustomer = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Underkund (verksamhetsutövare)</Label>
    <Value>
      <Link
        to={`${appRoutes.customers.fullPath}/${assignment.customer?.id}`}
        className="text-cyan-600 hover:text-cyan-700 font-medium"
      >
        {assignment.customer?.name}
      </Link>
    </Value>
  </Item>
);

export const AssignmentInfoSeparator = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Objekt</Label>
    <Value>
      <Link
        to={`${appRoutes.customers.fullPath}/${assignment.customer?.id}/${assignment?.separator?.id}`}
        className="text-cyan-600 hover:text-cyan-700 text-sm font-medium"
      >
        {getIdentifierWithType(assignment.separator)}
      </Link>
    </Value>
  </Item>
);

export const AssignmentInfoType = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Uppdragstyp</Label>
    <Value>
      <div className="flex items-center space-x-2">
        <AssignmentTypeTag type={assignment.type} />
        {!!assignment.includeTomning && (
          <AssignmentTypeTag type={AssignmentType.Tomning} />
        )}
      </div>
    </Value>
  </Item>
);

export const AssignmentInfoStatus = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Status</Label>
    <Value>
      <div className="flex">
        <AssignmentStatusTag status={assignment.assignmentStatus} />
      </div>
    </Value>
  </Item>
);

export const AssignmentInfoSeparatorAddress = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Objektets adress</Label>
    <Value>
      <div className="space-y-1">
        {!!assignment.separator?.address && (
          <p>{assignment.separator.address}</p>
        )}
        {!!assignment.separator?.postalCode && (
          <p>{`${assignment.separator.postalCode} ${assignment.separator.city}`}</p>
        )}
      </div>
    </Value>
  </Item>
);

export const AssignmentInfoScheduledDate = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Schemalagd</Label>
    <Value>
      {`Vecka ${getWeek(new Date(assignment.date ?? ""), {
        locale: swedish,
      })}`}
      {!!assignment.weekday && (
        <span>{` - ${getDayNameFromWeekDay(
          assignment.weekday
        ).toLowerCase()}`}</span>
      )}
    </Value>
  </Item>
);

const SixMonthsInspectionInfo = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <>
    <Item>
      <Label>Rapport</Label>
      <Value>
        <Link
          to={getLinkToSixMonthsInspectionPage(
            assignment.separator?.customerId ?? -1,
            assignment.separatorId,
            assignment.sixMonthsInspection?.id ?? -1
          )}
        >
          Öppna
        </Link>
      </Value>
    </Item>
    <Item>
      <Label>Kontrolldatum</Label>
      <Value>
        {formatDate(assignment.sixMonthsInspection?.inspectionDate)}
      </Value>
    </Item>
  </>
);

const FiveYearsInspectionInfo = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <>
    <Item>
      <Label>Rapport</Label>
      <Value>
        <Link
          to={getLinkToFiveYearsInspectionPage(
            assignment.separator?.customerId ?? -1,
            assignment.separatorId,
            assignment.fiveYearsInspection?.id ?? -1
          )}
        >
          Öppna
        </Link>
      </Value>
    </Item>
    <Item>
      <Label>Kontrolldatum</Label>
      <Value>
        {formatDate(assignment.fiveYearsInspection?.inspectionDate)}
      </Value>
    </Item>
  </>
);

export const AssignmentInfoTomningInfo = ({
  assignment,
  includeReportedHours = true,
}: {
  assignment: Assignment;
  includeReportedHours?: boolean;
}) => {
  const wasteList = assignment.tomning?.transportDocument.wasteList ?? [];
  return (
    <>
      {!!assignment.tomning && (
        <>
          <Item>
            <Label>Transportdokument</Label>
            <Value>
              <Link
                to={getLinkToTomningPage(
                  assignment.customerId,
                  assignment.separatorId,
                  assignment.tomning.id
                )}
              >
                Öppna
              </Link>
            </Value>
          </Item>
          <Item>
            <Label>Transportdatum</Label>
            <Value>
              {formatDate(assignment.tomning?.transportDocument.transportDate)}
            </Value>
          </Item>
        </>
      )}
      <Item>
        <Label>Mottagare</Label>
        <Value>{getWasteStationName(assignment)}</Value>
      </Item>
      <Item>
        <Label>{wasteList.length > 1 ? "Avfallskoder" : "Avfallskod"}</Label>
        <Value>
          <div className="space-y-2 flex flex-col">
            {wasteList.map((waste) => (
              <div key={waste.id} className="flex flex-col space-y-0.5">
                <p>{`${waste.wasteCode.codePretty}${getWeight(waste)}`}</p>
                {!!waste.comment && (
                  <p className="text-gray-600 flex items-center">
                    <PencilSquareIcon className="h-4 w-auto mr-1 text-gray-400" />
                    {waste.comment}
                  </p>
                )}
              </div>
            ))}
          </div>
        </Value>
      </Item>
      {includeReportedHours && (
        <AssignmentInfoReportedHours assignment={assignment} />
      )}
    </>
  );
};

const getWasteStationName = (assignment: Assignment) => {
  if (assignment.tomning?.transportDocument?.wasteStation) {
    return assignment.tomning.transportDocument.wasteStation.name;
  } else if (assignment.wasteStation) {
    return assignment.wasteStation.name;
  }
  return "";
};

const getWeight = (waste: Waste) => {
  if (waste.weightFinal) {
    return ` - ${waste.weightFinal} kg`;
  }
  if (waste.weightPreliminary) {
    return ` - ${waste.weightPreliminary} kg`;
  }
  return "";
};

export const AssignmentInfoNotes = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Intern kommentar</Label>
    <Value>{assignment.notes}</Value>
  </Item>
);

export const AssignmentInfoOperatorInfo = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Operatör</Label>
    <Value>{assignment.user.name}</Value>
  </Item>
);

export const AssignmentInfoReferenceNumber = ({
  assignment,
}: {
  assignment: Assignment;
}) => (
  <Item>
    <Label>Referensnummer</Label>
    <Value>{assignment.referenceNumber}</Value>
  </Item>
);

export const AssignmentInfoReportedHours = ({
  assignment,
  highlight,
}: {
  assignment: Assignment;
  highlight?: boolean;
}) => (
  <Item>
    <Label highlight={highlight}>Arbetad tid</Label>
    <Value highlight={highlight}>{`${assignment.reportedHours} timmar`}</Value>
  </Item>
);

const SeparatorCoordinates = ({ assignment }: { assignment: Assignment }) => (
  <Item>
    <Label>{getSeparatorLabel("coordinates")}</Label>
    <Value>
      {!!assignment.separator?.coordinates && (
        <a
          href={`http://www.google.com/maps/place/${assignment.separator.coordinates.replace(
            " ",
            ""
          )}`}
          target="_blank"
          rel="noreferrer"
          className="underline text-cyan-600"
        >
          Öppna Google Maps
        </a>
      )}
    </Value>
  </Item>
);

const Item: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="sm:col-span-1">{children}</div>
);

const Label: React.FC<{ children: React.ReactNode; highlight?: boolean }> = ({
  children,
  highlight = false,
}) => (
  <dt
    className={classNames(
      "text-gray-700 text-sm font-medium",
      highlight ? "" : ""
    )}
  >
    {children}
  </dt>
);

const Value: React.FC<{ children: React.ReactNode; highlight?: boolean }> = ({
  children,
  highlight = false,
}) => (
  <dd
    className={classNames(
      "mt-1 text-gray-900",
      highlight ? "text-lg font-semibold" : "text-sm font-normal"
    )}
  >
    {children}
  </dd>
);
