import React, { FC } from "react";
import { classNames } from "helpers/classNames";
import { twMerge } from "tailwind-merge";
interface Props {
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  isLoading?: boolean;
  loadingText?: string;
}

const PrimaryButton: FC<
  Props &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({
  children,
  size = "medium",
  fullWidth = false,
  isLoading = false,
  loadingText = "Sparar...",
  className,
  ...rest
}) => {
  const getClassForSize = (size: "small" | "medium" | "large") => {
    switch (size) {
      case "small":
        return classNames(
          "inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-semibold rounded shadow-sm text-white bg-cyan-700 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 disabled:bg-gray-400",
          fullWidth && "w-full"
        );
      case "large":
        return classNames(
          "inline-flex items-center px-4 py-2 border border-transparent text-sm font-semibold rounded-md shadow-sm text-white bg-cyan-700 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 disabled:bg-gray-400",
          fullWidth && "w-full"
        );
      case "medium":
      default:
        return classNames(
          "inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-semibold rounded-md shadow-sm text-white bg-cyan-700 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 disabled:bg-gray-400",
          fullWidth && "w-full"
        );
    }
  };
  return (
    <button
      type="button"
      className={twMerge(getClassForSize(size), className)}
      {...rest}
    >
      <div className="flex items-center mx-auto">
        {isLoading && (
          <svg
            className={classNames(
              size === "small" ? "w-3 h-3" : "w-5 h-5",
              "mr-3 -ml-1 text-white animate-spin"
            )}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {isLoading ? loadingText : <>{children}</>}
      </div>
    </button>
  );
};

export default PrimaryButton;
