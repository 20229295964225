import { FC } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ModalContentWrapper: FC<Props> = ({ children, className }) => (
  <div className={twMerge("mt-8 space-y-8", className)}>{children}</div>
);
