export enum WasteStationNotificationStatus {
  NotApplicable = "Not Applicable",
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  NewDateRequested = "NewDateRequested",
}
export type WasteStationNotification = {
  id: number;
  requestedDate: Date;
  requestedDateChange: Date;
  preliminaryWeights: {
    code: string;
    preliminaryWeightInKilo: string;
    description?: string;
  }[];
  status: WasteStationNotificationStatus;
  messageFromWasteStation: string;
  createdAt: Date;
};
