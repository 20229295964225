import { useMutation, useQuery } from "react-query";
import instance, { ApiUrl } from "./axiosInstance";
import { WasteStationNotification } from "types/wasteStationNotification.type";
import { Company } from "./company.api";
import { WasteStation } from "types/wasteStation.type";
import { Assignment } from "types/assignment.type";
import { AcceptOrDeclineFormData } from "pages/waste-station-notification/WasteStationNotificationPage";

export type PatchWasteStationNotificationDTO = {
  newDate: Date | string;
};

export enum WasteStationNotificationQueryKey {
  GetOneByUuid = "getWasteStationNotificationByUuid",
}

export type ExtendedWasteStationNotification = WasteStationNotification & {
  company: Company;
  wasteStation: WasteStation;
  assignment: Assignment;
};

export const useWasteStationNotificationByUuid = (uuid: string) =>
  useQuery(
    WasteStationNotificationQueryKey.GetOneByUuid,
    async (): Promise<ExtendedWasteStationNotification> =>
      (await instance.get(`${ApiUrl.WasteStationNotification}/byuuid/${uuid}`))
        .data
  );

export const useAcceptOrDeclineWasteStationNotification = (uuid: string) =>
  useMutation(
    async (data: AcceptOrDeclineFormData) =>
      await instance.post(
        `${ApiUrl.WasteStationNotification}/byuuid/${uuid}/accept-or-decline`,
        data
      )
  );

export const usePatchWasteStationNotification = (id: number) => {
  return useMutation(
    async (
      payload: PatchWasteStationNotificationDTO
    ): Promise<WasteStationNotification> =>
      (
        await instance.patch(
          `${ApiUrl.WasteStationNotification}/${id}`,
          payload
        )
      ).data
  );
};
