import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import TabContainer from "components/tab/TabContainer";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { Separator } from "types/separator/separator.type";
import EmptyState from "components/EmptyState";
import { Assignment, hasInspection } from "types/assignment.type";
import { getLinkToSixMonthsInspectionPage } from "../six-months-inspection-page/SixMonthsInspectionPage";
import { getLinkToFiveYearsInspectionPage } from "../five-years-inspection-page/FiveYearsInspectionPage";
import { AssignmentStatusTag } from "components/AssignmentStatusTag";
import { AssignmentInfoModal } from "../aktuella-uppdrag/AssignmentInfoModal";
import { getLinkToTomningPage } from "../tomning-page/TomningPage";
import { AssignmentTypeTag } from "components/AssignmentTypeTag";
import { AssignmentType } from "enums/AssignmentType.enum";
import { PriorityIcon } from "../aktuella-uppdrag/PriorityIcon";

interface Props {
  separator: Separator;
  openModal: () => void;
  disableButton: boolean;
  assignments?: Assignment[];
}
export const SeparatorAssignmentsTab: FC<Props> = ({
  separator,
  openModal,
  disableButton,
  assignments = [],
}) => {
  const navigate = useNavigate();

  const navigageToInspection = (assignment: Assignment) => {
    if (assignment.sixMonthsInspection) {
      navigate(
        getLinkToSixMonthsInspectionPage(
          separator.customerId,
          separator.id,
          assignment.sixMonthsInspection.id
        )
      );
    } else if (assignment.fiveYearsInspection) {
      navigate(
        getLinkToFiveYearsInspectionPage(
          separator.customerId,
          separator.id,
          assignment.fiveYearsInspection.id
        )
      );
    } else if (assignment.tomning) {
      navigate(
        getLinkToTomningPage(
          separator.customerId,
          separator.id,
          assignment.tomning.id
        )
      );
    } else {
      throw new Error("Invalid inspection type");
    }
  };

  /**
   * View assignment
   */
  // modal
  const [assignmentIdToView, setAssignmentIdToView] = useState<number | null>(
    null
  );

  if (assignments.length === 0) {
    return (
      <>
        <EmptyState
          header="Inga uppdrag"
          subHeader={``}
          onClick={disableButton ? undefined : openModal}
          buttonText="+ Nytt uppdrag"
        />
      </>
    );
  }

  return (
    <TabContainer>
      <div className="px-4 sm:px-0">
        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>Typ</TH>
              <TH>Datum</TH>
              <TH hideOnMobile>Operatör</TH>
              <TH hideOnTablet>Status</TH>
              <TH isLink>
                <></>
              </TH>
            </TR>
          </THead>
          <TBody>
            {assignments.map((assignment: Assignment) => (
              <TR key={assignment.id}>
                <TD isFirstColumn>
                  <div className="flex items-center space-x-2">
                    <AssignmentTypeTag type={assignment.type} />
                    {!!assignment.includeTomning && (
                      <AssignmentTypeTag type={AssignmentType.Tomning} />
                    )}
                  </div>
                </TD>
                <TD>
                  <div className="flex items-center">
                    {assignment.inspectionDate}
                    {assignment.isPrioritized && (
                      <PriorityIcon className="ml-2" />
                    )}
                  </div>
                </TD>
                <TD hideOnMobile>{assignment.user?.name}</TD>
                <TD hideOnTablet>
                  <AssignmentStatusTag status={assignment.assignmentStatus} />
                </TD>
                <TD isLink>
                  <button
                    className="text-cyan-600 hover:text-cyan-700 text-sm font-base"
                    onClick={() =>
                      hasInspection(assignment)
                        ? navigageToInspection(assignment)
                        : setAssignmentIdToView(assignment.id)
                    }
                  >
                    Öppna
                  </button>
                </TD>
              </TR>
            ))}
          </TBody>
        </Table>
      </div>

      <AssignmentInfoModal
        assignmentId={assignmentIdToView}
        closeModal={() => setAssignmentIdToView(null)}
        isOpen={!!assignmentIdToView}
      />
    </TabContainer>
  );
};
