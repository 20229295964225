import { Role } from "types/enums";
import {
  ClipboardDocumentListIcon,
  UserCircleIcon,
  UserGroupIcon,
  BellIcon,
  TruckIcon,
  CalendarDaysIcon,
  // CodeBracketIcon,
  ArchiveBoxArrowDownIcon,
  FaceSmileIcon,
  ChatBubbleBottomCenterTextIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import { Company } from "api/company.api";

export enum RouteUrl {
  Home = "/",
  Login = "/signin",
  Signup = "/signup",
  App = "/app",
  Customers = "kunder",
  Dashboard = "oversikt",
  Inspections = "rapporter",
  TransportDocuments = "transport-dokument",
  Organization = "organisation",
  Account = "konto",
  Schedule = "schema",
  WasteStations = "mottagsstation",
  UserSchedule = "userschema",
  FiveYearsInspection = "five-years-inspection",
  SixMonthsInspection = "six-months-inspection",
  Tomning = "tomning",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  MagicLinkLogin = "/magiclink",
  Changelog = "uppdateringar",
  OpenTransportDocuments = "transport-dokument",
  VehicleContentQr = "vehicle-content-qr",
  VehicleContent = "vehicle-content",
  Feedback = "feedback",
  Avfallsregistret = "avfallsregistret",
  RapporteraTransport = "rapportera-transport",
  RapporteraTransportPlanering = "rapportera-transportplanering",
  Companies = "foretag",
  WasteStationNotification = "avisering-om-avfall",
}

export enum RouteLabels {
  Dashboard = "Att göra",
  Customers = "Kunder",
  WasteStations = "Mottagarstationer",
  Inspections = "Uppdragshistorik",
  Organization = "Organisation",
  Account = "Konto",
  TransportDocuments = "Transportdokument",
  Schedule = "Aktuella uppdrag",
  UserSchedule = "Mina uppdrag",
  Changelog = "Uppdateringar",
  OpenTransportDocuments = "Last",
  Feedback = "Återkoppling",
  Avfallsregistret = "Avfallsregistret",
  RapporteraTransport = "Rapportera transport",
  RapporteraTransportPlanering = "Rapportera som ombud",
  Companies = "Företag - Admin",
}

export type AppRoutes = {
  companies: Route;
  dashboard: Route;
  customers: Route;
  inspections: Route;
  avfallsregistret: Route;
  wasteStations: Route;
  organization: Route;
  account: Route;
  schedule: Route;
  userSchedule: Route;
  // changelog: Route;
  transportDocuments: Route;
  openTransportDocuments: Route;
  feedback: Route;
};

export type Route = {
  path: string;
  fullPath: string;
  label: string;
  icon: (props: any) => any;
  minRole?: Role;
  exactRole?: Role;
  bottom?: boolean;
};

export const appRoutes: AppRoutes = {
  companies: {
    path: RouteUrl.Companies,
    fullPath: `${RouteUrl.App}/${RouteUrl.Companies}`,
    label: RouteLabels.Companies,
    icon: CurrencyDollarIcon,
    minRole: Role.SuperUser,
  },
  dashboard: {
    path: RouteUrl.Dashboard,
    fullPath: `${RouteUrl.App}/${RouteUrl.Dashboard}`,
    label: RouteLabels.Dashboard,
    icon: BellIcon,
    minRole: Role.Admin,
  },
  schedule: {
    path: RouteUrl.Schedule,
    fullPath: `${RouteUrl.App}/${RouteUrl.Schedule}`,
    label: RouteLabels.Schedule,
    icon: CalendarDaysIcon,
    minRole: Role.Admin,
  },
  userSchedule: {
    path: RouteUrl.UserSchedule,
    fullPath: `${RouteUrl.App}/${RouteUrl.UserSchedule}`,
    label: RouteLabels.UserSchedule,
    icon: CalendarDaysIcon,
    exactRole: Role.User,
  },
  openTransportDocuments: {
    path: RouteUrl.OpenTransportDocuments,
    fullPath: `${RouteUrl.App}/${RouteUrl.OpenTransportDocuments}`,
    label: RouteLabels.OpenTransportDocuments,
    icon: TruckIcon,
    minRole: Role.User,
  },
  customers: {
    path: RouteUrl.Customers,
    fullPath: `${RouteUrl.App}/${RouteUrl.Customers}`,
    label: RouteLabels.Customers,
    icon: FaceSmileIcon,
    minRole: Role.User,
  },
  inspections: {
    path: RouteUrl.Inspections,
    fullPath: `${RouteUrl.App}/${RouteUrl.Inspections}`,
    label: RouteLabels.Inspections,
    icon: ClipboardDocumentListIcon,
    minRole: Role.Admin,
  },
  transportDocuments: {
    path: RouteUrl.TransportDocuments,
    fullPath: `${RouteUrl.App}/${RouteUrl.TransportDocuments}`,
    label: RouteLabels.TransportDocuments,
    icon: TruckIcon,
    minRole: Role.User,
  },
  avfallsregistret: {
    path: RouteUrl.Avfallsregistret,
    fullPath: `${RouteUrl.App}/${RouteUrl.Avfallsregistret}`,
    label: RouteLabels.Avfallsregistret,
    icon: ArchiveBoxArrowDownIcon,
    minRole: Role.Admin,
  },
  wasteStations: {
    path: RouteUrl.WasteStations,
    fullPath: `${RouteUrl.App}/${RouteUrl.WasteStations}`,
    label: RouteLabels.WasteStations,
    icon: ArchiveBoxArrowDownIcon,
    minRole: Role.Admin,
  },
  organization: {
    path: RouteUrl.Organization,
    fullPath: `${RouteUrl.App}/${RouteUrl.Organization}`,
    label: RouteLabels.Organization,
    icon: UserGroupIcon,
    minRole: Role.Admin,
    bottom: false,
  },
  account: {
    path: RouteUrl.Account,
    fullPath: `${RouteUrl.App}/${RouteUrl.Account}`,
    label: RouteLabels.Account,
    icon: UserCircleIcon,
    minRole: Role.User,
  },
  // changelog: {
  //   path: RouteUrl.Changelog,
  //   fullPath: `${RouteUrl.App}/${RouteUrl.Changelog}`,
  //   label: RouteLabels.Changelog,
  //   icon: CodeBracketIcon,
  //   minRole: Role.Admin,
  //   bottom: true,
  // },
  feedback: {
    path: RouteUrl.Feedback,
    fullPath: `${RouteUrl.App}/${RouteUrl.Feedback}`,
    label: RouteLabels.Feedback,
    icon: ChatBubbleBottomCenterTextIcon,
    minRole: Role.User,
    bottom: true,
  },
};

export interface AppRoute {
  isCurrent: boolean;
  path: string;
  fullPath: string;
  label: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  minRole?: Role;
  exactRole?: Role;
  bottom?: boolean;
}
export const getAppRoutes = (
  role: Role,
  currentPath: string,
  company?: Company
): AppRoute[] => {
  return [
    appRoutes.companies,
    appRoutes.dashboard,
    appRoutes.schedule,
    appRoutes.userSchedule,
    // appRoutes.openTransportDocuments,
    appRoutes.inspections,
    // appRoutes.transportDocuments,
    appRoutes.customers,
    appRoutes.wasteStations,
    appRoutes.avfallsregistret,
    appRoutes.organization,
    appRoutes.account,
    // appRoutes.changelog,
    appRoutes.feedback,
  ]
    .filter(
      (
        { minRole, exactRole } // om exactRole finns måste den vara samma, annars kolla minRole
      ) =>
        !!exactRole ? exactRole === role : !minRole ? false : minRole <= role
    )
    .filter(
      ({ label }) =>
        label !== RouteLabels.Avfallsregistret || !!company?.isNvActive // Bara företag som har aktiverat avfallsregistret ska se det i listan
    )
    .map((route: Route) => ({
      ...route,
      isCurrent: currentPath
        .toLowerCase()
        .startsWith(`/app/${route.path.toLowerCase()}`),
    }));
};
