import { animated, config, useSpring } from "react-spring";
import { Transition } from "./Transition";

interface Props {
  children: React.ReactNode;
}

export const SnapUp: React.FC<Props> = ({ children }) => {
  const styles = useSpring({
    from: { opacity: 0, transform: "translate3d(0, 30px, 0px)" },
    to: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
    config: { ...config.stiff, mass: 0.8 },
  });
  return <animated.div style={styles}>{children}</animated.div>;
};

export const SnapRight: React.FC<Props> = ({ children }) => {
  const styles = {
    from: { opacity: 0, transform: "translate3d(-200px, 0px, 0px)" },
    enter: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
    config: { ...config.stiff, mass: 0.8 },
  };
  return <Transition settings={styles}>{children}</Transition>;
};

export const SmoothUp: React.FC<Props> = ({ children }) => {
  const styles = useSpring({
    from: { opacity: 0, transform: "translate3d(0, 30px, 0px)" },
    to: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
    config: {
      mass: 5,
      tension: 2000,
      friction: 100,
    },
  });
  return <animated.div style={styles}>{children}</animated.div>;
};

export const SmoothUpNew: React.FC<Props> = ({ children }) => {
  return (
    <Transition
      settings={{
        from: {
          opacity: 0,
          transform: "translate3d(0, 30px, 0px)",
        },
        enter: {
          opacity: 1,
          transform: "translate3d(0px, 0px, 0px)",
        },
        config: { ...config.gentle, friction: 22, clamp: true },
      }}
    >
      {children}
    </Transition>
  );
};

export const PageTransition: React.FC<Props> = ({ children }) => {
  return <>{children}</>;
};
