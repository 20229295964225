/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useDeleteSeparator,
  useGetSeparator,
  useSeparatorAssignments,
  useSetSeparatorFiles,
} from "api/separator.api";
import { PageLoader } from "components/PageLoader";
import menuStore from "store/menu.store";
import { InspectionType, Role } from "types/enums";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { GeneralTab } from "./SeparatorOverview";
import { SeparatorAssignmentsTab } from "./SeparatorAssignmentsTab";
import { capitalizeFirstLetter } from "helpers/string";
import { TechnicalInformation } from "./TechnicalInformation";
import Modal from "components/Modal";
import { useUpdateSeparatorLogic } from "hooks/useUpdateSeparatorLogic";
import SecondaryButton from "components/buttons/SecondaryButton";
import { useForm } from "react-hook-form";
import PrimaryButton from "components/buttons/PrimaryButton";
import { getSeparatorTypeLabel } from "types/separator/separator.helpers";
import DeleteSeparatorModal from "components/DeleteSeparatorModal";
import userStore, {
  getUserId,
  getUserRole,
  useIsAdmin,
} from "store/user.store";
import { SeparatorMetadataForm } from "./SeparatorMetadataForm";
import { EditSeparatorTechnicalInformation } from "./EditSeparatorTechnicalInformation";
import { appRoutes } from "routes.type";
import { NewAssignmentForOperator } from "./NewAssignment";
import { useCreateAssignmentWithInspection } from "api/assignment.api";
import { AssignmentDTO, getEmptyAssignmentDTO } from "types/assignment.type";
import { dateToYearMonthDay } from "helpers/date";
import { getLinkToFiveYearsInspectionPage } from "../five-years-inspection-page/FiveYearsInspectionPage";
import { getLinkToSixMonthsInspectionPage } from "../six-months-inspection-page/SixMonthsInspectionPage";
import { isEmptyArray } from "helpers/array";
import { AdminTab } from "./notiser/AdminTab";
import { NotiserTab } from "./notiser/NotiserTab";
import { getLinkToTomningPage } from "../tomning-page/TomningPage";
import { isAvskiljare } from "types/separator/separator.type";
import { useAddAssignmentLogic } from "../aktuella-uppdrag/AddAssignmentModal/useAddAssignmentLogic";
import { AddAssignmentModal } from "../aktuella-uppdrag/AddAssignmentModal/AddAssignmentModal";

enum Tab {
  Egenskaper = "allmänt",
  Kontroller = "uppdrag",
  Detaljer = "detaljer",
  Admin = "admin",
  Notiser = "notiser",
}

export default function SeparatorPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAdmin = useIsAdmin();
  // tabs
  const [searchParams, setSearchParams] = useSearchParams();

  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });
  const currentTab = searchParams.get("tab") || Tab.Egenskaper;
  const params = useParams();
  const separatorId: string = params?.separatorId || "-1";
  const {
    data: separator,
    isLoading,
    isError,
  } = useGetSeparator(Number(separatorId));
  const { setCurrentCustomerName, setSeparator } = menuStore();

  const userRole = userStore(getUserRole);
  const userId = userStore(getUserId);

  // För att sätta rätt grejer i menyn
  useEffect(() => {
    if (separator) {
      setCurrentCustomerName(separator.customer?.name || "");
      setSeparator(
        `${`${getSeparatorTypeLabel(separator)}${
          !separator.identifier ? "" : ` - ${separator.identifier}`
        }`}${separator.isDeleted ? " - BORTTAGEN" : ""}`
      );
    }
  }, [separator, setCurrentCustomerName, setSeparator]);

  // Update separator
  const {
    isModalOpen,
    formErrors,
    isUpdating,
    onModalClose,
    openModal,
    onSeparatorSubmit,
    register,
    formData,
  } = useUpdateSeparatorLogic(separator);

  // Update separator metadata
  const {
    isModalOpen: isMetadataModalOpen,
    isUpdating: isUpdatingMetadata,
    onModalClose: onMetadataModalClose,
    onSeparatorSubmit: onSeparatorMetadataSubmit,
    register: registerMetadata,
    openModal: openMetadataModal,
    formErrors: editMetadataErrors,
    setSeparatorValue: setSeparatorMetadataValue,
    formData: watchSeparatorMetadata,
  } = useUpdateSeparatorLogic(separator);

  // Delete separator

  const [isDeleteSeparatorModalOpen, setIsDeleteSeparatorModalOpen] =
    useState<boolean>(false);

  const {
    mutate: deleteSeparator,
    isLoading: isDeletingSeparator,
    isSuccess: isDeleteSeparatorSuccess,
  } = useDeleteSeparator(Number(separatorId));

  const handleDeleteSeparator = () => deleteSeparator(); // för att slippa skriva komplexa props

  useEffect(() => {
    if (isDeleteSeparatorSuccess) {
      // Routa till kund/avskiljarelista
      navigate(
        `${appRoutes.customers.fullPath}/${
          pathname.split(`${appRoutes.customers.fullPath}/`)[1].split("/")[0]
        }?tab=objekt`
      );
    }
  }, [isDeleteSeparatorSuccess, navigate, pathname]);

  /**
   * Add assignment
   */
  const [isNewAssignmentModalOpen, setIsNewAssignmentModalOpen] =
    useState(false);

  const {
    register: registerAssignment,
    reset: resetAssignmentForm,
    watch: assignmentFormData,
    formState: { errors: assignmentFormErrors },
  } = useForm<AssignmentDTO>({
    defaultValues: {
      ...getEmptyAssignmentDTO(),
      customerId: separator?.customerId,
      separatorId: separator?.id,
    },
  });

  const onAddAssignmentModalClose = useCallback(() => {
    setIsNewAssignmentModalOpen(false);
    setTimeout(() => resetAssignmentForm(), 300);
  }, [setIsNewAssignmentModalOpen, resetAssignmentForm]);

  useEffect(() => {
    if (separator) {
      resetAssignmentForm({
        ...getEmptyAssignmentDTO(),
        separatorId: separator.id,
        customerId: separator.customerId,
      });
    }
  }, [separator, resetAssignmentForm]);

  // Starta uppdrag direkt, dvs skapa assignment ink inspection och routa

  const {
    mutate: createAssignmentWithInspection,
    isLoading: isCreatingAssignmentWithInspection,
    data: createdAssignment,
  } = useCreateAssignmentWithInspection();

  const handleCreateAssignmentWithInspection = () => {
    const type = +assignmentFormData("type");
    if (!separator) {
      throw Error("Missing separator");
    }
    createAssignmentWithInspection({
      type: +type,
      separatorId: separator.id,
      userId,
      customerId: separator.customerId,
      date: dateToYearMonthDay(new Date()),
      referenceNumber: "",
      isProxyAssignment: false,
      isPrioritized: false,
    });
  };

  // Route to inspection after create
  useEffect(() => {
    if (createdAssignment) {
      const fiveY = createdAssignment.fiveYearsInspection;
      const sixM = createdAssignment.sixMonthsInspection;
      const tomning = createdAssignment.tomning;
      const customer = createdAssignment.customer;
      if (fiveY) {
        navigate(
          getLinkToFiveYearsInspectionPage(customer.id, separatorId, fiveY.id)
        );
      } else if (sixM) {
        navigate(
          getLinkToSixMonthsInspectionPage(customer.id, separatorId, sixM.id)
        );
      } else if (tomning) {
        navigate(getLinkToTomningPage(customer.id, separatorId, tomning.id));
      }
    }
  }, [createdAssignment, navigate, separatorId]);

  /**
   * Filer
   */

  const [tempDeletedFiles, setTempDeletedFiles] = useState<number[]>([]);

  const { mutate: setFiles, isLoading: isSettingFiles } =
    useSetSeparatorFiles();

  const onFileAdd = (url: string) => {
    if (separator) {
      setFiles({
        separatorId: separator.id,
        files: [...separator.files, { id: undefined, name: url, url }],
      });
    }
  };

  const onFileDelete = (id: number) => {
    setTempDeletedFiles((prev) => [...prev, id]);
    if (separator) {
      setFiles({
        separatorId: separator.id,
        files: (separator.files || []).filter((file) => file.id !== id),
      });
    }
  };

  const copyAddressFromCustomer = () => {
    if (!separator?.customer) {
      throw Error("missing customer");
    }

    setSeparatorMetadataValue("address", separator.customer.address);
    setSeparatorMetadataValue("postalCode", separator.customer.postalCode);
    setSeparatorMetadataValue("city", separator.customer.city);
    setSeparatorMetadataValue("kommunKod", separator.customer.kommunKod);
  };

  const selectedSeparatorType = watchSeparatorMetadata("type");

  /**
   * Assignments
   */
  const { data: assignments } = useSeparatorAssignments(separator?.id ?? -1, {
    enabled: (separator?.id ?? -1) > 0,
  });

  // Admin new assignment

  const logic = useAddAssignmentLogic(separator);

  if (isLoading) {
    return <PageLoader />;
  }
  if (isError || !separator) {
    return <div>Error</div>;
  }

  return (
    <>
      <TabPageWrapper
        tabs={[
          {
            label: capitalizeFirstLetter(Tab.Egenskaper),
            labelName: "Översikt",
            content: (
              <GeneralTab
                separator={{
                  ...separator,
                  files: separator.files?.filter(
                    (file) => !tempDeletedFiles.includes(file.id)
                  ),
                }}
                afterUploadedFile={onFileAdd}
                deleteFile={onFileDelete}
                isLoadingFiles={isSettingFiles}
                openAdminTab={() => setTab(Tab.Notiser)}
              />
            ),
            isSelected: currentTab === Tab.Egenskaper,
            button: separator.isDeleted ? null : (
              <SecondaryButton size="small" onClick={openMetadataModal}>
                Ändra
              </SecondaryButton>
            ),
            showIfDeleted: true,
            minimumRole: Role.User,
          },
          {
            label: capitalizeFirstLetter(Tab.Detaljer),
            labelName: "Teknisk information",
            content: <TechnicalInformation separator={separator} />,
            isSelected: currentTab === Tab.Detaljer,
            button: separator.isDeleted ? null : (
              <SecondaryButton size="small" onClick={openModal}>
                Ändra teknisk information
              </SecondaryButton>
            ),
            showIfDeleted: true,
            minimumRole: Role.User,
            isHidden: !isAvskiljare(separator),
          },
          {
            label: capitalizeFirstLetter(Tab.Kontroller),
            content: (
              <SeparatorAssignmentsTab
                separator={separator}
                openModal={() => setIsNewAssignmentModalOpen(true)}
                disableButton={separator.isDeleted}
                assignments={assignments}
              />
            ),
            isSelected: currentTab === Tab.Kontroller,
            button:
              separator.isDeleted || isEmptyArray(assignments) ? null : (
                <PrimaryButton
                  onClick={
                    isAdmin
                      ? () => logic.setIsModalOpen(true)
                      : () => setIsNewAssignmentModalOpen(true)
                  }
                  size="small"
                >
                  + Nytt uppdrag
                </PrimaryButton>
              ),
            showIfDeleted: true,
            minimumRole: Role.User,
          },
          {
            label: capitalizeFirstLetter(Tab.Notiser),
            labelName: "Påminnelser",
            content: (
              <NotiserTab separator={separator} assignments={assignments} />
            ),
            isSelected: currentTab === Tab.Notiser,
            showIfDeleted: false,
            minimumRole: Role.Admin,
          },
          {
            label: capitalizeFirstLetter(Tab.Admin),
            labelName: "Administration",
            content: (
              <AdminTab
                isDeletingSeparator={isDeletingSeparator}
                openDeleteModal={() => setIsDeleteSeparatorModalOpen(true)}
              />
            ),
            isSelected: currentTab === Tab.Admin,
            showIfDeleted: false,
            minimumRole: Role.Admin,
          },
        ]
          .filter((item) => !separator.isDeleted || item.showIfDeleted)
          .filter((item) => item.minimumRole <= (userRole ?? Role.User))}
        setTab={setTab}
      />
      <Modal
        isOpen={isModalOpen}
        title={"Ändra objekt - " + separator?.customer?.name}
        onClose={onModalClose}
        actionHandler={onSeparatorSubmit}
        actionText="Spara"
        isLoading={isUpdating}
        loadingText="Sparar..."
      >
        <EditSeparatorTechnicalInformation
          register={register}
          formErrors={formErrors}
          formData={formData}
        />
      </Modal>

      <Modal
        isOpen={isMetadataModalOpen}
        title={"Ändra objekt - " + separator?.customer?.name}
        onClose={onMetadataModalClose}
        actionHandler={onSeparatorMetadataSubmit}
        actionText="Spara"
        isLoading={isUpdatingMetadata}
        loadingText="Sparar..."
      >
        <SeparatorMetadataForm
          register={registerMetadata}
          errors={editMetadataErrors}
          copyAddressFromCustomer={copyAddressFromCustomer}
          selectedSeparatorType={selectedSeparatorType}
          setValue={setSeparatorMetadataValue}
          postnummer={formData("postalCode") ?? ""}
        />
      </Modal>

      <Modal
        isOpen={isNewAssignmentModalOpen}
        title={"Nytt uppdrag"}
        onClose={onAddAssignmentModalClose}
        actionHandler={handleCreateAssignmentWithInspection}
        actionText="Påbörja"
        isLoading={isCreatingAssignmentWithInspection}
      >
        <NewAssignmentForOperator
          register={registerAssignment}
          formErrors={assignmentFormErrors}
          customerName={separator?.customer?.name ?? ""}
          separator={separator}
        />
      </Modal>

      <AddAssignmentModal logic={logic} />

      <DeleteSeparatorModal
        isOpen={isDeleteSeparatorModalOpen}
        onClose={() => setIsDeleteSeparatorModalOpen(false)}
        actionText="Ta bort objektet"
        actionHandler={handleDeleteSeparator}
        title="Ta bort objektet"
        separatorIdentification={separator?.identifier}
        customerName={separator?.customer?.name ?? ""}
      />
    </>
  );
}

export type CreateInspection = {
  type?: InspectionType;
  inspectionDate?: Date;
  referenceNumber?: string;
};
