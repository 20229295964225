import { useUpdateWasteStation, useWasteStation } from "api/wasteStation.api";
import Modal from "components/Modal";
import { PageLoader } from "components/PageLoader";
import SecondaryButton from "components/buttons/SecondaryButton";
import { DescriptionList } from "components/desriptionList/DescriptionList";
import { Item } from "components/desriptionList/Item";
import { Label } from "components/desriptionList/Label";
import { Value } from "components/desriptionList/Value";
import TabContainer from "components/tab/TabContainer";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { WasteStation, WasteStationDTO } from "types/wasteStation.type";
import { WasteStationForm } from "../waste-station-list/WasteStationForm";
import menuStore from "store/menu.store";
import { GetKommunLabel } from "types/Kommun.type";

export const WasteStationPage = () => {
  const params = useParams();
  const id: number = +(params?.id || "-1");

  enum Tabs {
    General = "oversikt",
  }

  // GET Wastestation
  const { data: wasteStation, isLoading } = useWasteStation(id);

  // BREADCRUMBS
  const { setCurrentWasteStationName } = menuStore();
  useEffect(() => {
    if (wasteStation && !isLoading) {
      setCurrentWasteStationName(wasteStation.name);
    }
  }, [wasteStation, setCurrentWasteStationName, isLoading]);

  // UPDATE Wastestation
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    mutate,
    isSuccess,
    isLoading: isUpdatingWasteStation,
  } = useUpdateWasteStation(+id);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setError,
    control,
  } = useForm<WasteStationDTO>();

  const onSubmit = handleSubmit((data: WasteStationDTO) => {
    if (data.wantsToBeNotified && !data.notificationEmail) {
      setError("notificationEmail", {
        message: "Krävs för avisering om avfall",
      });
      return;
    }
    mutate(data);
  });

  const resetForm = useCallback(
    (wasteStation: WasteStation) => {
      if (wasteStation) {
        reset(wasteStation);
      } else {
        reset(); // Tror aldrig man kommer hit men men
      }
    },
    [reset]
  );

  useEffect(() => {
    if (wasteStation) {
      resetForm(wasteStation);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasteStation]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setTimeout(() => {
      if (wasteStation) {
        resetForm(wasteStation);
      }
    }, 300); // Wait for modal to close
  }, [resetForm, wasteStation]);

  useEffect(() => {
    if (isSuccess) {
      // setIsModalOpen(false);
      closeModal();
    }
  }, [closeModal, isSuccess]);

  if (isLoading) {
    return <PageLoader />;
  }
  if (!wasteStation) {
    return <div>Error</div>;
  }
  return (
    <>
      <TabPageWrapper
        tabs={[
          {
            label: Tabs.General,
            labelName: "Översikt",
            content: (
              <TabContainer>
                <DescriptionList>
                  <Item>
                    <Label>Företagets namn</Label>
                    <Value>{wasteStation.name}</Value>
                  </Item>
                  <Item>
                    <Label>Organisationsnummer</Label>
                    <Value>{wasteStation.organizationNumber}</Value>
                  </Item>
                  <Item>
                    <Label>Epostadress för rapporter</Label>
                    <Value>{wasteStation.documentationEmail}</Value>
                  </Item>

                  <Item>
                    <Label>Adress</Label>
                    <Value>
                      <div className="space-y-1">
                        {!!wasteStation.address && (
                          <p>{wasteStation.address}</p>
                        )}
                        {!!wasteStation.postalCode && (
                          <p>{wasteStation.postalCode}</p>
                        )}
                        {!!wasteStation.city && <p>{wasteStation.city}</p>}
                      </div>
                    </Value>
                  </Item>
                  <Item>
                    <Label>Kommun</Label>
                    <Value>{GetKommunLabel(wasteStation.knCode)}</Value>
                  </Item>
                  <Item>
                    <Label>Telefon</Label>
                    <Value>{wasteStation.phone}</Value>
                  </Item>
                  <Item>
                    <Label>
                      <div className="w-[200px]">Vill aviseras om avfall</div>
                    </Label>
                    <Value>
                      {wasteStation.wantsToBeNotified ? "Ja" : "Nej"}
                    </Value>
                  </Item>
                  <Item>
                    <Label>Koordinater</Label>
                    <Value>
                      {!!wasteStation.coordinates && (
                        <a
                          href={`http://www.google.com/maps/place/${wasteStation.coordinates.replace(
                            " ",
                            ""
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                          className="underline text-cyan-600"
                        >
                          Öppna Google Maps
                        </a>
                      )}
                    </Value>
                  </Item>
                </DescriptionList>
              </TabContainer>
            ),
            isSelected: true,
            button: (
              <SecondaryButton
                size="small"
                onClick={() => setIsModalOpen(true)}
              >
                Ändra
              </SecondaryButton>
            ),
          },
        ]}
        setTab={() => null}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Ändra mottagarstation"
        actionText="Spara"
        actionHandler={onSubmit}
        isLoading={isUpdatingWasteStation}
        loadingText="Sparar..."
      >
        <WasteStationForm
          control={control}
          register={register}
          errors={errors}
          watch={watch}
        />
      </Modal>
    </>
  );
};
