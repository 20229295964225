import { AssignmentStatus } from "enums/AssignmentStatus.enum";
import { AssignmentType } from "enums/AssignmentType.enum";
import { Customer } from "./customer.type";
import { FiveYearsInspection } from "./fiveYearsInspection/fiveYearsInspection.type";
import { Separator } from "./separator/separator.type";
import { SixMonthsInspection } from "./sixMonthsInspection/sixMonthsInspection.type";
import { User } from "./user.type";
import { Tomning } from "./tomning.type";
import { WasteCode } from "./wasteCode.type";
import { WasteStation } from "./wasteStation.type";
import { InspectionStatus } from "enums/InspectionStatus.enum";
import { WasteStationNotification } from "./wasteStationNotification.type";
import { Company } from "api/company.api";

export type Assignment = {
  id: number;
  type: AssignmentType;
  user: User;
  userId: number;
  date: string; // yyyy-MM-dd
  separator: Separator;
  separatorId: number;
  notes?: string;
  customer: Customer;
  customerId: number;
  fiveYearsInspectionId?: number;
  fiveYearsInspection?: FiveYearsInspection;
  sixMonthsInspectionId?: number;
  sixMonthsInspection?: SixMonthsInspection;
  assignmentStatus: AssignmentStatus;
  hasSentEmail: boolean;
  tomningId?: number;
  tomning?: Tomning;
  referenceNumber: string;
  company?: Company;
  wasteCodes?: WasteCode[];
  wasteStation?: WasteStation;
  inspectionDate: string;
  reportedHours: string;
  reportedKilometers: string;
  includeTomning: boolean;
  isProxyAssignment: boolean;
  parentCustomer?: Customer;
  isPrioritized: boolean;
  weekday?: number;
  wasteStationNotification?: WasteStationNotification;
};

export type AssignmentDTO = {
  type: AssignmentType;
  userId?: number | null;
  date?: string | null; // yyyy-MM-dd
  weekday?: number | null;
  taskId?: number | null;
  separatorId?: number | null;
  customerId?: number | null;
  notes?: string | null;
  fiveYearsInspectionId?: number | null;
  sixMonthsInspectionId?: number | null;
  wasteStationId?: number | null;
  wasteCodeList?: { id: number; preliminaryWeight?: string | number }[];
  referenceNumber: string;
  includeTomning?: boolean;
  isProxyAssignment?: boolean;
  proxyCustomerId?: number | null;
  isPrioritized?: boolean;
  wasteStationNotificationDate?: Date | string | null;
};

export type TimeReportDTO = {
  assignmentId: number;
  reportedHours: string;
  reportedKilometers?: string;
};

export type ConfirmTimeReportDTO = {
  assignmentId: number;
};

export const getEmptyAssignmentDTO = (
  separator?: Separator,
  customer?: Customer
): AssignmentDTO => ({
  type: AssignmentType.Undefined,
  userId: 0,
  date: null,
  weekday: null,
  taskId: null,
  separatorId: separator ? separator.id : 0,
  customerId: separator ? separator.customerId : customer ? customer.id : 0,
  wasteStationId: 0,
  notes: null,
  fiveYearsInspectionId: null,
  sixMonthsInspectionId: null,
  referenceNumber: "",
  includeTomning: false,
  isProxyAssignment: false,
  isPrioritized: false,
  proxyCustomerId: 0,
});

// returnerar true om assignmentents avskiljare har påminnelser aktiverade för assignmentsens typ
export const isTasksEnabledForAssignmentSeparator = (
  assignment: Assignment
) => {
  switch (assignment.type) {
    case AssignmentType.FiveYearInspection:
      return !!assignment.separator?.avtalFemarskontroll;
    case AssignmentType.SixMonthsInspection:
      return !!assignment.separator?.avtalHalvarsKontroll;
    case AssignmentType.Tomning:
      return !!assignment.separator?.avtalSlamtomning;
    default:
      return false;
  }
};

export const hasInspection = (assignment?: Assignment): boolean =>
  !assignment
    ? false
    : hasFiveYearsInspection(assignment) ||
      hasSixMonthsInspection(assignment) ||
      hasTomning(assignment);

export const hasFiveYearsInspection = (assignment: Assignment): boolean =>
  !!assignment.fiveYearsInspection;

export const hasSixMonthsInspection = (assignment: Assignment): boolean =>
  !!assignment.sixMonthsInspection;

export const hasTomning = (assignment: Assignment): boolean =>
  !!assignment.tomning;

export const getReferenceNumber = (assignment: Assignment): string => {
  if (hasFiveYearsInspection(assignment)) {
    return assignment.fiveYearsInspection?.referenceNumber ?? "";
  } else if (hasSixMonthsInspection(assignment)) {
    return assignment.sixMonthsInspection?.referenceNumber ?? "";
  } else if (hasTomning(assignment) || !!assignment.referenceNumber) {
    return assignment.referenceNumber ?? "";
  }
  return "";
};

export const getRapportNummer = (
  assignment: Assignment
): number | undefined => {
  if (hasFiveYearsInspection(assignment)) {
    return assignment.fiveYearsInspection?.rapportNummer ?? undefined;
  } else if (hasSixMonthsInspection(assignment)) {
    return assignment.sixMonthsInspection?.rapportNummer ?? undefined;
  } else if (hasTomning(assignment)) {
    return assignment.tomning?.rapportNummer ?? undefined;
  }
  return undefined;
};

export const inspectionStatusToAssignmentStatus = (
  inspectionStatus: InspectionStatus
): AssignmentStatus => {
  switch (inspectionStatus) {
    case InspectionStatus.Created:
      return AssignmentStatus.Created;
    case InspectionStatus.Loaded:
      return AssignmentStatus.Loaded;
    case InspectionStatus.Weighed:
      return AssignmentStatus.Weighed;
    case InspectionStatus.Signed:
      return AssignmentStatus.Signed;
    case InspectionStatus.MissingSeparatorData:
      return AssignmentStatus.MissingSeparatorData;
    case InspectionStatus.RequirementsFulfilled:
      return AssignmentStatus.RequirementsFulfilled;
    case InspectionStatus.Klarmarkerad:
      return AssignmentStatus.Klarmarkerad;
    default:
      throw Error(
        "inspectionStatusToAssignmentStatus missing implementation for " +
          inspectionStatus
      );
  }
};
