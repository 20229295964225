import Modal from "components/Modal";
import TabContainer from "components/tab/TabContainer";
import { AssignmentStatus } from "enums/AssignmentStatus.enum";
import { AssignmentType } from "enums/AssignmentType.enum";
import { TaskType } from "enums/TaskType.enum";
import { getLinkToFiveYearsInspectionPage } from "pages/app/five-years-inspection-page/FiveYearsInspectionPage";
import { getLinkToSixMonthsInspectionPage } from "pages/app/six-months-inspection-page/SixMonthsInspectionPage";
import { FC, useEffect, useState } from "react";
import { Assignment } from "types/assignment.type";
import { FiveYearsInspection } from "types/fiveYearsInspection/fiveYearsInspection.type";
import {
  getNextSeparatorTaskDate,
  isAvskiljare,
  Separator,
} from "types/separator/separator.type";
import { SixMonthsInspection } from "types/sixMonthsInspection/sixMonthsInspection.type";
import { AssignmentInfoModal } from "../../aktuella-uppdrag/AssignmentInfoModal";
import { EditFiveYearsTask } from "../EditFiveYearsTask";
import { EditSixMonthsTask } from "../EditSixMonthsTask";
import { useCreateTaskLogic } from "../useCreateTaskLogic";
import { useToggleAvtal } from "../useToggleAvtal";
import { AvtalFemårs } from "./AvtalFemårs";
import { AvtalSexmånaders } from "./AvtalSexmånaders";
import { AvtalSlamtömning } from "./AvtalSlamtömning";
import { Tomning } from "types/tomning.type";
import { getLinkToTomningPage } from "pages/app/tomning-page/TomningPage";
import { TransportDocumentStatus } from "types/enums";

type Props = {
  separator: Separator;
  assignments?: Assignment[];
};

export enum AvtalModalType {
  None,
  EditSlamtomningPerAr,
  SlamtomningNastaPaminnelse,
}
export const NotiserTab: FC<Props> = ({ separator, assignments = [] }) => {
  const [disableAnimation, setDisableAnimation] = useState(true);

  useEffect(() => {
    setTimeout(() => setDisableAnimation(false), 300);
  }, []);

  const {
    setAvtalFemarskontroll,
    setAvtalHalvarsKontroll,
    setAvtalSlamtomning,
  } = useToggleAvtal(separator);

  ///
  ///   Nästa tillfälle senast
  ///

  // Sexmånaders
  const {
    isModalOpen: isSixMonthsTaskModalOpen,
    isCreatingTask: isCreatingSixMonthsTask,
    onModalClose: onSixMonthsTaskModalClose,
    openModal: openSixMonthsModal,
    onTaskSubmit: onSixMonthsTaskSubmit,
    register: registerSixMonthsTask,
  } = useCreateTaskLogic(separator, TaskType.SixMonthsInspection);

  // femårs
  const {
    isModalOpen: isFiveYearsTaskModalOpen,
    isCreatingTask: isCreatingFiveYearsTask,
    onModalClose: onFiveYearsTaskModalClose,
    openModal: openFiveYearsModal,
    onTaskSubmit: onFiveYearsTaskSubmit,
    register: registerFiveYearsTask,
  } = useCreateTaskLogic(separator, TaskType.FiveYearInspection);

  // Navigera till kontroller

  const latestTomning = getLatestTomning(assignments);
  const latestFiveYearsInspection = getLatestFiveYearsInspection(assignments);
  const latestSixMonthsInspection = getLatestSixMonthsInspection(assignments); // fixa här
  // Senaste kontroll är det senaste assignment som har en påbörjad inspection
  // Nästa tillfälle ska ha bibehållen logik.

  /**
   * View assignment
   */
  // modal
  const [assignmentIdToView, setAssignmentIdToView] = useState<number | null>(
    null
  );

  const _setAssignmentIdToView = (id: number | null) =>
    setAssignmentIdToView(id);

  // Om det finns någon assignment som är schemalagd men ej klarmarkerad så ska vi visa den här
  const scheduledFiveYearAssignment = assignments.find(
    (ass) =>
      ass.type === AssignmentType.FiveYearInspection &&
      ass.assignmentStatus < AssignmentStatus.Klarmarkerad
  );

  const scheduledSixMonthsAssignment = assignments.find(
    (ass) =>
      ass.type === AssignmentType.SixMonthsInspection &&
      ass.assignmentStatus < AssignmentStatus.Klarmarkerad
  );

  const scheduledTomningAssignment = assignments.find(
    (ass) =>
      (ass.type === AssignmentType.Tomning || ass.includeTomning) &&
      (!ass.tomning ||
        ass.tomning.transportDocument.status < TransportDocumentStatus.Signed)
  );

  const linkToLatestFiveYearsInspection = !latestFiveYearsInspection
    ? undefined
    : getLinkToFiveYearsInspectionPage(
        separator.customerId,
        separator.id,
        latestFiveYearsInspection.id
      );

  const linkToLatestSixMonthsInspection = !latestSixMonthsInspection
    ? undefined
    : getLinkToSixMonthsInspectionPage(
        separator.customerId,
        separator.id,
        latestSixMonthsInspection.id
      );

  const linkToLatestTomning = !latestTomning
    ? undefined
    : getLinkToTomningPage(
        separator.customerId,
        separator.id,
        latestTomning.id
      );

  return (
    <TabContainer>
      <div className="px-4 sm:px-0 py-4 sm:py-0">
        <h4 className="text-lg font-semibold leading-6 text-gray-900 py-1 mb-4 border-b border-gray-100 pb-4 max-w-xl">
          Påminnelser om schemaläggning
        </h4>

        <div className="max-w-xl space-y-8">
          <div className="grid grid-cols-1 gap-12 sm:gap-8">
            {isAvskiljare(separator) && (
              <>
                <AvtalFemårs
                  isEnabled={!!separator.avtalFemarskontroll}
                  setIsEnabled={setAvtalFemarskontroll}
                  label="5-årsbesiktning"
                  openModal={openFiveYearsModal}
                  taskDate={getNextSeparatorTaskDate(
                    separator,
                    TaskType.FiveYearInspection
                  )}
                  disableAnimation={disableAnimation}
                  latestFiveYearsInspection={latestFiveYearsInspection}
                  fiveYearsInspectionLink={linkToLatestFiveYearsInspection}
                  scheduledAssignment={scheduledFiveYearAssignment}
                  setAssignmentIdToView={_setAssignmentIdToView}
                />
                <AvtalSexmånaders
                  isEnabled={!!separator.avtalHalvarsKontroll}
                  setIsEnabled={setAvtalHalvarsKontroll}
                  label="6-månaderskontroll"
                  openModal={openSixMonthsModal}
                  taskDate={getNextSeparatorTaskDate(
                    separator,
                    TaskType.SixMonthsInspection
                  )}
                  disableAnimation={disableAnimation}
                  latestSixMonthsInspection={latestSixMonthsInspection}
                  sixMonthsInspectionLink={linkToLatestSixMonthsInspection}
                  scheduledAssignment={scheduledSixMonthsAssignment}
                  setAssignmentIdToView={_setAssignmentIdToView}
                />
              </>
            )}
            <AvtalSlamtömning
              isEnabled={!!separator.avtalSlamtomning}
              setIsEnabled={setAvtalSlamtomning}
              disableAnimation={disableAnimation}
              separator={separator}
              latestTomning={latestTomning}
              linkToLatestTomning={linkToLatestTomning}
              scheduledAssignment={scheduledTomningAssignment}
              setAssignmentIdToView={_setAssignmentIdToView}
              taskDate={getNextSeparatorTaskDate(separator, TaskType.Tomning)}
            />
          </div>
        </div>

        <Modal
          isOpen={isFiveYearsTaskModalOpen}
          title={"Avtal (5-årskontroll)"}
          onClose={onFiveYearsTaskModalClose}
          actionHandler={onFiveYearsTaskSubmit}
          actionText="Spara"
          isLoading={isCreatingFiveYearsTask}
          loadingText="Sparar..."
        >
          <EditFiveYearsTask register={registerFiveYearsTask} />
        </Modal>
        <Modal
          isOpen={isSixMonthsTaskModalOpen}
          title={"Avtal (6-månaderskontroll)"}
          onClose={onSixMonthsTaskModalClose}
          actionHandler={onSixMonthsTaskSubmit}
          actionText="Spara"
          isLoading={isCreatingSixMonthsTask}
          loadingText="Sparar..."
        >
          <EditSixMonthsTask register={registerSixMonthsTask} />
        </Modal>
      </div>

      <AssignmentInfoModal
        assignmentId={assignmentIdToView}
        closeModal={() => setAssignmentIdToView(null)}
        isOpen={!!assignmentIdToView}
      />
    </TabContainer>
  );
};

export const getLatestFiveYearsInspection = (
  assignments: Assignment[]
): FiveYearsInspection | undefined =>
  assignments
    .filter(
      (ass) =>
        !!ass.fiveYearsInspection &&
        ass.assignmentStatus >= AssignmentStatus.Klarmarkerad
    )
    ?.sort((a: Assignment, b: Assignment) =>
      (a.fiveYearsInspection?.inspectionDate ?? new Date()) >
      (b.fiveYearsInspection?.inspectionDate ?? new Date())
        ? -1
        : 1
    )
    .find((insp) => insp)?.fiveYearsInspection;

export const getLatestSixMonthsInspection = (
  assignments: Assignment[]
): SixMonthsInspection | undefined =>
  assignments
    .filter((ass) => !!ass.sixMonthsInspection)
    ?.sort((a: Assignment, b: Assignment) =>
      (a.sixMonthsInspection?.inspectionDate ?? new Date()) >
      (b.sixMonthsInspection?.inspectionDate ?? new Date())
        ? -1
        : 1
    )
    .find((insp) => insp)?.sixMonthsInspection;

export const getLatestTomning = (
  assignments: Assignment[]
): Tomning | undefined =>
  assignments
    .filter((ass) => !!ass.tomning)
    .sort((a: Assignment, b: Assignment) =>
      (a.tomning?.transportDocument?.transportDate ?? new Date()) >
      (b.tomning?.transportDocument?.transportDate ?? new Date())
        ? -1
        : 1
    )
    .find((insp) => insp)?.tomning;
